import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { useEffect, useState } from "react";
import { message } from "antd";
import { debounce } from "../../../common/helper/debounce";

export const useInstructor = () => {
  const queryClient = useQueryClient();
  const [params, setParams] = useState({ name: null, status: null });
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [delegateTrainer, setDelgateTrainer] = useState(null);

  const { data, isLoading } = useQuery(
    ["list-instructors", params],
    async () => {
      const response = await httpRequest.get("/school/instructors", {
        params,
      });
      // seTotalData(response?.data?.data?.total_data || 0);
      return response;
    }
  );

  const {
    mutate: getInstructor,
    isLoading: loadingGetInstrucor,
    data: dataInstructor,
  } = useMutation({
    mutationFn: (id) => {
      return httpRequest.get(`/school/instructors/${id}`);
    },
    mutationKey: "detail-instructor",
  });

  const handleOpenInstructor = (id) => {
    setIsOpenEdit(true);
    getInstructor(id);
  };

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      if (
        data?.data?.data?.filter((item) => item.role === "Admin")?.length > 1
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [data?.data?.data]);

  // invite instructor or admin
  const { mutate: invite, isLoading: loadingInvite } = useMutation({
    mutationFn: (val) => {
      return httpRequest.post(`/school/instructors/invite`, val);
    },
    mutationKey: "invite-instructor",
  });

  const handleInvite = (val) =>
    invite(val, {
      onSuccess: () => {
        queryClient.refetchQueries("list-instructors");
        message.success("success invite");
        setIsOpenNew(false);
      },
      onError: (error) => message.error(error?.response?.data?.data),
    });

  // edit instructor or admin
  const { mutate: edit, isLoading: loadingEdit } = useMutation({
    mutationFn: (val, id) => {
      return httpRequest.patch(
        `/school/instructors/${dataInstructor?.data?.data?.id}/edit`,
        val
      );
    },
    mutationKey: "edit-instructor",
  });

  const handleEdit = (val) =>
    edit(val, {
      onSuccess: () => {
        queryClient.refetchQueries("list-instructors");
        message.success("success edit");
        setIsOpenEdit(false);
      },
      onError: (error) => message.error(error?.response?.data?.data),
    });

  //delete data
  const [deleteData, setDeleteData] = useState(null);
  const [isOpenDelete, setIsDeleteOpen] = useState(false);
  const handleOpenDelete = (data) => {
    setDeleteData(data);
    setIsDeleteOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteData(null);
    setIsDeleteOpen(false);
    setDelgateTrainer(null);
  };

  const { mutateAsync: remove, isLoading: loadingRemove } = useMutation(
    {
      mutationFn: async (id) => {
        await httpRequest.delete(`/school/instructors/${id}/delete`);
      },
      mutationKey: "delete-instructor",
    },
    {
      onError: (e) => {
        message.error(e?.response?.data?.message || "something wrong");
      },
    }
  );

  const { mutateAsync: delagate, isLoading: loadingDelegate } = useMutation(
    {
      mutationFn: async () => {
        await httpRequest.patch(
          `/school/skills-and-courses/id/${deleteData?.id}/delegated_id/${delegateTrainer}`
        );
      },
      mutationKey: "delegate-instructor",
    },
    {
      onError: (e) => {
        message.error(e?.response?.data?.message || "something wrong");
      },
    }
  );
  const handleDelete = async () => {
    if (deleteData) {
      if (delegateTrainer) {
        await delagate(); // wait delegate
      }
      await remove(deleteData?.id); // wait remove
      setIsDeleteOpen(false);
      queryClient.invalidateQueries(["list-instructors"]);
    }
  };

  const handleSearch = debounce((val) => {
    setParams((prev) => ({ ...prev, name: val }));
  }, 500);

  return {
    list: { data: data?.data?.data, isLoading },
    params: { params, setParams, handleSearch },
    stage: {
      isOpenEdit,
      setIsOpenEdit,
      isOpenNew,
      setIsOpenNew,
      isDisabled,
      isOpenDelete,
    },
    detail: {
      action: handleOpenInstructor,
      loading: loadingGetInstrucor,
      data: dataInstructor?.data?.data,
    },
    edit: {
      action: handleEdit,
      loading: loadingEdit,
    },
    add: {
      action: handleInvite,
      loading: loadingInvite,
    },
    remove: {
      user: deleteData,
      handleOpen: handleOpenDelete,
      handleClose: handleCloseDelete,
      handleDelete: handleDelete,
      isLoading: loadingDelegate || loadingRemove,
    },
    setDelgateTrainer,
    delegateTrainer,
  };
};

export const useInstructorCourse = (id) => {
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });

  const { data, isLoading } = useQuery(
    ["list-course-instructors", params],
    async () => {
      const response = await httpRequest.get(
        `/school/my-courses/trainer-id/${id}`,
        {
          params,
        }
      );
      return response;
    },
    {
      enabled: !!id,
    }
  );

  return {
    data,
    isLoading,
    setParams,
  };
};

import { useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { useState } from "react";

export const useDashboard = () => {
  const [dataView, setDataView] = useState(5);
  const { data, isLoading } = useQuery("dashboard", () =>
    httpRequest.get("/school/dashboard")
  );
  return {
    view: { dataView, setDataView },
    data: data?.data?.data,
    loading: isLoading,
  };
};

import React from "react";

interface BasicVerticalContainerProps {
  children: React.ReactNode | React.ReactElement;
  className?: string;
}

export const BasicVerticalContainer: React.FC<BasicVerticalContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`py-[14px] px-5 rounded-[20px] ${className}`}>
      {children}
    </div>
  );
};

import React from "react";
import { InformationUser } from "../components";
import { useUser } from "../hooks/useUser";

const UserForm = () => {
  const { user } = useUser();
  return (
    <div>
      <div className=" bg-[#E2EBFA]  mt-10">
        <InformationUser
          data={user.data}
          avatar={user.avatar}
          action={user.action}
        />
      </div>
    </div>
  );
};

export default UserForm;

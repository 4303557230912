import React from "react";
import { StripeOnboard } from "../components";
import { useStripe } from "../hooks/useStripe";

const StripeFrom = () => {
  const stripe = useStripe();
  return (
    <div>
      <div className=" bg-[#E2EBFA]  mt-10">
        <StripeOnboard stripe={stripe} />
      </div>
    </div>
  );
};

export default StripeFrom;

import { create } from "zustand"

export interface InitPublicStore {
  isVisible: boolean
  onConfirm: (() => Promise<void>) | null // Tipe untuk fungsi async
  onCancel: (() => void) | null
  openModal: (onConfirm: () => Promise<void>, onCancel: () => void) => void
  closeModal: () => void
}


export const initialPublicStoreState = create<InitPublicStore>((set) => ({
  isVisible: false,
  onConfirm: null,
  onCancel: null,

  openModal: (onConfirm, onCancel) => set({
    isVisible: true,
    onConfirm,
    onCancel,
  }),

  closeModal: () => set({
    isVisible: false,
    onConfirm: null,
    onCancel: null,
  }),
}))

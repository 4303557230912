import React from "react";
import { useCourseEditorStore } from "../stores/useCourseEditorStore.ts";
import {
  useDeleteLessonVideo,
  useEncodeLessonVideo,
} from "./useLessonVideo.ts";
import {
  useUpdateCourseLevelModuleLesson,
  useRemovePdf,
} from "./courseEditor.ts";
import { FormValues, Lesson } from "../types/common.ts";
import { useFormContext } from "react-hook-form";
import { message } from "antd";
import { RcFile } from "antd/es/upload";
import { BeforeUploadFileType } from "rc-upload/lib/interface";

export const useLessonContainer = (
  lesson: Lesson,
  module_id: string,
  moduleName: string,
  lesssonIndex: number,
  videoStatusUpdateAfterUpload: (
    lesson_id: string,
    isError: boolean | undefined,
    deleted?: boolean
  ) => void,
  addPdfFileToLesson: (
    lesson_id: string,
    pdf_url: string,
    name: string
  ) => void,
  deletePdfFileFromLesson: (lesson_id: string, pdf_url: string) => void
) => {
  const encodeLessonVideo = useEncodeLessonVideo();
  const { skill, level } = useCourseEditorStore((state) => state);
  const deleteLessonVideo = useDeleteLessonVideo();
  const removePdf = useRemovePdf();

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const { mutateAsync: updateLesson, isLoading } =
    useUpdateCourseLevelModuleLesson();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();

  const handleBlur = (value: string) => {
    const hasChanged = value !== lesson?.description;
    if (skill?.id && level?.id && module_id && lesson?.id && hasChanged) {
      updateLesson(
        {
          course_id: skill?.id,
          level_id: level?.id,
          module_id: module_id,
          lesson_id: lesson?.id,
          formData: {
            description: value,
            order: lesson?.order,
            file: null,
          },
        },
        {
          onSuccess: () => {},
          onError: () => {
            message.error(
              "There was a problem in updating the lesson. Please try again."
            );
          },
        }
      );
    }
  };

  const handleDeleteVideo = async () => {
    if (lesson?.id && module_id) {
      await deleteLessonVideo.mutateAsync(
        {
          lesson_id: lesson?.id,
          module_id: module_id,
          video_file_name: lesson?.id,
        },
        {
          onSuccess: () => {
            videoStatusUpdateAfterUpload(lesson?.id, false, true);
          },
          onError: () => {
            videoStatusUpdateAfterUpload(lesson?.id, true, true);
          },
        }
      );
    }
  };

  const beforeFileUpload = (file: RcFile) => {
    const isPdf = file?.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      return false;
    }
    return true;
  };

  const handleFileUpload = async (
    file: Exclude<BeforeUploadFileType, File | boolean> | RcFile
  ) => {
    await updateLesson(
      {
        course_id: skill?.id,
        level_id: level?.id,
        module_id: module_id,
        lesson_id: lesson?.id,
        formData: {
          file: file as RcFile,
        },
      },
      {
        onSuccess: (data) => {
          message.success("File uploaded successfully");

          console.log(data?.data?.data);
          if (data?.data?.data) {
            addPdfFileToLesson(
              lesson?.id,
              // @ts-ignore
              data?.data?.data?.pdf?.[data?.data?.data?.pdf?.length - 1]
                ?.url as string,
              // @ts-ignore
              file?.name ?? ""
            );
          }
        },
        onError: () => {
          message.error(
            "There was a problem in updating the lesson. Please try again."
          );
        },
      }
    );
  };

  const removePdfFile = async (
    pdf_url: string,
    lessonIndex: number,
    pdfIndex: number
  ) => {
    await removePdf.mutateAsync(
      {
        course_id: skill?.id ?? "",
        level_id: level?.id ?? "",
        module_id: module_id,
        lesson_id: lesson?.id,
        formData: {
          pdf_url,
        },
      },
      {
        onSuccess: async (data) => {
          message.success("Successfully removed file");
          deletePdfFileFromLesson(lesson?.id ?? "", pdf_url);
        },
        onError: () => {
          message.error(
            "There was a problem in removing the file. Please try again."
          );
        },
      }
    );
  };

  return {
    encodeLessonVideo,
    skill,
    level,
    deleteLessonVideo,
    isCollapsed,
    setIsCollapsed,
    updateLesson,
    isLoading,
    control,
    errors,
    handleBlur,
    handleDeleteVideo,
    beforeFileUpload,
    handleFileUpload,
    removePdfFile,
    removePdf,
  };
};

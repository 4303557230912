import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import { Area } from "@ant-design/plots";
import { useSales } from "../hook/useSales";
import {HomeIcon} from "../../../assets/icon";

const Sales = () => {
  const action = useSales();
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      title: "Admin",
    },
    {
      path: "/trainer-admin/sales",
      title: "Sales",
    },
  ];

  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop capitalize">
          Sales dashboard
        </h1>
      </div>
      <div className="mx-5 px-5 py-5  my-5 bg-white grid md:grid-cols-2 gap-16">
        <div>
          <div className=" font-semibold mb-3">By Skill</div>
          <Area {...action.data.skill} />
        </div>
        <div>
          <div className=" font-semibold mb-3">By Instructor</div>
          <Area {...action.data.instructor} />
        </div>
      </div>
    </div>
  );
};

export default Sales;

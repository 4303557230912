import React, { useEffect } from "react";
import { Button, Input } from "antd";
import {
  PlusOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import { CountryComponent } from "../../../common/components";

export const SetupCompany = ({
  image,
  update,
  loading,
  data,
  isOnboard = true,
}) => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      logo:
        data?.data?.data?.logo ===
        "https://stgreenskills001.blob.core.windows.net/greenskills-recruiter-assets-production/avatar-placeholder.png"
          ? null
          : data?.data?.data?.logo,
      description: data?.description,
      name: data?.name,
      location: data?.location || null,
      website_url: data?.website_url === "-" ? null : data?.website_url,
      linkedin_url: data?.linkedin_url === "-" ? null : data?.linkedin_url,
      twitter_url: data?.twitter_url === "-" ? null : data?.twitter_url,
      facebook_url: data?.facebook_url === "-" ? null : data?.facebook_url,
      instagram_url: data?.instagram_url === "-" ? null : data?.instagram_url,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="px-20">
      {isOnboard && (
        <p className="text-center mb-10">
          Let’s set up your <strong>School</strong>. You will be able to change
          this later.
        </p>
      )}

      <form>
        <div className="flex gap-x-8">
          <label htmlFor="image-profile" className=" cursor-pointer">
            {image.imageUrl ? (
              <img
                src={image.imageUrl}
                className="w-[120px] h-full object-contain bg-white border border-[#D9D9D9] border-dashed"
                alt="profile"
              />
            ) : (
              <div
                className="w-[120px] h-full text-center border-gray-600 flex flex-col justify-center items-center bg-[#ffffff] gap-1"
                style={{
                  border: `1px dashed ${errors?.logo ? "#ff4d4f" : "#D9D9D9"}`,
                }}
                // style={{ border: "1px solid red" }}
              >
                <PlusOutlined />
                <div className=" text-[#cdcdcd]">Max 300kb</div>
              </div>
            )}
          </label>

          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            id="image-profile"
            className=" hidden"
            {...register("logo", {
              required: true,
              onChange: (e) => {
                image.handleImageChange(e);
              },
            })}
          />
          <div className="flex-grow">
            <div className="text-backdrop text-base">School description</div>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input.TextArea
                  status={errors?.description && "error"}
                  rows={4}
                  {...field}
                  placeholder="Write a short company description, to build a stronger Employer Branding"
                  className="mt-1 w-full"
                />
              )}
            />
          </div>
        </div>
        <div className="grid grid-cols-5 gap-20 mt-8">
          <div className=" col-span-3">
            <div className="mb-3">
              <div className="text-backdrop">Company Name</div>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors?.name && "error"}
                    placeholder="School Name"
                    className="mt-1"
                  />
                )}
              />
            </div>
            <div className="mt-5">
              <div className="text-backdrop">Main Location</div>
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CountryComponent
                    status={errors?.location && "error"}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className=" col-span-2">
            <Controller
              name="website_url"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  prefix={<LinkOutlined className="mr-2" />}
                  placeholder="School website"
                  // className="mt-3"
                />
              )}
            />
            <Controller
              name="linkedin_url"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  prefix={<LinkedinOutlined className="mr-2" />}
                  placeholder="Linkedin"
                  className="mt-3"
                />
              )}
            />
            <Controller
              name="twitter_url"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  prefix={<TwitterOutlined className="mr-2" />}
                  placeholder="Twitter"
                  className="mt-3"
                />
              )}
            />
            <Controller
              name="facebook_url"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  prefix={<FacebookOutlined className="mr-2" />}
                  placeholder="Facebook"
                  className="mt-3"
                />
              )}
            />
            <Controller
              name="instagram_url"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  prefix={<InstagramOutlined className="mr-2" />}
                  placeholder="Instagram"
                  className="mt-3"
                />
              )}
            />
          </div>
        </div>
      </form>
      <div className="text-right mt-5">
        <Button
          onClick={handleSubmit(update)}
          loading={loading}
          className="bg-primary py-1 px-2 text-white"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Empty, Popover, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const TableCourses = ({ isLoading, listMyCourses, isRedirect = true }) => {
  const navigate = useNavigate();

  const contentInfoStatus = (
    <div className="space-y-1">
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-white"></div>
        <span>not started</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-secondaryYellow"></div>
        <span>in progress</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-[#EF7574]"></div>
        <span>in review</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-primary-bg"></div>
        <span>live (published)</span>
      </div>
    </div>
  );

  return (
    <table className="w-full mt-3">
      <thead className="grid grid-cols-12 mb-3">
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-6">
          Skill Name
        </td>
        <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-6">
          Status of Courses{" "}
          <Popover content={contentInfoStatus}>
            <InfoCircleOutlined className="cursor-pointer hover:text-primary" />
          </Popover>
        </td>
      </thead>
      {isLoading ? (
        <div className="py-4 text-center">
          <Spin spinning={isLoading} size="large" />
        </div>
      ) : listMyCourses?.list === null ? (
        <div className="py-4  text-center">
          <Empty />
        </div>
      ) : (
        listMyCourses?.list?.map((item, index) => (
          <tbody className="grid grid-cols-12" key={index}>
            <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-6 flex items-center">
              {item?.name}
            </td>

            <td className="font-medium  border-gray-300 py-2 px-5 text-backdrop col-span-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                  {item?.levels?.map((item, index) => (
                    <p
                      key={index}
                      className={`border-backdrop border-[1.6px] w-6 text-center flex justify-center ${
                        item?.accessible_for_me
                          ? "cursor-pointer"
                          : "opacity-40"
                      } ${
                        item?.status === "DRAFT"
                          ? "bg-white"
                          : item?.status === "ON_PROGRESS" ||
                            item?.status === "REJECTED"
                          ? "bg-[#FFEFA0]"
                          : item?.status === "PUBLISH"
                          ? "bg-primary-bg"
                          : "bg-[#EF7574]"
                      }`}
                    >
                      {item?.proficiency}
                    </p>
                  ))}
                </div>
                {isRedirect && (
                  <Button
                    onClick={() => navigate(`/courses/detail/${item?.id}`)}
                    className="border-primary"
                  >
                    Edit Courses
                  </Button>
                )}
              </div>
            </td>
          </tbody>
        ))
      )}
    </table>
  );
};

export default TableCourses;

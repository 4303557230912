import { Dropdown, Input } from 'antd'
import React from 'react'
import { ArrowIcon } from '../../../common/components/Icons'

const InputDropdown = ({
  defaultValue,
  data,
  handleChangeFilter,
  selected,
}) => {
  return (
    <Dropdown
      menu={{
        items:
          data && data.length > 0
            ? [
                { key: 'deselect', label: 'Show All' },
                ...data?.map((location) => ({
                  key: `${location.id},${location.name}`,
                  label: location?.name,
                })),
              ]
            : [],
        onClick: handleChangeFilter,
        itemClassName: 'p-2 hover:bg-blue-500',
      }}
      className='text-backdrop '
    >
      <span className='text-backdrop'>
        <Input
          addonAfter={
            <ArrowIcon
              className='w-auto mx-auto flex justify-center text-backdrop'
              width={13}
              height={13}
            />
          }
          value={selected}
          defaultValue={defaultValue}
          placeholder={defaultValue}
          allowClear
          className='col-span-1 cursor-pointer text-backdrop capitalize'
          readOnly
        />
      </span>
    </Dropdown>
  )
}

export default InputDropdown

import httpRequest from "../../../config/config"

export const FetchMyCourses = (params) => {
  return httpRequest.get(`/school/my-courses`, {
    params,
  })
}
export const FetchDetailMyCourses = (id) => {
  return httpRequest.get(`/school/my-courses/${id}`)
}
export const UpdateMyCourses = (id, params = {}) => {
  console.log('data to edit', id, params)
  return httpRequest.patch(`/school/my-courses/${id}`, {
    id: params?.id,
    level_id: params?.level_id,
    instructor_id: params?.instructor_id,
  })
}

import { message } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "../common/helper/debounce";
import httpRequest from "../config/config";

export const useCoursesDropdown = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    name: null,
  });

  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["courses-dropdown", params],
    () => httpRequest.get("/school/my-courses", { params }),
    {
      onError: (err) => message.error(err?.response?.data?.data),
    }
  );

  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = () => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const target = e.target;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list || []);
        setTotal(data?.data?.data?.total_data || 0);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data || 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);

  useEffect(() => {
    if (total > dataList.length) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return {
    dataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  };
};

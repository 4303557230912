import { Route, Routes } from "react-router-dom";
import ListCoursesV2 from "./pages/ListCoursesV2";
import NotFound from "../../common/components/NotFound";
// import DetailCourses from './pages/DetailCourses'
import EditLevelCoursesContainer from "./pages/EditLevelCoursesContainer";
import DetailCoursesV3 from "./pages/DetailCoursesV3.tsx";

const Index = () => {
  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={<ListCoursesV2 />}
        />
        <Route
          path='/detail/:id'
          element={<DetailCoursesV3 />}
        />
        <Route
          path='/edit-level/:skill_id/:level_id'
          element={<EditLevelCoursesContainer />}
        />

        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </div>
  );
};

export default Index;

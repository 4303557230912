import React, { useRef } from "react"
import {  PlusOutlined } from "@ant-design/icons"
import { Breadcrumbs } from "../../../common/components/Breadcumbs"
import { Image, Tag } from "antd"
import logoSkilledin from "../../../assets/images/logo-skilledin.png"
import { useSchool } from "../../onboarding/hooks/useSchool"
import {HomeIcon} from "../../../assets/icon";

const Certificate = () => {

  const {
    school,
    imageSignature,
  } = useSchool()

  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      path: "/trainer-admin/skills-courses",
      title: "Skills and Courses",
    },
    {
      path: "/trainer-admin/skills-courses/certificate",
      title: "Certificates",
    },
  ]

  const data = [
    { name: '[Date]', age: "Level 1 {Beginner}", gender: `Level 1 [description]` },
    { name: '[Date]', age: "Level 2 {Developing}", gender: `Level 2 [description]` },
    { name: '[Date]', age: "Level 3 {Proficient}", gender: `Level 3 [description]` },
    { name: '[Date]', age: "Level 4 {Skilled}", gender: `Level 4 [description]` },
    { name: '[Date]', age: "Level 5 {Advanced}", gender: `Level 5 [description]` },
  ]

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'age', label: 'Age' },
    { key: 'gender', label: 'Gender' },
  ]

  const fileInputRef = useRef(null)

  const handleReplaceClick = () => {
    fileInputRef.current.click()
  }


  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop capitalize">
          Certificate Editor
        </h1>
      </div>
      <div className="px-5 py-5  grid md:grid-cols-2 gap-5">
        <div>
          <div className="text-backdrop bg-white px-10 py-5">
            <div className={`text-center mb-5 mt-4`}>
              <Image src={school?.detail?.logo} width={70} />
            </div>
            <div className={`text-center space-y-4 mb-7`}>
              <h3>Certificate of Completion</h3>
              <p>This is to certify that</p>
              <h1>FULL NAME</h1>
              <p>Has successfully completed online course delivered by <span>{school?.detail?.name}</span> and offered through Skilledin Green platform</p>
              <h1>SKILL NAME</h1>
            </div>
            <div className={`space-y-4 mb-20`}>
              <p>Completed proficiency levels and associated knowledge</p>

              <table className="table-fixed w-full">
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {columns.map((column, index) => (
                        <td className={`border-none py-1 ${index === 0 ? 'w-[100px]' : index === 1 ? 'w-[183px]' : ''}`} key={column.key}>
                          {row[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={`flex items-center justify-center gap-7 mb-7`}>
              <div className={`text-center mb-5`}>
                {imageSignature.imageSignatureUrl ? (
                  <img
                    src={imageSignature.imageSignatureUrl}
                    className="w-[120px] h-full object-contain bg-white border border-[#D9D9D9] border-dashed"
                    alt="profile"
                  />
                ) : (
                  <div
                    className="w-[120px] h-16 text-center border-gray-600 flex flex-col justify-center items-center bg-[#ffffff] gap-1"
                    style={{
                      border: `1px dashed`,
                    }}
                  >
                    <div className="mt-2 font-thin text-[#cdcdcd]">Signature</div>
                  </div>
                )}
              </div>
              <div className={`space-y-1 mb-4`}>
                <p className={`font-semibold`}>{school?.detail?.admin?.first_name} {school?.detail?.admin?.last_name}</p>
                <p>{school?.detail?.admin?.role} {school?.detail?.name} </p>
                <p>Date: [Last Passed date] </p>
              </div>
            </div>


          </div>

          <div className={`bg-secondaryBlue flex items-center justify-between px-10 py-2`}>
            <div className={`text-center`}>
              <Image src={logoSkilledin} width={100} />
              <p>green.skilledin.io</p>
            </div>
            <div className={`space-y-1`}>
              <p>Ref No [Ref Number]</p>
            </div>
          </div>
        </div>
        <div>
          <div className=" font-semibold mb-3 bg-white px-10 py-5">
            <h4 className={`mb-2`}>Certificate Status</h4>
            <Tag
              style={{
                backgroundColor: school?.detail?.is_signature_exist ? "#D4F5C5" : "red",
                color: school?.detail?.is_signature_exist ? "#000" : "#FFF",
              }}
              // color={school?.detail?.is_signature_exist ? "#000" : "white"}
              className={`rounded-full px-4 mb-3 border-none`}
            >
              {school?.detail?.is_signature_exist ? "Enabled" : "Disabled"}
            </Tag>
            <h4 className={`mb-1`}>School Admin Signature</h4>
            <p className={`font-normal text-xs mb-7`}>Please, upload a signature in PNG format or JPG on white background</p>

            <label htmlFor="image-profile" className=" cursor-pointer">
              {imageSignature.imageSignatureUrl ? (
                <img
                  src={imageSignature.imageSignatureUrl}
                  className="w-[120px] h-full object-contain bg-white border border-[#D9D9D9] border-dashed"
                  alt="profile"
                />
              ) : (
                <div
                  className="w-[120px] h-24 text-center border-gray-600 flex flex-col justify-center items-center bg-[#ffffff] gap-1"
                  style={{
                    border: `1px dashed`,
                  }}
                >
                  <PlusOutlined />
                  <div className="mt-2 font-thin text-[#cdcdcd]">Max 300kb</div>
                </div>
              )}
            </label>

            <input
              type="file"
              ref={fileInputRef}
              accept="image/png, image/gif, image/jpeg"
              id="image-profile"
              className=" hidden"
              onChange={(e) => {
                imageSignature.handleImageSignatureChange(e)
              }}
            />

            {school?.detail?.is_signature_exist ? <div className={`flex items-center gap-5 font-thin mt-2`}>
              <p onClick={handleReplaceClick} className={`cursor-pointer text-blue-500`}>Replace</p>
              <p onClick={() => imageSignature.handleRemoveClick(school?.detail?.id)} className={`text-danger cursor-pointer`}>Remove</p>
            </div> : null}

          </div>

        </div>
      </div>
    </div>
  )
}

export default Certificate

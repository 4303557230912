import React, { useEffect } from "react";
import { CourseEditorLayout } from "./CourseEditorLayout.tsx";
import { EditorSidebar } from "./EditorSidebar.tsx";
import { BasicContainerRichEditor } from "./BasicContainerRichEditor.tsx";
import { BasicTitle } from "./BasicTitle.tsx";
import LessonContainer from "./LessonContainer.tsx";
import { AddButton } from "../AddButton.tsx";
import { BasicTwoBoxContainer } from "./BasicTwoBoxContainer.tsx";
import { CourseData, TabNames } from "../../types/common.ts";
import { useCourseModule } from "../../hooks/useCourseModule.ts";
interface Props {
  module: CourseData;
  moduleNum: number;
  moduleName: string;
  publishCourse: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  saveChanges: () => Promise<any>;
  hitTrigger: any;
}

export const CourseEditorModule: React.FC<Props> = ({
  module,
  moduleNum,
  moduleName,
  publishCourse,
  saveChanges,
  hitTrigger,
}) => {
  const {
    addLessonHandler,
    errors,
    removeLessonHandler,
    fieldSaving,
    handleBlur,
    lessons,
    moduleDetailQuery,
    moveLessonDown,
    moveLessonUp,
    videoStatusUpdateAfterUpload,
    control,
    addLesson,
    deleteLesson,
    skill,
    addPdfFileToLesson,
    deletePdfFileFromLesson,
    saveOnExit,
  } = useCourseModule(moduleName, module);

  useEffect(() => {
    hitTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CourseEditorLayout
      isLoading={moduleDetailQuery.isLoading}
      isOpacityLoading={addLesson.isLoading || deleteLesson.isLoading}
      isEmpty={
        moduleDetailQuery.data?.data?.data === null ||
        moduleDetailQuery.data?.data?.data === undefined
      }
      leftRightSidebar={
        <>
          <EditorSidebar
            type={moduleName as TabNames}
            skill_id={skill?.id ?? ""}
            publishCourse={publishCourse}
            saveChanges={saveChanges}
            lessons={lessons}
            saveOnExit={saveOnExit}
          />
        </>
      }
      mainContent={
        <>
          <BasicTwoBoxContainer
            leftBox={
              moduleNum !== 1 ? (
                <BasicContainerRichEditor
                  richTextProps={{
                    onBlur: (range, source, editor) =>
                      handleBlur(editor?.getHTML(), "recap"),
                  }}
                  title={"Recap"}
                  name={`${moduleName}.recap`}
                  showSaving
                  isSaving={fieldSaving.recap?.isSaving}
                  control={control}
                  showError
                  errorMessage={
                    "Recap is required, and must be at least 30 characters"
                  }
                  isError={!!errors?.[moduleName]?.recap}
                />
              ) : null
            }
            rightBox={
              <BasicContainerRichEditor
                richTextProps={{
                  onBlur: (range, source, editor) =>
                    handleBlur(editor?.getHTML(), "introduction"),
                }}
                title={"Introduction"}
                name={`${moduleName}.introduction`}
                showSaving
                isSaving={fieldSaving.introduction?.isSaving}
                control={control}
                showError
                errorMessage={
                  errors?.[moduleName]?.introduction?.message
                    ? "Introduciton is required, and must be at least 30 characters"
                    : ""
                }
                isError={!!errors?.[moduleName]?.introduction}
              />
            }
          />
          <BasicTitle
            title="Lessons"
            className="mt-6"
            titleSize="h2"
            withButton
            buttonTitle="Add New Lesson"
            buttonProps={{
              onClick: addLessonHandler,
              loading: addLesson.isLoading,
            }}
          />
          {(errors?.[moduleName]?.lessons?.message || lessons?.length < 1) && (
            <div className="text-danger text-xs text-center mt-4">
              You need to add at least one lesson
            </div>
          )}
          {lessons?.map((lesson: any, index) => (
            <LessonContainer
              key={lesson.fid}
              lesson={lesson as unknown as any}
              module_id={module?.id}
              lessonIndex={index}
              moduleName={moduleName}
              totalLessons={lessons?.length}
              moveLessonDown={moveLessonDown}
              moveLessonUp={moveLessonUp}
              removeLessonHandler={removeLessonHandler}
              videoStatusUpdateAfterUpload={videoStatusUpdateAfterUpload}
              addPdfFileToLesson={addPdfFileToLesson}
              deletePdfFileFromLesson={deletePdfFileFromLesson}
            />
          ))}
          <div className="flex items-center justify-center">
            <AddButton className="mt-6" onClick={addLessonHandler} />
          </div>
          <BasicTitle
            title="Supporting Materials"
            className="mt-6"
            titleSize="h2"
          />
          <BasicTwoBoxContainer
            className="mt-6"
            leftBox={
              <BasicContainerRichEditor
                richTextProps={{
                  onBlur: (range, source, editor) =>
                    handleBlur(editor?.getHTML(), "resources"),
                }}
                title={"Resources"}
                name={`${moduleName}.resources`}
                control={control}
                showSaving
                isSaving={fieldSaving.resources?.isSaving}
                showError
                errorMessage={
                  errors?.[moduleName]?.resources?.message
                    ? "Resources is required, and must be at least 30 characters"
                    : ""
                }
                isError={!!errors?.[moduleName]?.resources}
              />
            }
            rightBox={
              <BasicContainerRichEditor
                richTextProps={{
                  onBlur: (range, source, editor) =>
                    handleBlur(editor?.getHTML(), "glossary"),
                }}
                title={"Glossary"}
                name={`${moduleName}.glossary`}
                control={control}
                showSaving
                isSaving={fieldSaving.glossary?.isSaving}
                showError
                errorMessage={
                  errors?.[moduleName]?.glossary?.message
                    ? "Glossary is required, and must be at least 30 characters"
                    : ""
                }
                isError={!!errors?.[moduleName]?.glossary}
              />
            }
          />
          <BasicContainerRichEditor
            richTextProps={{
              onBlur: (range, source, editor) =>
                handleBlur(editor?.getHTML(), "reflection"),
            }}
            className="mt-6"
            title={"Reflection Questions"}
            name={`${moduleName}.reflection`}
            control={control}
            showSaving
            isSaving={fieldSaving.reflection?.isSaving}
            showError
            errorMessage={
              errors?.[moduleName]?.reflection?.message
                ? "Reflection Questions is required, and must be at least 30 characters"
                : ""
            }
            isError={!!errors?.[moduleName]?.reflection}
          />
        </>
      }
    />
  );
};

export const LIST_PROGRAM_QUERY_NAME: string = "LIST_PROGRAM_QUERY_NAME"
export const DETAIL_PROGRAM_QUERY_NAME: string = "DETAIL_PROGRAM_QUERY_NAME"

export interface Skill {
  id: string
  name: string
  created_at: string
  program_id: string
  school_id: string
}

export interface ProgramResponse {
  id: string
  school_id: string
  title: string
  description: string
  created_at: string
  update_at: string | null
  is_issuing_certificate: boolean
  skills: Skill[]
  modules: any | null
  lessons: any | null
  status: ProgramStatus
  total_course: number
  total_module: number
  total_lesson: number
  total_trainer: number
  trainers: ProgramTrainer[]
  courses?: Course[] | null
  action_button: ActionButton
}

export interface ProgramQueryParams {
  page: number
  limit: number
  status?: string
  search?: string
}

export enum ProgramStatus {
  DRAFT = "draft",
  IN_PROGRESS = "in progress",
  LIVE = "live",
}

export interface ProgramTrainer {
  id: string
  display_name: string
  first_name: string
  last_name: string
  email: string
  avatar: string
  program_course_id: string
  program_id: string
}


export interface Lesson {
  id: number
  title: string
  skill?: string
  program_skill?: ProgramSkill
  program_skill_id: string | null
}

export interface Module {
  introduction: string
  lessons: Lesson[]
}

export interface Course {
  id?: string
  modules: Module[]
  program_id: string
  release_date?: string
  title: string
  trainer_id: string | null
  skills?: Skill[]
  status?: string
  trainer?: ProgramTrainer
}


export interface ProgramSkill {
  id: string
  name: string
  program_id: string
  school_id: string
  created_at: string
}

export interface ActionButton {
  disabled_button: boolean
  label: string
}

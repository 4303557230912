import { message } from "antd"
import { useState } from "react"
import { useQuery } from "react-query"
import { FetchSkillAndCourses } from "../apis/skillAndCourses"
import { debounce } from "../../../common/helper/debounce"

export const useFetchSkillAndCourses = () => {
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [level, setLevel] = useState(null)
  const [levelName, setLevelName] = useState(null)
  const [trainer, setTrainer] = useState(null)
  const [trainerName, setTrainerName] = useState(null)

  const useFetchData = (params = {}) => {
    const handleFetchError = (error) => {
      message.error(`An error occurred while fetching skill and courses: ${error}`)
    }

    return useQuery(['list-skill-and-courses', params], () => FetchSkillAndCourses(params), {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    })
  }

  const handlePageChange = (page) => {
    setPage(page)
  }
  const handleLimitChange = (size) => {
    setPage(1)
    setLimit(size)
  }

  const handleSetFilterByLevel = (value) => {
    if (value?.key === 'deselect') {
      setLevel(null)
      setLevelName(null)
      return
    }
    setLevel(value?.key?.split(',')[0])
    setLevelName(value?.key?.split(',')[1])
  }

  const handleChangeFilterByTrainer = (value) => {
    if (value?.key === 'deselect') {
      setTrainer(null)
      setTrainerName(null)
      return
    }
    setTrainer(value?.key?.split(',')[0])
    setTrainerName(value?.key?.split(',')[1])
  }

  const handleSearch = debounce((value) => {
    setSearch(
      value?.target?.value === null ||
        value?.target?.value?.trim() === '' ||
        value?.target?.value === undefined
        ? null
        : value?.target?.value
    )
  }, 1000)

  const { data: listSkillAndCourses, isLoading } = useFetchData({ name: search, limit, page, published_level: level, trainer_id: trainer })

  return {
    listSkillAndCourses: listSkillAndCourses?.data?.data,
    isLoading,
    handlePageChange,
    handleLimitChange,
    handleSearch,
    search,
    levelName,
    handleSetFilterByLevel,
    handleChangeFilterByTrainer,
    trainerName
  }
}

import React from "react";
import dayjs from "dayjs";
import { Tag } from "antd";
import { VideoCameraOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Workshop } from "../../hooks/useWorkshop";

interface Props {
  data: Workshop;
}

export const Header: React.FC<Props> = ({ data }) => {
  return (
    <div className=" bg-[#FAFAFA] rounded-md px-5 py-3 shadow-md">
      <h1 className=" font-semibold my-1.5 capitalize">
        {data?.title || "Unknown title"}
      </h1>
      <Tag
        color={`${
          data.status === "available"
            ? "green"
            : data.status === "scheduled"
            ? "blue"
            : "orange"
        }`}
        className=" first-letter:capitalize py-0.5 px-3 my-1.5"
      >
        {data.status || "Unknown status"}
      </Tag>
      <div className=" flex gap-3 items-center my-1.5">
        <VideoCameraOutlined />{" "}
        <div className=" first-letter:capitalize">
          {data?.mode || "Unknown mode"}
        </div>
      </div>
      <div className=" flex gap-3 items-center my-1.5">
        <ClockCircleOutlined />
        <div>
          {data?.release_date
            ? dayjs(data.release_date).format("DD MMM YYYY, h:mmA")
            : data.mode}
        </div>
      </div>
    </div>
  );
};

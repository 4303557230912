import {Button, Divider, Input, Modal, Popover, Switch, Tag} from 'antd'
import React from 'react'
import {initialProgramStoreState} from '../store/programStore.ts'
import {Controller} from 'react-hook-form'
import {InfoCircleOutlined, PlusCircleFilled} from '@ant-design/icons'
import {useProgramFetch} from '../hook/useProgram.ts'
import './style.css'

interface ModalNewProgramProps {
}

const ModalNewProgram: React.FC<ModalNewProgramProps> = () => {
    const {openModalNewProgram, setOpenModalNewProgram, isEditAction, setIsEditAction} = initialProgramStoreState()
    const {
        handleSubmit,
        errors,
        control,
        handleAddSkillField,
        handleConfirmSkill,
        handleCancelSkill,
        handleSkillInputChange,
        showSkillInputs,
        setShowSkillInputs,
        unconfirmedSkills,
        isButtonDisabled,
        onSubmit,
        fields,
        remove,
        setValue,
    } = useProgramFetch()

    return (
        <div>
            <Modal
                title={<h2 className='flex items-center justify-center'>{isEditAction ? 'Edit' : 'New'} Program</h2>}
                centered
                open={openModalNewProgram}
                onOk={() => {
                    setOpenModalNewProgram(false)
                    setIsEditAction(false)
                }}
                onCancel={() => {
                    setOpenModalNewProgram(false)
                    setIsEditAction(false)
                }}
                footer={null}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='space-y-4 my-5'>
                        <div className='space-y-2'>
                            <label htmlFor="title" className="w-16 font-bold text-md">
                                Program Title
                            </label>
                            <Controller
                                name="title"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        id="title"
                                        status={errors?.title ? 'error' : ''}
                                        className="my-1 font-default rounded-lg border-2 border-gray-200"
                                        placeholder="What your program is called?"
                                    />
                                )}
                            />
                            {errors.title && <p className="text-red-500">Program title is required</p>}
                        </div>
                        <div className='space-y-2'>
                            <label htmlFor="description" className="font-bold text-md flex items-center gap-x-2 w-full">
                                Program Description
                                <Popover placement="right" content={<p>Minimum of 300 characters</p>}>
                                    <InfoCircleOutlined/>
                                </Popover>
                            </label>
                            <Controller
                                name="description"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => (
                                    <Input.TextArea
                                        {...field}
                                        placeholder="Provide a short description of the program"
                                        status={errors?.description ? 'error' : ''}
                                        rows={4}
                                        className="border-2 "
                                        maxLength={1000}
                                        minLength={300}
                                        showCount
                                        // style={{background: "#f3f4f6 !important", borderRadius: "10px"}}

                                    />
                                )}
                            />
                            {errors.description && <p className="text-red-500">Program description is required</p>}
                        </div>

                        <div className='space-y-3'>
                            <label htmlFor="description" className="font-bold text-md flex items-center gap-x-2 w-full">
                                Program Skills
                                <Popover placement="right" content={
                                    <p>
                                        Minimum 4 of 10 Skills
                                    </p>
                                }>
                                    <InfoCircleOutlined/>
                                </Popover>
                            </label>
                            <p className='text-xs'>You can create 4-10 skills and assign a single skill per lesson. When
                                learners complete the lesson, they will see their program skill progress chart in their
                                learning dashboard.</p>

                            {/* Render existing skills as tags */}
                            {fields.map((field, index) => (
                                <Tag
                                    key={field.id}
                                    closable
                                    onClose={() => remove(index)}
                                    className="mb-2 bg-primary-bg rounded-xl border-2 border-gray-200 py-0.5 px-3"
                                >
                                    {/* @ts-ignore */}
                                    {field.name}
                                </Tag>
                            ))}

                            {/* Skill Input Fields */}
                            {showSkillInputs && unconfirmedSkills.map((skill, index) => (
                                <div key={index} className="flex gap-2 items-center mb-2">
                                    <Input
                                        placeholder="max 20 characters"
                                        value={skill}
                                        maxLength={20}
                                        onChange={(e) => handleSkillInputChange(index, e.target.value)}
                                        className='rounded-lg border-2 border-gray-200'
                                    />
                                    <div className='flex items-center'>
                                        <Button type="link" onClick={() => handleConfirmSkill(index)}
                                                disabled={!skill.trim()}>
                                            Confirm
                                        </Button>
                                        <Divider type='vertical'/>
                                        <Button type="link" danger onClick={() => handleCancelSkill(index)}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            ))}

                            {(fields.length + unconfirmedSkills.length) < 10 &&
                                <div
                                    className='flex items-center gap-x-2 !my-5 cursor-pointer'
                                    onClick={() => {
                                        setShowSkillInputs(true)
                                        handleAddSkillField()
                                    }}
                                >
                                    <PlusCircleFilled className='text-xl'/>
                                    <span className='text-md font-bold'>
                    Add Skill
                  </span>
                                </div>
                            }
                        </div>

                        <div className='flex items-center gap-x-2 !my-10'>
                            <Controller
                                name="is_issuing_certificate"
                                control={control}
                                render={({field}) => (
                                    <Switch
                                        className={`${field.value ? 'bg-primary' : 'bg-gray-200'}`}
                                        checked={field.value}
                                        onChange={(checked) => setValue('is_issuing_certificate', checked)}
                                    />
                                )}
                            />
                            <p className='m-0 p-0'>Issue Certificate of Completion</p>
                        </div>
                    </div>

                    <div className='my-4'>
                        <Button
                            block
                            type="primary"
                            className='bg-primary rounded'
                            htmlType="submit"
                            disabled={isButtonDisabled}
                        >
                            {isEditAction ? 'Edit' : 'New'} program
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default ModalNewProgram

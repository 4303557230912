import React from "react";
import { SetupCompany } from "../../onboarding/components";
import { useSchool } from "../../onboarding/hooks/useSchool";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import { Skeleton } from "antd";
import {HomeIcon} from "../../../assets/icon";

const SchoolProfile = () => {
  const { school, image } = useSchool();
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
      {
          title: "Admin",
      },
    {
      path: "/trainer-admin/school-profile",
      title: "School Profile",
    },
  ];
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop capitalize">
          School Profile
        </h1>
      </div>
      <div className="mx-5 px-5 py-5 my-5 bg-white">
        {school?.loading ? (
          <Skeleton active paragraph={{ rows: 7 }} />
        ) : (
          <SetupCompany
            data={school.detail}
            image={image}
            update={school.update}
            loading={school.loadingUpdate}
            isOnboard={false}
          />
        )}
      </div>
    </div>
  );
};

export default SchoolProfile;

import { Breadcrumb, Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"

export const Breadcrumbs = ({ items = [] }) => {
  return (
    <Breadcrumb
      separator=">"
      className="font-default"
      items={
        items?.map((itm, i) => ({
          title: itm.to ? (
            <Link to={itm.to}>{itm.title}</Link>
          ) : (
            <Typography.Paragraph>{itm.title}</Typography.Paragraph>
          ),
        }))
        // <Breadcrumb.Item key={i}>
        //   <Link to={itm.to}>{itm.title}</Link>
        // </Breadcrumb.Item>
      }
    />
  )
}

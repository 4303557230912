import React from 'react'
import DashboardSubMenu from './DashboardSubMenu.tsx'
import { useLocation } from 'react-router-dom'
import OverviewContent from './OverviewContent.tsx'
import GreenSkillContent from './GreenSkillContent.tsx'
import WorkshopContent from './WorkshopContent.tsx'
import ProgramContent from './ProgramContent.tsx'

interface ROUTES {
  ROUTES: any
}

const SchoolDashboardContent: React.FC<ROUTES> = ({ ROUTES }) => {
  const location = useLocation()

  const renderContent = () => {
    switch (location.pathname) {
      case ROUTES.OVERVIEW:
        return <div><OverviewContent /></div>
      case ROUTES.GREEN_SKILLS:
        return <div><GreenSkillContent /> </div>
      case ROUTES.WORKSHOPS:
        return <div><WorkshopContent /> </div>
      case ROUTES.PROGRAMS:
        return <div><ProgramContent /></div>
      default:
        return <div>Default Content</div> // Fallback if no route matches
    }
  }

  return (
    <div className="relative">
      {/* Sub Menu */}
      <div className="w-[60%] m-auto !z-50 absolute left-[15rem]">
        <DashboardSubMenu ROUTES={ROUTES} />
      </div>

      {/* Content */}
      <div className="bg-white-custom rounded-lg p-7 mt-6">
        {renderContent()}
      </div>
    </div>
  )
}


export default SchoolDashboardContent

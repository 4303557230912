import React, { createContext, useState } from "react";

export const SkillAndLevelContext = createContext();

export const SkillAndLevelContextProvider = (props) => {
  const [skill_id, setSkillId] = useState(null);
  const [level_id, setLevelId] = useState(null);

  const setSkillAndLevel = (skill_id, level_id) => {
    setSkillId(skill_id);
    setLevelId(level_id);
  };

  return (
    <SkillAndLevelContext.Provider
      value={{
        skill_id,
        level_id,
        setSkillAndLevel,
        setSkillId,
        setLevelId,
      }}>
      {props.children}
    </SkillAndLevelContext.Provider>
  );
};

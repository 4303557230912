import React from 'react'
import { PageContent } from '../../../common/layout/PageContent.tsx'
import { PageHeader } from '../../../common/layout/PageHeader.tsx'
import CertificateEditorContent from '../components/CertificateEditorContent.tsx'
import {HomeIcon} from "../../../assets/icon";

interface CertificateEditorProps {

}

const CertificateEditor: React.FC<CertificateEditorProps> = () => {
  const breadcrumbItems = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/trainer-admin/school-dashboard",
    },
    {
      title: "Overview",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: "Certificate Editor",
    },
  ]

  return (
    <>
      <PageContent
        content={<CertificateEditorContent />}
        header={
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title="Certificate Editor"
            showBack
           
          />
        }
      />
    </>
  )
}

export default CertificateEditor

import React from "react";
import FormModule from "./FormModule";

const TabModuleContainer = ({
  handleInputChangeModule,
  handleLessonsChangeModule,
  handleAddLessonModule,
  handleDeleteLessonModule,
  register,
  errors,
  moduleData,
  moduleNumber,
  recapForm,
  setIsVideoToInProgress,
  moveLessonDown,
  moveLessonUp,
}) => {
  return (
    <div>
      <FormModule
        moduleData={moduleData}
        recapForm={recapForm}
        handleInputChange={handleInputChangeModule}
        handleLessonsChange={handleLessonsChangeModule}
        handleAddLesson={handleAddLessonModule}
        handleDeleteLesson={handleDeleteLessonModule}
        register={register}
        errors={errors}
        moduleNumber={moduleNumber}
        setIsVideoToInProgress={setIsVideoToInProgress}
        moveLessonDown={moveLessonDown}
        moveLessonUp={moveLessonUp}
      />
    </div>
  );
};

export default TabModuleContainer;

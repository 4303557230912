import { useState } from "react";
import { debounce } from "../../helper/debounce";
import { useQuery } from "react-query";
import httpRequest from "../../../config/config";

export const useCountry = () => {
  const [params, setParams] = useState({ name: "", limit: 10, page: 1 });
  const [totalData, seTotalData] = useState(0);
  const [countryList, setCountryList] = useState([]);

  const { isLoading } = useQuery(
    ["countries-list", params], // Gunakan array parameter untuk cache key
    () => async () => {
      const response = await httpRequest.get("/references/countries", {
        params,
      });
      setCountryList((prev) => response?.data?.data?.list || []);
      seTotalData(response?.data?.data?.total_data || 0);
      return response.data;
    },
    { retry: false }
  );

  const handleMore = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (countryList.length < totalData) {
        setParams((params) => ({ ...params, limit: params.limit + 5 }));
      }
    }
  };
  const handleSearch = debounce((val) => {
    setParams((prev) => ({ ...prev, name: val, page: 1, limit: 10 }));
  }, 500);
  return {
    countries: {
      list: countryList,
      setParams,
      totalData,
      handleMore,
      handleSearch,
      isLoading,
    },
  };
};

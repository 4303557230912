import React from "react";
import { Header } from "./Header.tsx";
import { Material } from "./Material.tsx";
import { Description } from "./Description.tsx";
import { Workshop } from "../../hooks/useWorkshop.ts";
import { Control, FieldErrors, UseFormHandleSubmit } from "react-hook-form";
import { RcFile } from "antd/es/upload/interface";

interface Props {
  data: Workshop;
  form: {
    handleSubmit: UseFormHandleSubmit<
      {
        description: string;
        material: string;
        video: string;
      },
      undefined
    >;
    control: Control<
      {
        description: string;
        material: string;
        video: string;
      },
      any
    >;
    errors: FieldErrors<{
      description: string;
      material: string;
      video: string;
    }>;
    isDirty: boolean;
  };
  fileMaterial: {
    handleFileUpload: (file: string | Blob | RcFile) => Promise<void>;
    beforeFileUpload: (file: RcFile) => boolean;
    pdfTemp:
      | {
          name: string;
          url: string;
          created_at: string;
        }[]
      | undefined;
    isLoadingUpload: boolean;
    handleFileDelete: (name: string) => Promise<void>;
    isLoadingDelete: boolean;
    handleVideoDelete: (name: string) => Promise<void>;
    isLoadingVideoDelete: boolean;
    handleVideoEncode: (name: string) => Promise<void>;
    isEncodeLoading: boolean;
  };
}

export const Detail: React.FC<Props> = ({ data, form, fileMaterial }) => {
  return (
    <div className=" w-[calc(100%-20rem)] bg-white rounded-md  p-5">
      <Header data={data} />
      <div className="bg-[#E2EBFA] rounded-md px-5 py-3 shadow-md my-5">
        <h1 className=" font-semibold">Details</h1>
      </div>
      <Material form={form} fileMaterial={fileMaterial} media={data?.media} />
      <Description form={form} />
    </div>
  );
};

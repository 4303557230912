import { useMutation } from "react-query";
import httpRequest from "../../../config/config";

export const usePayouts = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: (val) => {
      return httpRequest.post(`/school/stripe/account-login-link`, val);
    },
    mutationKey: "account-login-link",
  });

  const handleAccountLoginLink = () => {
    mutate(null, {
      onSuccess: (res) => {
        window.open(res?.data?.data, "_blank");
      },
    });
  };
  return { handleAccountLoginLink, isLoading };
};

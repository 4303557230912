import React from 'react'
import SidebarIconHome from '../../assets/icon/home-icon.svg'
import SidebarIconJobArchitecture from '../../assets/icon/job-arch-icon.svg'
import SidebarIconOrgSetting from '../../assets/icon/org-setting-icon.svg'
import SidebarIconOkr from '../../assets/icon/okr-icon.svg'
import SpyIcon from '../../assets/icon/spy-icon.svg'
import { ReactComponent as SpyIcon2 } from '../../assets/icon/spy-icon.svg'
import { ReactComponent as IconArrow } from '../../assets/icon/arrow-icon.svg'
import { ReactComponent as EyeIcon } from '../../assets/icon/eye-icon.svg'
import { ReactComponent as HideIcon } from '../../assets/icon/eye-hide-icon.svg'
import { ReactComponent as PencilIcon } from '../../assets/icon/pencil-icon.svg'
import { ReactComponent as TrashIcon } from '../../assets/icon/trash.svg'
import { ReactComponent as IIcon } from '../../assets/icon/info-icon.svg'
import { ReactComponent as LongArrowIcon } from '../../assets/icon/long-arrow-icon.svg'
import { ReactComponent as GreenCheckIcon } from '../../assets/icon/greencheck-icon.svg'
import { ReactComponent as IRoundedIcon } from '../../assets/icon/inforounded-icon.svg'
import { ReactComponent as Coin } from '../../assets/icon/coin.svg'

export const HomeIcon = React.forwardRef(({ ...props }, ref) => {
  return <img src={SidebarIconHome} {...props} ref={ref} alt='Home Icon' />
})

export const JobArchitectureIcon = React.forwardRef(({ ...props }, ref) => {
  return (
    <img
      src={SidebarIconJobArchitecture}
      {...props}
      ref={ref}
      alt='Job Architecture Icon'
    />
  )
})

export const OrgSettingIcon = React.forwardRef(({ ...props }, ref) => {
  return (
    <img
      src={SidebarIconOrgSetting}
      {...props}
      ref={ref}
      alt='Org Setting Icon'
    />
  )
})

export const OkrIcon = React.forwardRef(({ ...props }, ref) => {
  return <img src={SidebarIconOkr} {...props} ref={ref} alt='OKR Icon' />
})

export const SearchIcon = React.forwardRef(({ ...props }, ref) => {
  return <img src={SpyIcon} {...props} ref={ref} alt='Spy Icon Icon' />
})

export const SearchIcon2 = ({ width, height, ...props }) => {
  return <SpyIcon2 width={width} height={height} {...props} />
}
export const ArrowIcon = ({ width, height, ...props }) => {
  return <IconArrow width={width} height={height} {...props} />
}
export const EditIcon = ({ width, height, ...props }) => {
  return <PencilIcon width={width} height={height} {...props} />
}
export const ViewIcon = ({ width, height, ...props }) => {
  return <EyeIcon width={width} height={height} {...props} />
}
export const ViewHideIcon = ({ width, height, ...props }) => {
  return <HideIcon width={width} height={height} {...props} />
}
export const DeleteIcon = ({ width, height, ...props }) => {
  return <TrashIcon width={width} height={height} {...props} />
}
export const InfoIcon = ({ width, height, ...props }) => {
  return <IIcon width={width} height={height} {...props} />
}
export const InfoRoundedIcon = ({ width, height, ...props }) => {
  return <IRoundedIcon width={width} height={height} {...props} />
}
export const ArrowLongIcon = ({ width, height, ...props }) => {
  return <LongArrowIcon width={width} height={height} {...props} />
}
export const CheckGreenIcon = ({ width, height, ...props }) => {
  return <GreenCheckIcon width={width} height={height} {...props} />
}
export const CoinIcon = ({ width, height, ...props }) => {
  return <Coin width={width} height={height} {...props} />
}

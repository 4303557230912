import {Button, Empty, Input, Space} from 'antd'
import React from 'react'
import {useProgramFetch} from '../hook/useProgram.ts'
import {initialProgramStoreState} from '../store/programStore.ts'
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons'
import {ProgramCard} from './ProgramCard.tsx'
import {debounce} from '../../../common/helper/debounce.js'
import ModalNewProgram from './ModalNewProgram.tsx'

interface ProgramContentProps {

}

const ProgramContent: React.FC<ProgramContentProps> = () => {
    const {lastCardElementRef} = useProgramFetch()
    const {programData, loading, setOpenModalNewProgram, setQueryParams, queryParams} = initialProgramStoreState()

    const handleSearchChange = debounce((value) => {
        setQueryParams({
            page: 1,
            limit: queryParams.limit,
            total: queryParams.total,
            search: value,
        })
    }, 500)

    return (
        <div className='w-full'>

            <div className='flex items-center justify-end mb-5 mt-10'>
                <Button className="bg-primary text-white" onClick={() => setOpenModalNewProgram(true)}>
                    <Space>
                        New Program
                    </Space>
                </Button>
            </div>

            {/* FILTER */}
            <div className='flex items-center gap-x-56 justify-end mb-12 px-4 py-6 shadow-custom-sm border rounded-lg'>
                <div className="bg-white-custom flex gap-4 flex-wrap items-center w-full">
                    <div
                        className="flex flex-grow min-w-[80px]"
                        style={{borderBottom: "1px solid #D0DDE5"}}
                    >
                        <SearchOutlined/>
                        <Input
                            bordered={false}
                            onChange={(e) => {
                                handleSearchChange(e.target.value)
                            }}
                            placeholder="Search"
                        />
                    </div>

                </div>

            </div>

            {/* CARD LIST */}
            <div className=" bg-white-custom">
                {loading ? (
                    <div className="flex justify-center items-center h-40">
                        <LoadingOutlined className={"text-4xl"}/>
                    </div>
                ) : programData.length === 0 ? (
                    <div className="h-40">
                        <Empty/>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4">
                        {programData?.map((program) => (
                            <ProgramCard key={program.id} program={program} lastCardElementRef={lastCardElementRef}/>
                        ))}
                    </div>
                )}
            </div>

            <ModalNewProgram/>

        </div>
    )
}

export default ProgramContent

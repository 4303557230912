import { useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { useEffect, useState } from "react";

export const useStudent = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const { data, isLoading } = useQuery(["students", params], () =>
    httpRequest.get("/school/students", { params })
  );

  useEffect(() => {
    if (data) {
      setTotal(data?.data?.data?.total_data || 0);
    }
  }, [data]);
  return { data: data?.data?.data, isLoading, setParams, params, total };
};

import { useMutation } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

export const useStripe = () => {
  const { mutate: generate, isLoading } = useMutation({
    mutationFn: () => {
      httpRequest
        .get(`/school/settings/stripe`)
        .then((res) => {
          window.location.href = res?.data?.data?.onboarding_url;
        })
        .catch((error) => {
          message.error(error?.response?.data?.data);
        });
    },
    mutationKey: "update-school",
  });
  const handleGenerate = () => {
    generate();
  };
  return { handleGenerate, isLoading };
};

import React from "react";
import { CheckCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Control, FieldErrors, UseFormHandleSubmit } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Workshop } from "../../hooks/useWorkshop";

interface Props {
  form: {
    handleSubmit: UseFormHandleSubmit<
      {
        description: string;
        material: string;
        video: string;
      },
      undefined
    >;
    control: Control<
      {
        description: string;
        material: string;
        video: string;
      },
      any
    >;
    errors: FieldErrors<{
      description: string;
      material: string;
      video: string;
    }>;
    isDirty: boolean;
  };
  actionSave: {
    handleSave: () => Promise<void>;
    isSaveLoading: boolean;
    handlePublish: (data: any) => Promise<void>;
    isPublishLoading: boolean;
  };
  data: Workshop;
}

export const CheckList: React.FC<Props> = ({ form, actionSave, data }) => {
  const navigate = useNavigate();
  return (
    <div className=" w-80 h-max bg-[#E2EBFA] rounded-md px-5 py-3">
      <h3 className=" mb-3">Checklist</h3>
      <div className=" flex  justify-between items-center my-3">
        <div>Workshop Materials</div>
        <CheckCircleOutlined
          className={!form.errors.video ? "text-primary" : ""}
        />
      </div>
      <div className=" flex  justify-between items-center my-3">
        <div>Description</div>
        <CheckCircleOutlined
          className={!form.errors.description ? "text-primary" : ""}
        />
      </div>
      <div className=" flex gap-3">
        <Button
          className="w-full bg-white"
          disabled={
            (!!form.errors?.video && !!form.errors?.video) ||
            actionSave.isPublishLoading ||
            !!data.published_at
          }
          onClick={form.handleSubmit(actionSave.handlePublish)}
        >
          Save & Publish
        </Button>
        <Button
          className=" w-full bg-primary text-white"
          style={{ border: "none" }}
          disabled={actionSave.isSaveLoading || !!data.published_at}
          onClick={() => actionSave.handleSave()}
        >
          Save Changes
        </Button>
      </div>
      <div
        className=" flex justify-center items-center gap-3 bg-[#36505E] text-white py-1 px-3 my-3 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <LogoutOutlined /> Exit Workshop Dashboard
      </div>
    </div>
  );
};

import React from 'react'
import dayjs from 'dayjs'
import { useHeader } from '../../../common/hooks/useCustomHeader'


export default function Logout() {
    const { handleLoginRedirect } = useHeader();
    return (
        <div className="w-full bg-[#E2EBFA]  h-full flex flex-col ">
            <div className="w-full h-full flex justify-center items-center">
                <div className="w-1/3 -mt-10 flex flex-col justify-center items-center">
                    <div className=" text-xl font-bold text-center mb-5">
                        Successfully logged out.
                    </div>
                    <div className="my-3 text-semibold text-center text-lg text-primary">This tab will automtically close after 10 seconds...</div>

                    <div className="mt-3">
                        If you would like to sign back in.
                        <span
                            onClick={handleLoginRedirect}
                            className="text-[#009ACE] ml-1 cursor-pointer"
                        >
                            Press Here.
                        </span>
                    </div>
                </div>
            </div>
            <div className="h-10 mx-auto">
                {`Copyright ©`}
                <span className="text-[#009ACE]">{`Skilledin`}</span>
                {` ${dayjs().format('YYYY')}`}
            </div>
        </div>
    )
}

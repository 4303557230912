import { Button, Empty, Popover, Skeleton } from "antd";
import React from "react";

export const Table = ({ data, isLoading }) => {
  return (
    <table className="w-full mt-6">
      <thead className="">
        <tr className="grid grid-cols-12 bg-[#FFEFA0]">
          <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-4">
            User
          </td>
          <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
            Location
          </td>
          <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
            Skills
          </td>

          <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
            Courses
          </td>
          <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
        </tr>
      </thead>

      <tbody className="">
        {isLoading ? (
          <tr className="grid grid-cols-12">
            <td className=" col-span-12 border-b  border-l border-r border-gray-300 p-5">
              <Skeleton
                title={false}
                paragraph={{ rows: 8 }}
                active
                className="mb-3"
              />
            </td>
          </tr>
        ) : data?.list?.length > 0 ? (
          data?.list?.map((v, i) => (
            <tr key={i} className="grid grid-cols-12">
              <td className="border-l border-b border-gray-300 py-2 px-5 col-span-4 flex items-center gap-5">
                <img
                  src={v.avatar}
                  className="h-10 w-10 rounded-full object-contain border border-slate-100"
                  alt="avatar"
                />
                <div className="">{`@${v.username}`}</div>
              </td>
              <td className="border-b border-gray-300 py-2 px-5 col-span-3 flex items-center">
                <div className=" truncate capitalize">{v.location || "-"}</div>
              </td>
              <td className="border-b border-gray-300 py-2 px-5 col-span-2 flex items-center ">
                <Popover
                  trigger="hover"
                  placement="bottom"
                  arrow={false}
                  content={
                    <div>
                      <div className=" font-semibold mb-2">Skills learning</div>
                      <div className=" h-full max-h-20 w-40 overflow-auto">
                        {v?.skills?.map((itm, n) => (
                          <div key={n} className=" truncate">
                            {itm.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                >
                  <div className=" truncate w-full">{v.total_skills}</div>
                </Popover>
              </td>
              <td className="border-b border-gray-300 py-2 px-5 col-span-2 flex items-center ">
                {v.total_courses}
              </td>
              <td className="border-b border-r border-gray-300 py-2 px-5 col-span-1 flex items-center justify-end gap-5 ">
                <Button type="primary" ghost disabled>
                  Details
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <div className="py-3 border-b  border-l border-r">
            <Empty />
          </div>
        )}
      </tbody>
    </table>
  );
};

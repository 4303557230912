import { message, Select, Skeleton, } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useSchool } from '../../onboarding/hooks/useSchool.js'
import { useInstructor } from '../hook/useInstructor.js'


export interface Instructor {
  id: string
  tenant_id: string
  display_name: string
  first_name: string
  last_name: string
  email: string
  role: string
  country: string
  status: string
  avatar: string
  about: string
  job_title: string
  linkedin_url?: string
  twitter_url?: string
  medium_url?: string
  display_onboard: string
}
interface CertificateEditorContentProps {

}

const { Option } = Select

const CertificateEditorContent: React.FC<CertificateEditorContentProps> = () => {

  const [selectedUser, setSelectedUser] = useState<Instructor | null>(null)

  const fileInputRef = useRef(null)

  const {
    school,
    imageSignature,
    handleUpdateOwnerCertificate,
    certificateData,
    isLoadingCertificateData,
  } = useSchool()

  const { list } = useInstructor()

  const handleChange = (id: string) => {
    setSelectedUser(null)
    const selected = list.data.find(user => user.id === id)
    setSelectedUser(selected)

    if (selectedUser) {
      const data = {
        owner_certificate_id: id,  // Set the ID 
      }
      handleUpdateOwnerCertificate(data)
    } else {
      message.error("Please select a user before updating")
    }
  }

  useEffect(() => {
    if (certificateData) {
      setSelectedUser(certificateData?.data?.data?.owner_certificate)
    }
  }, [isLoadingCertificateData, certificateData])

  return (
    <div className='shadow bg-white px-4 py-5 rounded-xl'>
      <h3>Signatory</h3>
      <div className='grid grid-cols-2 gap-x-14 mt-4'>
        <div className='space-y-4'>
          <Select
            placeholder="Select Admin to sign certificates "
            className="w-full !rounded"
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              option?.children?.toString().toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleChange}
          >
            {list?.data?.map((user: Instructor) => (
              <Option key={user.id} value={user.id}>
                {user.display_name}
              </Option>
            ))}
          </Select>
          {isLoadingCertificateData ? <div><Skeleton active /></div> : certificateData?.data?.data?.owner_certificate !== null &&
            <div className='grid grid-cols-5 leading-7 ml-4'>
              <div className='col-span-2'>
                <p className='font-bold'>Name</p>
                <p className='font-bold'>Role in Organization</p>
                <p className='font-bold'>Issuing Organization</p>
              </div>
              <div className='col-span-3'>
                <p>{certificateData?.data?.data?.owner_certificate?.display_name || '-'}</p>
                <p>{certificateData?.data?.data?.owner_certificate?.job_title || '-'}</p>
                <p>{school?.detail?.name || '-'}</p>
              </div>
            </div>
          }
        </div>
        {selectedUser !== null &&
          <div className='space-y-3'>
            <p>Please, upload a <b>signature</b> in PNG format (with no background) or JPG on white background</p>

            {isLoadingCertificateData ? <div><Skeleton className='my-3' active /></div> :
              <div className="font-semibold mb-3 bg-white px-10 py-5 -ml-10">
                <label htmlFor="image-profile" className=" cursor-pointer">
                  {imageSignature.imageSignatureUrl ? (
                    <img
                      src={imageSignature.imageSignatureUrl}
                      className="w-[120px] h-[120px] object-contain bg-white border border-[#D9D9D9] border-dashed"
                      alt="profile"
                    />
                  ) : (
                    <div
                      className="w-[120px] h-[120px] text-center border-gray-600 flex flex-col justify-center items-center bg-[#ffffff] gap-1"
                      style={{
                        border: `1px dashed`,
                      }}
                    >
                      <PlusOutlined />
                      <div className="mt-2 font-thin text-[#cdcdcd]">Max 300kb</div>
                    </div>
                  )}
                </label>

                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/png, image/gif, image/jpeg"
                  id="image-profile"
                  className="-ml-3 hidden"
                  onChange={(e) => {
                    imageSignature.handleImageSignatureChange(e)
                  }}
                />
              </div>
            }
          </div>
        }
      </div>

      {
        selectedUser !== null &&
        <div className='bg-secondaryYellow border-2 rounded-xl py-2 px-4'>
          <p>
            <b>Name</b> and <b>Role</b> (position) can be edited by the signing admin in My Profile.
          </p>
          <p>
          </p>
          <b>Issuing Organization</b> is your school name and can be edited in School Profile.
        </div>
      }
    </div >
  )
}

export default CertificateEditorContent

import React from 'react'
import { PageContent } from "../../../common/layout/PageContent.tsx"
import { PageHeader } from "../../../common/layout/PageHeader.tsx"
import SchoolDashboardContent from '../components/SchoolDashboardContent.tsx'
import { useLocation } from 'react-router-dom'
import {HomeIcon} from "../../../assets/icon";

interface SchoolDashboardProps {

}

export const ROUTES = {
  OVERVIEW: "/trainer-admin/school-dashboard/overview",
  GREEN_SKILLS: "/trainer-admin/school-dashboard/green-skills",
  WORKSHOPS: "/trainer-admin/school-dashboard/workshop",
  PROGRAMS: "/trainer-admin/school-dashboard/programs",
}

const SchoolDashboard: React.FC<SchoolDashboardProps> = () => {
  const location = useLocation()
  const lastSegment = location.pathname.split('/').pop()

  const breadcrumbItems = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/school-dashboard",
    },
    {
      title: <span className='capitalize'>{lastSegment}</span>,
      to: `/${lastSegment}`,
    },
  ]

  return (
    <>
      <PageContent
        content={<SchoolDashboardContent ROUTES={ROUTES} />}
        header={
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title="School Dashboard"
          />
        }
      />
    </>
  )
}

export default SchoolDashboard

import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import { Table } from "../components/Table";
import { Pagination } from "antd";
import { useStudent } from "../hooks/useStudent";
import { Filter } from "../components/Filter";
import {HomeIcon} from "../../../assets/icon";

const ListStudents = () => {
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      path: "/students",
      title: "Students",
    },
  ];
  const { data, isLoading, setParams, params, total } = useStudent();
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop">Students</h1>
      </div>
      <div className="mx-5 px-5 py-5 my-5 bg-white">
        <Filter setParamStudent={setParams} />
        <Table data={data} isLoading={isLoading} />
        {/* {total > params?.limit && ( */}
        <Pagination
          className="mt-3 text-end"
          current={params?.page}
          pageSize={10}
          total={total}
          onChange={(page) => setParams((prev) => ({ ...prev, page: page }))}
          hideOnSinglePage={true}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default ListStudents;

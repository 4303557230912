import {
  CloseCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React from "react";
import ReactQuill from "react-quill";
import { Button, Spin, Upload } from "antd";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
} from "react-hook-form";
import { RcFile } from "antd/es/upload";
import { BitmovinPlayer } from "../../../../common/components";
import { VideoUploadV2 } from "../../../../common/components/VideoUploadV2.tsx";
import { useParams } from "react-router-dom";
interface Props {
  media?: {
    status: string;
    dash: string;
    encoding_id: string;
    blob_path: string;
    duration: number;
  };
  form: {
    handleSubmit: UseFormHandleSubmit<
      {
        description: string;
        material: string;
        video: string;
      },
      undefined
    >;
    control: Control<
      {
        description: string;
        material: string;
        video: string;
      },
      any
    >;
    errors: FieldErrors<{
      description: string;
      material: string;
      video: string;
    }>;
    isDirty: boolean;
  };
  fileMaterial: {
    handleFileUpload: (file: string | Blob | RcFile) => Promise<void>;
    beforeFileUpload: (file: RcFile) => boolean;
    pdfTemp?: {
      name: string;
      url: string;
      created_at: string;
      file_name?: string;
    }[];
    isLoadingUpload: boolean;
    handleFileDelete: (name: string) => Promise<void>;
    isLoadingDelete: boolean;
    handleVideoDelete: (name: string) => Promise<void>;
    isLoadingVideoDelete: boolean;
    handleVideoEncode: (name: string) => Promise<void>;
    isEncodeLoading: boolean;
  };
}

export const Material: React.FC<Props> = ({ form, fileMaterial, media }) => {
  const { id } = useParams();
  return (
    <div className="flex gap-5 h-max">
      <div className=" w-full bg-[#FAFAFA] rounded-md p-5 shadow-md">
        <div className=" h-[calc(100%-2rem)]  bg-white rounded-md">
          {!media ? (
            fileMaterial.isEncodeLoading ? (
              <div className="text-center h-full flex items-center justify-center p-5">
                <Spin spinning indicator={<LoadingOutlined className="" />} />
              </div>
            ) : (
              <VideoUploadV2
                style={{ background: "white", padding: "0.75rem" }}
                encodingInProgress={false}
                azureContainerName={
                  process.env.REACT_APP_AZURE_VIDEO_STORAGE_CONTAINER_NAME ?? ""
                }
                fileName={id}
                onSuccess={() => fileMaterial.handleVideoEncode(id || "")}
              />
            )
          ) : media.status === "IN_PROGRESS" ? (
            <div className="text-center h-full flex items-center justify-center p-5">
              <div>
                <strong>Upload successful,</strong> but encoding is underway and
                may take a moment. Our automation system is still in
                development, so please reload in 5 minutes.
              </div>
            </div>
          ) : (
            <BitmovinPlayer
              dash={media.dash}
              hls={undefined}
              poster={undefined}
            />
          )}
        </div>
        <div
          className={`" mt-3 text-danger ${
            media && media.dash && !fileMaterial.isLoadingVideoDelete
              ? "cursor-pointer"
              : "cursor-not-allowed"
          }`}
          onClick={() => {
            if (media && media.dash && !fileMaterial.isLoadingVideoDelete) {
              fileMaterial.handleVideoDelete(media.blob_path);
            }
          }}
        >
          Delete video
        </div>
      </div>
      <div className=" w-full rounded-md bg-[#FAFAFA] p-5 shadow-md">
        <h3 className=" font-semibold">Materials</h3>
        <div className=" my-3">
          Upload PDFs or share links that participants will be able to access
          after signing up
        </div>
        <Controller
          name="material"
          control={form.control}
          render={({ field }) => (
            <ReactQuill {...field} className={`custom bg-white`} />
          )}
        />
        <div className=" flex justify-between items-center mt-3">
          <h4 className=" font-semibold">Upload files</h4>
          <Upload
            beforeUpload={(file) => fileMaterial.beforeFileUpload(file)}
            showUploadList={false}
            accept="application/pdf"
            customRequest={(options) =>
              fileMaterial.handleFileUpload(options?.file)
            }
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
        {fileMaterial.pdfTemp && (
          <div className="mt-3">
            {fileMaterial.pdfTemp.map((pdf, index) => (
              <div
                className="flex items-center gap-3 text-secondary"
                key={index}
              >
                <CloseCircleOutlined
                  className={`${
                    fileMaterial.isLoadingDelete ? "!cursor-not-allowed" : ""
                  } text-danger`}
                  onClick={() => {
                    if (!fileMaterial.isLoadingDelete)
                      fileMaterial.handleFileDelete(pdf.name);
                  }}
                />
                <a href={pdf.url} target="_blank" rel="noreferrer">
                  {pdf.file_name}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Typography } from "antd";
import {
  LinkedinOutlined,
  MediumOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

export const SocialMedia = ({ data, action }) => {
  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        linkedin_url: data?.linkedin_url,
        twitter_url: data?.twitter_url,
        medium_url: data?.medium_url,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <form className="py-5 px-[26%]">
      <Typography.Paragraph className="text-center ">
        {data?.role === "Admin"
          ? "You are setting up an Admin account for your school. Admins in Skilledin Green have an instructor role as well. Fill in information, that is visible to learners on the skills you teach."
          : "Fill in the information to be displayed in “Meet the Instructor” on the skills you teach"}
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-3 mt-10">
        Social Media (optional)
      </Typography.Paragraph>
      <div>
        <Controller
          name="linkedin_url"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="linkedin_url"
              prefix={<LinkedinOutlined />}
              className="my-2 font-default rounded-none "
              placeholder="linkedin.com/in/account"
            />
          )}
        />
        <Controller
          name="twitter_url"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="twitter_url"
              prefix={<TwitterOutlined />}
              className="my-2 font-default rounded-none "
              placeholder="twitter.com/account"
            />
          )}
        />
        <Controller
          name="medium_url"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              id="medium_url"
              prefix={<MediumOutlined />}
              className="my-2 font-default rounded-none "
              placeholder="medium.com/@account"
            />
          )}
        />
      </div>
      <div className="text-right mt-5">
        <Button
          type="primary"
          className="bg-[#86C769]"
          loading={action?.loading}
          onClick={handleSubmit(action?.updateSocial)}
        >
          Next
        </Button>
      </div>
    </form>
  );
};

import React from "react";
import avatar from "../../../assets/images/course_info.png";

export const InfoTotalCourse = ({ data, course }) => {
  return (
    <div className=" flex gap-5 flex-wrap">
      <div className=" w-36 h-24 border bg-[#FAFAFA] flex justify-center items-center flex-col gap-2">
        <p className=" font-bold text-lg">{data?.teaching || 0}</p>
        <p>Skills I Teach</p>
      </div>
      <div className=" w-36 h-24 border bg-[#FAFAFA] flex justify-center items-center flex-col gap-2">
        <p className=" font-bold text-lg">{data?.published || 0}</p>
        <p>Published Courses</p>
      </div>
      <div className=" w-36 h-24 border bg-[#FAFAFA] flex justify-center items-center flex-col gap-2">
        <p className=" font-bold text-lg">{data?.learners || 0}</p>
        <p>Total Learners</p>
      </div>
      <div className="grow h-24 border bg-[##FFF8F5] flex gap-3 px-2 py-3">
        <img
          src={avatar}
          className="h-full w-24 object-contain"
          alt="info-course"
        />
        <div>
          <p className=" capitalize font-semibold">course status</p>
          <div className="flex items-center gap-2">
            <div className="w-1.5 h-1.5 rounded-full bg-[#D4F5C5] border"></div>
            <p className="text-[#919191] text-[12px]">{`${
              course?.published || 0
            } published`}</p>
          </div>
          <div className="flex items-center gap-2 ">
            <div className="w-1.5 h-1.5 rounded-full bg-[#FFEFA0] border"></div>
            <p className="text-[#919191] text-[12px]">{`${
              course?.in_progress || 0
            } in progress`}</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-1.5 h-1.5 rounded-full border"></div>
            <p className="text-[#919191] text-[12px]">{`${
              course?.not_started || 0
            } not started`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

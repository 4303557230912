import {ApiResponsePaginated} from "./../../../common/types/ApiResponse";
import httpRequest from "../../../config/config";
import {Program, WorkshopMode, WorkshopStatus, WorkshopType,} from "../types/workshopAndPrograms";

export interface IWorkshop {
    title?: string;
    trainer_id?: string;
    status?: WorkshopStatus;
    mode?: WorkshopMode;
    type?: WorkshopType;
    limit?: number;
    page?: number;
}

export const workshopAndProgramsList = async (
    data: IWorkshop
): Promise<ApiResponsePaginated<Program>> => {
    return httpRequest.get("/school/programs-and-workshops", {
        params: {
            ...data,
        },
    });
};

export const createWorkshop = async (data: any) => {
    return httpRequest.post("/school/programs-and-workshops", data);
};

export const updateWorkshop = async (id: string, data: any) => {
    return httpRequest.patch(`/school/programs-and-workshops/${id}`, data);
};

export const getWorkshopDetail = async (id: string) => {
    return httpRequest.get(`/school/programs-and-workshops/${id}`);
};

import Swal from 'sweetalert2'

export const handlingError = (val) => {
  Swal.fire({
    title: `<div className="text-left">Oops..</div>`,
    html: `<div className="text-gray-500 text-left">${val}</div>`,
    icon: 'error',
    showCancelButton: false,
    showDenyButton: false,
    width: '400px',
    confirmButtonText: 'Continue',
    customClass: {
      popup: 'rounded-xl',
      container: '',
      denyButtonColor: '#D0DDE5',
      confirmButton:
        'bg-warning capitalize border-transparent focus:border-transparent focus:ring-0 rounded-md ',
      denyButton: 'btn bg-danger',
    },
    confirmButtonColor: '#FAC61D',
  })
}

export const handlingSuccess = (val, callback) => {
  Swal.fire({
    title: 'Success',
    html: `<div className="text-left">${val}</div>`,
    width: '400px',
    showCancelButton: false,
    showDenyButton: false,
    confirmButtonText: 'Continue',
    customClass: {
      popup: 'rounded-xl',
      container: '',
      denyButtonColor: '#D0DDE5',
      confirmButton:
        'btn bg-warning border-transparent focus:border-transparent focus:ring-0 rounded-xl',
      denyButton: 'btn bg-danger',
    },
    confirmButtonColor: '#FAC61D',
  }).then(() => callback())
}

export const handlingConfirm = (val, callback) => {
  Swal.fire({
    title: `<div className="text-left">Are you sure?</div>`,
    html: `<div className="text-gray-500 text-left">${val}</div>`,
    width: '400px',
    showCancelButton: true,
    confirmButtonText: 'Continue',
    customClass: {
      popup: 'rounded-xl',
      container: '',
      denyButtonColor: '#D0DDE5',
      confirmButton:
        'bg-warning capitalize border-transparent focus:border-transparent focus:ring-0 rounded-md ',
      cancelButton:
        'bg-danger capitalize border-transparent focus:border-transparent focus:ring-0 rounded-md',
    },
    confirmButtonColor: '#FAC61D',
    cancelButtonColor: '#D0DDE5',
  }).then((result) => {
    if (result.isConfirmed) callback()
  })
}

export const handlingInfo = (val, callback) => {
  Swal.fire({
    title: '<strong>In Review</strong>',
    html:
        'Your course is successfully sent to Skilledin <br />' +
        'Green for curriculum Verification  <br />' +
        'We will notify you via email once review is  <br />' +
        'completed',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    showConfirmButton: false,
    customClass: {
      container: '',
      popup: '...',
      header: '...',
      title: '...',
      closeButton: '...',
      icon: '...',
      image: '...',
      htmlContainer: '!my-10',
      input: '...',
      inputLabel: '...',
      validationMessage: '...',
      actions: '...',
      confirmButton: '...',
      denyButton: '...',
      cancelButton: '...',
      loader: '...',
      footer: '....',
      timerProgressBar: '....',
    }
  }).then((result) => {
    if (result.isConfirmed) callback()
  })
}

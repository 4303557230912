import React from "react";

interface PageContentProps {
  header: React.ReactNode;
  content: React.ReactNode;
  extraContent?: React.ReactNode;
  extraContentBottom?: React.ReactNode;
  contentRightSidebar?: React.ReactNode;
  rootClassName?: string;
  contentRootClassName?: string;
  rightSidebarRootClassName?: string;
}

export function PageContent({
  header,
  content,
  extraContent,
  extraContentBottom,
  contentRightSidebar,
  rootClassName,
  contentRootClassName,
  rightSidebarRootClassName,
}: PageContentProps) {
  const colspanLeftSidebar = contentRightSidebar
    ? "lg:col-span-2 col-span-3"
    : "col-span-3";
  return (
    <div>
      {header}
      {extraContent && <div className=" p-6">{extraContent}</div>}
      <div className={`p-6 grid grid-cols-3 gap-5 ${rootClassName ?? ""}`}>
        <div
          className={`${colspanLeftSidebar ?? ""} flex flex-col gap-4 ${
            contentRootClassName ?? ""
          }`}>
          {content}
        </div>
        {contentRightSidebar && (
          <div
            className={`lg:col-span-1 col-span-3 ${
              rightSidebarRootClassName ?? ""
            }`}>
            {contentRightSidebar}
          </div>
        )}
      </div>
      {extraContentBottom && <div className=" px-6">{extraContentBottom}</div>}
    </div>
  );
}

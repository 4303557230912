import { message } from "antd";
import { RcFile } from "antd/es/upload";

export function openInNewTab(url: string) {
  window.open(url, "_blank");
}

// export const checkAudioTrack = async (file) => {
//   const video = document.createElement("video");
//   video.preload = "metadata";

//   const loadVideoMetaData = new Promise((resolve, reject) => {
//     video.onloadedmetadata = () => {
//       window.URL.revokeObjectURL(video.src);
//       if (
//         video.duration > 0 &&
//         video.audioTracks &&
//         video.audioTracks.length > 0
//       ) {
//         resolve(true);
//       } else {
//         reject(new Error("Video must have an audio track"));
//       }
//     };
//   });

//   video.src = URL.createObjectURL(file);
//   return loadVideoMetaData;
// };

export function checkIfVideoHasAudio(file: RcFile): Promise<boolean> {
  return new Promise((resolve) => {
    // Create a video element
    const video = document.createElement("video");

    // Set the source of the video element to be the file
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      resolve(hasAudio(video));
    };
    // When enough data has been loaded, check for audio tracks
    video.onloadedmetadata = () => {
      resolve(hasAudio(video));
    };
  });
}

interface MyVideoElement extends HTMLVideoElement {
  audioTracks?: { length: number };
  webkitAudioDecodedByteCount?: number;
  mozHasAudio?: boolean;
}

export function hasAudio(video: MyVideoElement): boolean {
  return (
    (video.audioTracks && video.audioTracks.length > 0) || // Standard
    Boolean(video.webkitAudioDecodedByteCount) || // Webkit
    Boolean(video.mozHasAudio) // Mozilla
  );
}

/**
 * Converts a data URL to a file
 * @description Converts a data URL to a file object. Used to convert a compressed image data URL to a file object.
 * Why we need this: Ant Design Upload component requires a file object to be uploaded.
 * @param dataURL The data URL to convert
 * @param fileName The name of the file
 * @returns The file
 *
 */
function dataURLtoFile(dataURL: string, fileName: string) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

/**
 * Compresses an image file to a maximum of 800x800px and 2MB
 * To be used with Ant Design Upload component beforeUpload prop
 * Also handles images with transparent background
 * Only supports jpeg and png file types
 * @param file The image file to compress
 * @returns A promise that resolves to the compressed file
 * @throws Error if the file type is not supported or the file size exceeds 2MB
 */
export function beforeUpload(file: RcFile): Promise<File> {
  const allowedTypes = ["image/jpeg", "image/png"];
  const maxSize = 2 * 1024 * 1024; // 2MB
  return new Promise((resolve, reject) => {
    // Check file type
    if (!allowedTypes.includes(file.type)) {
      reject(new Error("Invalid file type"));
      return;
    }

    // Check file size
    if (file.size > maxSize) {
      message.error("File size exceeded. Max size allowed is 2 MB");
      reject(new Error("File size exceeded"));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, width, height);

        const compressedDataURL = canvas.toDataURL(file.type); // Use original image format
        const compressedFile = dataURLtoFile(compressedDataURL, file.name);

        resolve(compressedFile);
      };
    };

    reader.onerror = (error) => reject(error);
  });
}

/**
 * Truncates a string to a specified length
 */
export const truncateText = (text: string = "", maxLength: number) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

import React from "react";
import { BasicContainer } from "./BasicContainer.tsx";
import { Skeleton } from "antd";
import { getTitleAccordingToSize, TitleSize } from "../../utils.tsx";

interface Props {
  title: string;
  isLoading?: boolean;
  titleSize?: TitleSize;
  children: React.ReactNode | React.ReactElement;
  className?: string;
  extraTitle?: React.ReactNode | React.ReactElement;
}

export const BasicContainerWithTitle: React.FC<Props> = ({
  title,
  isLoading,
  children,
  titleSize = "h2",
  className,
  extraTitle = undefined,
}) => {
  return (
    <BasicContainer className={className}>
      {isLoading ? (
        <Skeleton
          active
          loading
          title={false}
          paragraph={{ rows: 1, width: "100%" }}
        />
      ) : extraTitle ? (
        <div className='flex justify-between items-center'>
          {getTitleAccordingToSize(titleSize, title)}
          {extraTitle}
        </div>
      ) : (
        getTitleAccordingToSize(titleSize, title)
      )}
      {children}
    </BasicContainer>
  );
};

import React from "react";
import { Modal, ModalProps } from "antd";

export const NewProgramModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal title={"New Program"} {...props}>
      <form></form>
    </Modal>
  );
};

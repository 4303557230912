import React from "react"
import { PageContent } from "../../../common/layout/PageContent.tsx"
import { PageHeader } from "../../../common/layout/PageHeader.tsx"
import {
  DownOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { Button, Dropdown, Empty, Input, MenuProps, Select, Space } from "antd"
import { WorkshopCard } from "../components/WorkshopCard.tsx"
import { NewWorkshopModal } from "../components/NewWorkshopModal.tsx"
import { NewProgramModal } from "../components/NewProgramModal.tsx"
import { useQuery } from "react-query"
import { workshopAndProgramsList } from "../apis/workshops.ts"
import { getInstructorList } from "../apis/instructors.ts"
import { debounce } from "../../../common/helper/debounce.js"
import {HomeIcon} from "../../../assets/icon";

const ProgramsAndWorkshops = () => {
  const breadcrumbItems = [
      {
          title:
              <div className={"mt-1"}>
                  <HomeIcon />
              </div>,
          path: "/",
      },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Programs and Workshops",
      to: "/programs-and-workshops",
    },
  ]

  const [isWorkshopModalVisible, setIsWorkshopModalVisible] =
    React.useState(false)
  const [isProgramModalVisible, setIsProgramModalVisible] =
    React.useState(false)

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "workshop") {
      setIsWorkshopModalVisible(true)
    } else if (e.key === "program") {
      setIsProgramModalVisible(true)
    } else {
      console.error("Invalid key")
    }
  }
  return (
    <>
      <PageContent
        content={<Content />}
        header={
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title="Programs and Workshops"
            subtitle={
              <div className="golden-subtitle">
                your bespoke educational offer
              </div>
            }
            needButton
            buttonComponent={
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "Workshop",
                      key: "workshop",
                    },
                    {
                      label: "Program",
                      disabled: true,
                      key: "2",
                    },
                  ],
                  onClick: handleMenuClick,
                }}
              >
                <Button className="bg-primary text-white mb-4">
                  <Space>
                    Add New
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            }
          />
        }
      />
      <NewWorkshopModal
        open={isWorkshopModalVisible}
        onCancel={() => setIsWorkshopModalVisible(false)}
        closeModal={() => setIsWorkshopModalVisible(false)}
      />
      <NewProgramModal
        open={isProgramModalVisible}
        onCancel={() => setIsProgramModalVisible(false)}
      />
    </>
  )
}

const Content = () => {
  const instructorsQuery = useQuery("instructors", () => getInstructorList())

  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const [status, setStatus] = React.useState(undefined)
  const [trainer, setTrainer] = React.useState<string | undefined>(undefined)
  const [mode, setMode] = React.useState(undefined)

  console.log("Are these changing on select:", status, trainer, mode, search)

  const workshopListQuery = useQuery("workshops", () =>
    workshopAndProgramsList({
      title: search,
      status: status,
      trainer_id: trainer,
      mode: mode,
    })
  )

  React.useEffect(() => {
    workshopListQuery.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, trainer, mode, search])

  return (
    <>
      <div className="basic-card bg-white flex gap-4 flex-wrap items-center">
        <div
          className="flex flex-grow min-w-[80px]"
          style={{ borderBottom: "1px solid #D0DDE5" }}
        >
          <SearchOutlined />
          <Input
            bordered={false}
            onChange={(e) => {
              debounce(() => setSearch(e.target.value), 500)
            }}
            placeholder="search"
          />
        </div>
        <div className="bg-grey-select min-w-[122px]">
          <Select
            placeholder="Status"
            options={[
              {
                label: "All",
                value: "all",
              },
              { label: "Pending", value: "pending" },
              { label: "Scheduled", value: "scheduled" },
              { label: "Available", value: "available" },
            ]}
            onSelect={(value) => {
              if (value === "all") {
                setStatus(undefined)
              } else {
                setStatus(value)
              }
            }}
            className="w-full"
          />
        </div>
        <div className="bg-grey-select min-w-[122px]">
          <Select
            placeholder="Trainer"
            notFoundContent={"No status found"}
            showSearch
            options={instructorsQuery.data?.data?.data?.map((instructor) => ({
              label: instructor.display_name,
              value: instructor.id,
            }))}
            loading={instructorsQuery.isLoading}
            onSelect={(value) => setTrainer(value)}
            filterOption={false}
            className="w-full"
          />
        </div>
        <div className="bg-grey-select min-w-[122px]">
          <Select
            placeholder="Mode"
            filterOption={false}
            options={[
              {
                label: "All",
                value: "all",
              },
              { label: "On Demand", value: "on-demand" },
              { label: "Live Stream", value: "live-stream" },
              { label: "Pre Recorded", value: "pre-recorded" },
            ]}
            onSelect={(value) => {
              if (value === "all") {
                setMode(undefined)
              } else {
                setMode(value)
              }
            }}
            className="w-full"
          />
        </div>
      </div>
      <div className="basic-card bg-white">
        {workshopListQuery.isLoading ? (
          <div className="flex justify-center items-center h-40">
            <LoadingOutlined />
          </div>
        ) : !workshopListQuery.data?.data?.data?.list ? (
          <div className="h-40">
            <Empty />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {workshopListQuery.data?.data?.data?.list?.map((workshop) => (
              <WorkshopCard key={workshop.id} program={workshop} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ProgramsAndWorkshops

import { Dropdown, Empty, Space, Spin } from "antd";
import React from "react";
import { ArrowIcon } from "../../../common/components/Icons";
import { useInstructor } from "../hook/useInstructor";
import dayjs from "dayjs";

const TableDetailSkillAndCourses = ({
  isDetailLoading,
  detailSkillAndCourses,
  onSaveSubmit,
  handleSubmit,
}) => {
  const handleMenuClick = (e, levelID) => {
    const params = {
      id: detailSkillAndCourses?.id,
      level_id: levelID,
      instructor_id: e.key,
    };

    handleSubmit(onSaveSubmit(params));
  };

  const { list } = useInstructor();

  const itemTrainers =
    list &&
    list?.data?.map((item) => ({
      label: `${item?.first_name} ${item?.last_name}`,
      key: item?.id,
    }));

  const menuProps = (levelID) => {
    return {
      items: itemTrainers,
      onClick: (e) => handleMenuClick(e, levelID),
    };
  };
  const helperStatus = (status) => {
    switch (status) {
      case "ON_PROGRESS":
        return "Draft";
      case "PUBLISH":
        return "Publish";
      case "ON_REVIEW":
        return "On Review";
      case "REJECTED":
        return "Draft";
      default:
        return "Not Started";
    }
  };
  return (
    <table className="w-full mt-6">
      <thead className="grid grid-cols-12 bg-[#D4F5C5]">
        <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
          Level
        </td>
        <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
          Status
        </td>
        <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
          Assigned Trainer
        </td>

        <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
          Published on
        </td>
        <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-2">
          Updated on
        </td>
      </thead>
      {isDetailLoading ? (
        <div className="py-4 border text-center">
          <Spin spinning={isDetailLoading} size="large" />
        </div>
      ) : detailSkillAndCourses === null ? (
        <div className="py-4 border text-center">
          <Empty />
        </div>
      ) : (
        detailSkillAndCourses?.levels?.map((item, index) => (
          <tbody className="grid grid-cols-12" key={index}>
            <td className="bg-white font-medium border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-3 flex items-center">
              {item?.name}
            </td>
            <td className="bg-white font-medium border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2 flex items-center">
              {helperStatus(item?.status)}
            </td>
            <td className="bg-white font-medium border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3 flex items-center">
              <Dropdown menu={menuProps(item?.id)}>
                <div
                  className={`${
                    item?.trainer ? "bg-[#FFEFA0]" : "bg-[#D9D9D9]"
                  } border  border-backdrop px-3 py-0.5 rounded`}
                >
                  <Space className="flex items-center">
                    {item?.trainer
                      ? `${item?.trainer?.first_name} ${item?.trainer?.last_name}`
                      : "Select"}
                    <ArrowIcon
                      className="w-auto mx-auto flex justify-center text-backdrop"
                      width={10}
                      height={10}
                    />
                  </Space>
                </div>
              </Dropdown>
            </td>

            <td className="bg-white font-medium border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2 flex items-center">
              {item?.published_on
                ? dayjs(item?.item?.published_on.datetime).format("DD MMM YYYY")
                : "N/A"}
            </td>
            <td className="bg-white font-medium border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-2 flex items-center">
              {item?.updated_on
                ? dayjs(item?.updated_on.datetime).format("DD MMM YYYY")
                : "N/A"}
            </td>
          </tbody>
        ))
      )}
    </table>
  );
};

export default TableDetailSkillAndCourses;

import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import Lottie from "lottie-react";
import astronaut from "../../../assets/json/astronaut_sitdown.json";
import { Button } from "antd";
import { usePayouts } from "../hook/usePayouts";
import {HomeIcon} from "../../../assets/icon";

const Payouts = () => {
  const action = usePayouts();
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      title: "Admin",
    },
    {
      path: "/trainer-admin/payout",
      title: "Payouts",
    },
  ];
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop">
          Payouts and Stripe Settings
        </h1>
      </div>
      <div className="mx-5 px-5 py-5 my-5 bg-white">
        <div className=" text-end">
          <Button
            type="primary"
            className="bg-[#86C769]"
            onClick={action?.handleAccountLoginLink}
            loading={action?.isLoading}
          >
            Edit your payout details
          </Button>
        </div>
        <div className=" my-28">
          <div className=" h-28 w-28 mx-auto my-5">
            <Lottie className="h-28 w-28" animationData={astronaut} />
          </div>
          <div className=" text-center first-letter:capitalize">
            payout list still coming soon, but you can set up your account
            connect stripe with button on top
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;

import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { initialPublicStoreState } from '../hooks/InitPublicStore.ts'

interface ModalConfirmProps {
  // callback: () => void // Callback to perform the delete action
}

const ModalConfirm: React.FC<ModalConfirmProps> = () => {
  const { isVisible, onConfirm, onCancel, closeModal } = initialPublicStoreState()
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    closeModal()
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (onConfirm) {
      try {
        await onConfirm()
      } catch (error) {
        console.error('Error executing onConfirm:', error)
      }
    }
    setLoading(false)
    closeModal()
  }

  return (
    <Modal
      title=""
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <p className='font-bold text-center text-xl mb-10'>Are you sure you want to proceed?</p>

      <div className="flex justify-center mt-4 gap-x-6">
        <Button key="cancel" onClick={handleCancel} className='rounded bg-red-400 text-white px-10'>
          Cancel
        </Button>
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit} className='rounded bg-primary text-white px-10'>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirm

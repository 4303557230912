import React from "react"
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Skeleton, Tag, Typography } from "antd"
import { Link, useNavigate } from "react-router-dom"
import { initialProgramStoreState } from "../store/programStore.ts"
import { useProgramBuilder } from "../hook/useProgram.ts"

interface PageHeaderTransparentWrapperProps {
  children: React.ReactNode
  className?: string
}

export const PageHeaderTransparentWrapper: React.FC<PageHeaderTransparentWrapperProps> = ({
  children,
  className,
}) => (
  <div className={`bg-transparent p-4 px-6 w-full ${className ?? ""}`}>
    {children}
  </div>
)

interface PageTitleProps {
  title?: string | React.ReactNode | React.ReactElement | any
  first_name?: string
  last_name?: string
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  first_name,
  last_name,
}) => {
  if (typeof title === "string") {
    return <Typography.Title className="!text-white">{title}</Typography.Title>
  } else if (
    typeof title === "object" &&
    (title?.type === "span" || title?.type === "div")
  ) {
    return title
  }
  return (
    <Typography.Title>{`Hello, ${first_name} ${last_name}!`}</Typography.Title>
  )
}

interface PageHeaderTransparentProps {
  breadcrumbItems?: any
  title?: string | React.ReactNode
  className?: string
  isLoading?: boolean
  isError?: boolean
  subtitle?: string | React.ReactNode
  isHeaderForAccountInfo?: boolean
  children?: React.ReactNode | React.ReactElement
  buttonComponent?: React.ReactNode | React.ReactElement
  showBack?: boolean
  needButton?: boolean
}

export const PageHeaderTransparent: React.FC<PageHeaderTransparentProps> = ({
  breadcrumbItems,
  title,
  className,
  isLoading,
  isError,
  subtitle,
  isHeaderForAccountInfo,
  children,
  showBack,
  needButton,
  buttonComponent,
  ...rest
}) => {
  const navigate = useNavigate()

  const { programDetailData, loadingDetailPorgram, setOpenModalNewProgram, setIsEditAction } = initialProgramStoreState()

  const { handleNotifyTrainer } = useProgramBuilder()

  if (isError) {
    return (
      <PageHeaderTransparentWrapper className={className ?? ""}>
        <div className="text-danger">
          Error. Please try again or contact us, if the problem persists.
        </div>
      </PageHeaderTransparentWrapper>
    )
  }

  if (isLoading) {
    return (
      <PageHeaderTransparentWrapper className={className ?? ""}>
        <Skeleton active avatar />
      </PageHeaderTransparentWrapper>
    )
  }


  return (
    <PageHeaderTransparentWrapper
      {...rest}
      className={className ? className : (rest as any)?.className}>
      <Breadcrumb
        separator={<><span className="text-white"> &gt; </span></>}
        className="font-default !text-white"
        items={
          breadcrumbItems?.map((itm: any, i: number) => ({
            title: itm.to ? (
              <Link className="!text-white" to={itm.to}>
                <Typography.Paragraph className={`cursor-pointer text-white ${i === breadcrumbItems.length - 1 ? '!font-bold' : '!font-thin'}`}>{itm.title}</Typography.Paragraph>
              </Link>
            ) : (
              <Typography.Paragraph className={`cursor-pointer text-white ${i === breadcrumbItems.length - 1 ? '!font-bold' : '!font-thin'}`}>{itm.title}</Typography.Paragraph>
            ),
          }))
        }
      />
      {showBack && (
        <div className="mt-3 flex items-center gap-x-4">
          <ArrowLeftOutlined onClick={() => navigate(-1)}
            className="cursor-pointer transition-transform transform hover:scale-[101%] text-white" />

          <p className="text-xs text-[#FFEFA0] tracking-widest">PROGRAM BUILDER</p>
        </div>
      )}
      {title && (
        <div
          className={`mt-8 ${needButton ? "flex justify-between gap-x-4" : ""
            }`}>
          <div className="flex items-center gap-x-7">
            <div></div>
            <div className="space-y-1 w-full">
              <div className="flex items-center justify-between w-full gap-x-10">
                {loadingDetailPorgram ? <Skeleton.Button block active /> : <PageTitle title={title} />}

                {loadingDetailPorgram ? <Skeleton.Button active /> : <EditOutlined onClick={() => {
                  setOpenModalNewProgram(true)
                  setIsEditAction(true)
                }} className="text-white cursor-pointer" />}

              </div>

              <div className="w-full">
                {loadingDetailPorgram ? <Skeleton paragraph={{ rows: 2 }} className="mt-6" active /> : subtitle && <div className=" text-white leading-7 line-clamp-4">{subtitle}</div>}
              </div>


              <div className="flex items-center justify-between !mt-10">
                <div className="flex items-center gap-x-5">
                  {loadingDetailPorgram ?
                    Array.from({ length: 4 }, (_: any, index: number) =>
                    (
                      <div key={index}>
                        <Skeleton.Button className="!rounded-full" active />
                      </div>
                    )) : <div className="flex items-center gap-x-3">
                      <Tag
                        className="!bg-white !border-white !text-black rounded-full"
                      >{programDetailData?.total_course} courses</Tag>
                      <Tag
                        className="!bg-white !border-white !text-black rounded-full"
                      >{programDetailData?.total_lesson} lessons</Tag>
                      <Tag
                        className="!bg-white !border-white !text-black rounded-full"
                      >{programDetailData?.skills?.length} Skills</Tag>
                      <Tag
                        className="!bg-white !border-white !text-black rounded-full"
                      >{programDetailData?.total_trainer} trainers</Tag>
                    </div>}
                </div>

                <div className="flex items-center gap-x-5">
                  {loadingDetailPorgram ?
                    Array.from({ length: 2 }, (_: any, index: number) =>
                    (
                      <div key={index}>
                        <Skeleton.Button block className="" key={index} active />
                      </div>
                    )) : <div className="flex items-center gap-x-3">
                      <Button type="default" className="bg-white text-black border-primary" onClick={() => navigate('certificate')}>Preview Certificate</Button>
                      <Button disabled={programDetailData?.action_button?.disabled_button} type="default" className={`${programDetailData?.action_button?.disabled_button ? '!bg-white !text-gray-400' : 'bg-primary text-white border-white'} `} onClick={() => handleNotifyTrainer()}>Notify Trainer</Button>
                    </div>}
                </div>
              </div>
            </div>
          </div>
          {needButton && buttonComponent}
        </div>
      )
      }
      {children}
    </PageHeaderTransparentWrapper >
  )
}

import { useMutation, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { useContext } from "react";
import { SkillAndLevelContext } from "../contexts/SkillAndLevelContext";
import { message } from "antd";

export const useEncodeLessonVideo = () => {
  const queryClient = useQueryClient();
  const { skill_id, level_id } = useContext(SkillAndLevelContext);
  return useMutation(
    async (data) => {
      return httpRequest.post("/school/my-courses/encode-lesson-video", data);
    },
    {
      mutationKey: "encodeLessonVideo",
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([
            "detail-skill-level",
            skill_id,
            level_id,
          ]);
        }, 6000);
      },
    }
  );
};

export const useDeleteLessonVideo = () => {
  const queryClient = useQueryClient();
  const { skill_id, level_id } = useContext(SkillAndLevelContext);
  return useMutation(
    async ({ lesson_id, module_id, video_file_name }) => {
      return httpRequest.post("/school/my-courses/remove-lesson-video", {
        lesson_id,
        module_id,
        video_file_name,
      });
    },
    {
      onSuccess: () => {
        message.success(
          "Video deleted successfully. You'll see the changes soon, or you can refresh the page."
        );
        setTimeout(() => {
          queryClient.invalidateQueries([
            "detail-skill-level",
            skill_id,
            level_id,
          ]);
        }, 6000);
      },
    }
  );
};

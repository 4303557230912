import React from "react";

export type TitleSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const getTitleAccordingToSize = (
  titleSize: TitleSize,
  title: string
) => {
  switch (titleSize) {
    case "h1":
      return <h1 className='capitalize'>{title}</h1>;
    case "h2":
      return <h2 className='capitalize'>{title}</h2>;
    case "h3":
      return <h3 className='capitalize'>{title}</h3>;
    case "h4":
      return <h4 className='capitalize'>{title}</h4>;
    case "h5":
      return <h5 className='capitalize'>{title}</h5>;
    case "h6":
      return <h6 className='capitalize'>{title}</h6>;
    default:
      return <h2 className='capitalize'>{title}</h2>;
  }
};

export const swapInArray = <T extends { order: number }>(
  index1: number,
  index2: number,
  array: T[]
): T[] => {
  const arrayCopy = array.map((item) => ({ ...item }));
  const temp = arrayCopy[index1];
  arrayCopy[index1] = arrayCopy[index2];
  arrayCopy[index2] = temp;

  arrayCopy.map((item, i) => ({
    ...item,
    order: i + 1,
  }));

  return arrayCopy;
};

import { useQuery, useMutation } from "react-query";
import { ApiResponse } from "../../../common/types/ApiResponse";
import httpRequest from "../../../config/config";
import {
  CourseData,
  Lesson,
  ModuleOverviewData,
  QuestionData,
  QuizQuestion,
} from "../types/common";
import { message } from "antd";

type BasicQueryParams = {
  course_id: string;
  level_id: string;
};

/* Assessments */
type AddAssessmentParams = BasicQueryParams & {
  formData: QuizQuestion;
};

type UpdateAssessmentParams = BasicQueryParams &
  AddAssessmentParams & {
    assessment_id: string;
  };

/* Get Assessment Detail */
const getCourseLevelAssessmentList = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
): Promise<ApiResponse<QuestionData[]>> => {
  return httpRequest.get(
    `/school/my-courses/${course_id}/level/${level_id}/assessment`
  );
};

/* Add Assessment */
const addCourseLevelAssessment = (
  course_id: string,
  level_id: string,
  formData: QuizQuestion
): Promise<ApiResponse<QuestionData>> => {
  return httpRequest.post(
    `/school/my-courses/${course_id}/level/${level_id}/assessment`,
    formData
  );
};

/* Delete Assessment */
const deleteCourseLevelAssessment = (
  course_id: string,
  level_id: string,
  assessment_id: string
): Promise<ApiResponse<any>> => {
  return httpRequest.delete(
    `/school/my-courses/${course_id}/level/${level_id}/assessment/${assessment_id}`
  );
};

/* Update Assessment */
const updateCourseLevelAssessment = (
  course_id: string,
  level_id: string,
  assessment_id: string,
  formData: QuizQuestion
): Promise<ApiResponse<QuestionData>> => {
  return httpRequest.patch(
    `/school/my-courses/${course_id}/level/${level_id}/assessment/${assessment_id}`,
    formData
  );
};

/**
 *
 * @param course_id
 * @param level_id
 * @returns Promise of CourseData[]
 * @method GET
 * @description Returns the result of a get request that fetches the course level assessment list based on the course_id and level_id
 * */
export const useFetchCourseLevelAssessmentList = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
) => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while fetching course level assessment list: ${error}`
    );
  };

  return useQuery(
    ["course-level-assessment-list", course_id, level_id],
    () => getCourseLevelAssessmentList(course_id, level_id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !!course_id && !!level_id,
    }
  );
};

/**
 * @method POST
 * @description Returns the result of a useMutation hook that adds a course level assessment based on the course_id, level_id, assessment_id and formData
 */
export const useAddCourseLevelAssessment = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while adding course level assessment: ${error}`
    );
  };
  return useMutation(
    ({ course_id, level_id, formData }: AddAssessmentParams) =>
      addCourseLevelAssessment(course_id, level_id, formData),
    {
      onError: handleFetchError,
      onSuccess: (data, variables, context) => {
        message.success(`New Assessment added`);
      },
    }
  );
};

/**
 * @method DELETE
 * @description Returns the result of a useMutation hook that deletes a course level assessment based on the course_id, level_id and assessment_id
 */
export const useDeleteCourseLevelAssessment = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while deleting course level assessment: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      assessment_id,
      level_id,
    }: BasicQueryParams & { assessment_id: string }) =>
      deleteCourseLevelAssessment(course_id, level_id, assessment_id),
    {
      onError: handleFetchError,
      onSuccess: (data, variables, context) => {
        message.success(`Assessment deleted successfully`);
      },
    }
  );
};

/**
 * @method PATCH
 * @description Returns the result of a useMutation hook that updates a course level assessment based on the course_id, level_id, assessment_id and formData
 */
export const useUpdateCourseLevelAssessment = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while updating course level assessment: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      assessment_id,
      level_id,
      formData,
    }: UpdateAssessmentParams) =>
      updateCourseLevelAssessment(course_id, level_id, assessment_id, formData),
    {
      onError: handleFetchError,
    }
  );
};

/* Module Detail */
export type AddCourseLevelModuleData = {
  glossary: string | undefined;
  introduction: string | undefined;
  recap: string | undefined;
  reflection: string | undefined;
  resources: string | undefined;
  title: string | undefined;
};
const getCourseLevelModuleDetail = (
  course_id: string | undefined | null,
  level_id: string | undefined | null,
  module_id: string | undefined | null
): Promise<ApiResponse<CourseData>> => {
  return httpRequest.get(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}`
  );
};

const updateCourseLevelModule = (
  course_id: string,
  level_id: string,
  module_id: string,
  formData: AddCourseLevelModuleData
): Promise<ApiResponse<CourseData>> => {
  return httpRequest.patch(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}`,
    formData
  );
};

/**
 * @param course_id
 * @param level_id
 * @param module_id
 * @returns
 *
 * @method GET
 * @description Returns the result of a useQuery hook that fetches the course level module detail based on the course_id, level_id and module_id
 */
export const useFetchCourseLevelModuleDetail = (
  course_id: string | undefined | null,
  level_id: string | undefined | null,
  module_id: string | undefined | null
) => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while fetching course level module detail: ${error}`
    );
  };

  return useQuery(
    ["course-level-module-detail", course_id, level_id, module_id],
    () => getCourseLevelModuleDetail(course_id, level_id, module_id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !!course_id && !!level_id && !!module_id,
    }
  );
};

/**
 * @method PATCH
 * @description Returns the result of a useMutation hook that updates a course level module based on the course_id, level_id, module_id and formData
 */
export const useUpdateCourseLevelModule = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while updating course level module: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      level_id,
      module_id,
      formData,
    }: {
      course_id: string;
      level_id: string;
      module_id: string;
      formData: AddCourseLevelModuleData;
    }) => updateCourseLevelModule(course_id, level_id, module_id, formData),
    {
      onError: handleFetchError,
    }
  );
};

/* Levels */
type AddCourseForm = {
  description: string;
  order: number;
  file: File | null;
};

type UpdateCourseForm = {
  description?: string;
  order?: number;
  file?: File | null;
};

/* Add Lesson */
const addCourseLevelModuleLesson = (
  course_id: string,
  level_id: string,
  module_id: string,
  formData: AddCourseForm
): Promise<ApiResponse<Lesson>> => {
  return httpRequest.post(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}/lesson`,
    formData
  );
};

/* Delete Lesson */
const deleteCourseLevelModuleLesson = (
  course_id: string,
  level_id: string,
  module_id: string,
  lesson_id: string
): Promise<ApiResponse<any>> => {
  return httpRequest.delete(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}/lesson/${lesson_id}`
  );
};

/* Update Lesson */
const updateCourseLevelModuleLesson = (
  course_id: string | undefined | null,
  level_id: string | undefined | null,
  module_id: string | undefined | null,
  lesson_id: string | undefined | null,
  formData: UpdateCourseForm
): Promise<ApiResponse<CourseData>> => {
  const data = new FormData();
  if (formData?.description) data.append("description", formData?.description);
  if (formData?.order) data.append("order", (formData?.order).toString());
  if (formData.file) {
    data.append("file", formData.file);
  }
  return httpRequest.patch(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}/lesson/${lesson_id}`,
    data
  );
};

/**
 *
 * @param course_id
 * @param level_id
 * @returns Promise of CourseData[]
 * @method GET
 * @description Returns the result of a get request that fetches the course level module list based on the course_id and level_id
 * */
const getCourseLevelModuleList = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
): Promise<ApiResponse<CourseData[]>> => {
  return httpRequest.get(
    `/school/my-courses/${course_id}/level/${level_id}/modules`
  );
};

/**
 * @method POST
 * @description Returns the result of a useMutation hook that adds a course level module lesson based on the course_id, level_id, module_id and formData
 */
export const useAddCourseLevelModuleLesson = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while adding course level module lesson: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      level_id,
      module_id,
      formData,
    }: {
      course_id: string;
      level_id: string;
      module_id: string;
      formData: AddCourseForm;
    }) => addCourseLevelModuleLesson(course_id, level_id, module_id, formData),
    {
      onError: handleFetchError,
    }
  );
};

/**
 * @method DELETE
 * @description Returns the result of a useMutation hook that deletes a course level module lesson based on the course_id, level_id, module_id and lesson_id
 */
export const useDeleteCourseLevelModuleLesson = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while deleting course level module lesson: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      level_id,
      module_id,
      lesson_id,
    }: {
      course_id: string;
      level_id: string;
      module_id: string;
      lesson_id: string;
    }) =>
      deleteCourseLevelModuleLesson(course_id, level_id, module_id, lesson_id),
    {
      onError: handleFetchError,
    }
  );
};

/**
 * @method PATCH
 * @description Returns the result of a useMutation hook that updates a course level module lesson based on the course_id, level_id, module_id, lesson_id and formData
 */
export const useUpdateCourseLevelModuleLesson = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while updating course level module lesson: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      level_id,
      module_id,
      lesson_id,
      formData,
    }: {
      course_id: string | undefined | null;
      level_id: string | undefined | null;
      module_id: string | undefined | null;
      lesson_id: string | undefined | null;
      formData: UpdateCourseForm;
    }) =>
      updateCourseLevelModuleLesson(
        course_id,
        level_id,
        module_id,
        lesson_id,
        formData
      ),
    {
      onError: handleFetchError,
    }
  );
};

/**
 * @method GET
 * @description Returns the result of a useQuery hook that fetches the course level module list based on the course_id and level_id
 */
export const useFetchCourseLevelModuleList = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
) => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while fetching course level module list: ${error}`
    );
  };

  return useQuery(
    ["course-level-module-list", course_id, level_id],
    () => getCourseLevelModuleList(course_id, level_id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !!course_id && !!level_id,
    }
  );
};

export type OverviewForm = {
  certification_description: string | undefined;
  module_1st_outcome: string | undefined;
  module_2nd_outcome: string | undefined;
  module_3rd_outcome: string | undefined;
  module_4th_outcome: string | undefined;
};

/* Course Overview */

const getCourseLevelOverview = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
): Promise<ApiResponse<ModuleOverviewData>> => {
  return httpRequest.get(
    `/school/my-courses/${course_id}/level/${level_id}/overview`
  );
};

const updateCourseLevelOverview = (
  course_id: string,
  level_id: string,
  formData: OverviewForm
): Promise<ApiResponse<ModuleOverviewData>> => {
  return httpRequest.patch(
    `/school/my-courses/${course_id}/level/${level_id}/overview`,
    formData
  );
};

/**
 * @method GET
 * @description Returns the result of a useQuery hook that fetches the course level overview based on the course_id and level_id
 */
export const useFetchCourseLevelOverview = (
  course_id: string | undefined | null,
  level_id: string | undefined | null
) => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while fetching course level overview: ${error}`
    );
  };

  return useQuery(
    ["course-level-overview", course_id, level_id],
    () => getCourseLevelOverview(course_id, level_id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !!course_id && !!level_id,
    }
  );
};

/**
 * @method PATCH
 * @description Returns the result of a useMutation hook that updates a course level overview based on the course_id, level_id and formData
 */
export const useUpdateCourseLevelOverview = () => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while updating course level overview: ${error}`
    );
  };
  return useMutation(
    ({
      course_id,
      level_id,
      formData,
    }: {
      course_id: string;
      level_id: string;
      formData: OverviewForm;
    }) => updateCourseLevelOverview(course_id, level_id, formData),
    {
      onError: handleFetchError,
    }
  );
};

/* Pdfs */
const removePdf = (
  course_id: string | undefined | null,
  level_id: string | undefined | null,
  module_id: string | undefined | null,
  lesson_id: string | undefined | null,
  formData: { pdf_url: string }
): Promise<ApiResponse<any>> => {
  return httpRequest.delete(
    `/school/my-courses/${course_id}/level/${level_id}/module/${module_id}/lesson/${lesson_id}/pdf`,
    { data: formData }
  );
};

export const useRemovePdf = () => {
  const handleFetchError = (error: string) => {
    message.error(`An error occurred while removing pdf: ${error}`);
  };
  return useMutation(
    ({
      course_id,
      level_id,
      module_id,
      lesson_id,
      formData,
    }: {
      course_id: string;
      level_id: string;
      module_id: string;
      lesson_id: string;
      formData: { pdf_url: string };
    }) => removePdf(course_id, level_id, module_id, lesson_id, formData),
    {
      onError: handleFetchError,
    }
  );
};

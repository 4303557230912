import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import { SchoolInstructors } from "../components/SchoolInstructors";
import { InfoTotalCourse } from "../components/InfoTotalCourse";
import ListCourse from "../components/ListCourse";
import { useDashboard } from "../hooks/useDashboard";
import { Skeleton } from "antd";
import {HomeIcon} from "../../../assets/icon";

const Dashboard = () => {
  const { data, loading, view } = useDashboard();
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      path: "/dashboard",
      title: "Dashboard",
    },
  ];
  return (
    <div>
      <div className="px-6 py-2 bg-white ">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop">Dashboard</h1>
      </div>

      <div className="mx-5 px-5 py-5 my-5 bg-white flex flex-wrap gap-5 ">
        {loading ? (
          <Skeleton active paragraph={{ rows: 7 }} />
        ) : (
          <>
            <div className=" w-[300px]">
              <SchoolInstructors data={data?.school} peoples={data?.users} />
            </div>
            <div className=" flex-grow">
              <InfoTotalCourse
                data={{
                  learners: data?.total_learners,
                  teaching: data?.total_teach_skill,
                  published: data?.total_published_course,
                }}
                course={data?.course_status}
              />
              <div className=" ">
                <ListCourse courses={data?.courses} view={view} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

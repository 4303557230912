/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import React from "react";
// import useOnboarding from "../hooks/useOnboarding";

export const StripeOnboard = ({ stripe }) => {
  // const {
  //   user,
  //   handleGenerateLink,
  //   loadingUpdateUser,
  //   loadingGenerateLink,
  //   refetch,
  // } = useOnboarding();
  // useEffect(() => {
  //   if (!user?.data?.data?.link_onboarding_stripe) {
  //     handleGenerateLink();
  //   }
  // }, [user?.data?.data]);

  // useEffect(() => {
  //   if (refetch) {
  //     handleGenerateLink();
  //   }
  // }, [refetch]);

  return (
    <div className="w-[680px] mx-auto">
      <p className="px-3">
        Skilledin Pte. Ltd. uses Stripe to get your School paid quickly and keep
        your School’s payment information secure. Thousands of companies around
        the world trust Stripe to process payments for their users.
      </p>
      <p className="mt-5 px-3">
        Set up a Stripe account to get paid with Skilledin Green for the courses
        assigned to your School.
      </p>
      <p className="mt-5 bg-[#FFEFA0] p-3">
        Please, note: the payments will be transferred to the School’s account.
        Financial settlements between your School and the instructors teaching
        in it, should be handled by your School, and not Skilledin.
      </p>
      <div className="text-center mt-10">
        <Button
          type="primary"
          className="bg-[#86C769] w-48"
          onClick={stripe?.handleGenerate}
          loading={stripe?.isLoading}
        >
          Set up Stripe Connect
        </Button>
      </div>
    </div>
  );
};

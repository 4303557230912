export interface Trainer {
  id: string;
  name: string;
  email: string;
  avatar: string;
}

export interface Program {
  id: string;
  trainer_id: string;
  title: string;
  cover_image: string;
  is_replayable: boolean;
  is_paid: boolean;
  price: number;
  replay_price: number;
  mode: WorkshopMode;
  status: WorkshopStatus;
  type: WorkshopType;
  description: string;
  material: string;
  trainer: Trainer;
  media: any; // Replace 'any' with appropriate type if known
  documents: any; // Replace 'any' with appropriate type if known
  livestream_date: Date | null;
  release_date: Date | null;
  published_at: Date | null;
  created_at: Date;
  updated_at: Date | null;
}

export enum WorkshopStatus {
  PENDING = "pending trainer action",
  SCHEDULED = "scheduled",
  AVAILABLE = "available",
  EXPRIED = "expired",
}

export enum WorkshopType {
  WORKSHOP = "workshop",
  PROGRAM = "program",
}

export enum WorkshopMode {
  ON_DEMAND = "on-demand",
  LIVE_STREAM = "live-stream",
  PRE_RECORDED = "pre-recorded",
}

import React from "react";
import { BasicVerticalContainer } from "./BasicVerticalContainer.tsx";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useNavigate } from "react-router";
import { useFormContext } from "react-hook-form";
import { Lesson, TabNames } from "../../types/common.ts";

interface EditorSidebarProps {
  type: TabNames;
  skill_id: string;
  publishCourse: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  saveChanges: () => Promise<any>;
  lessons?: Lesson[];
  saveOnExit?: () => void;
}

const isModule = (type: TabNames) => {
  return (
    type === "module_1" ||
    type === "module_2" ||
    type === "module_3" ||
    type === "module_4"
  );
};

const getOverviewTabChecklist = (overviewErrors) => {
  return [
    {
      title: "Level description",
      isDone: !!overviewErrors?.certification_description === false,
    },
    {
      title: "Module 1 description",
      isDone: !!overviewErrors?.module_1st_outcome === false,
    },
    {
      title: "Module 2 description",
      isDone: !!overviewErrors?.module_2nd_outcome === false,
    },
    {
      title: "Module 3 description",
      isDone: !!overviewErrors?.module_3rd_outcome === false,
    },
    {
      title: "Module 4 description",
      isDone: !!overviewErrors?.module_4th_outcome === false,
    },
  ];
};
// if module name is module_1 then dont include object for recap
const getModuleTabChecklist = (moduleErrors, moduleName: TabNames) => {
  if (moduleName === "module_1") {
    return [
      {
        title: "Introduction",
        isDone: !!moduleErrors?.introduction === false,
      },
      {
        title: "Lessons",
        isDone: !!moduleErrors?.lessons === false,
      },
      {
        title: "Resources",
        isDone: !!moduleErrors?.resources === false,
      },
      {
        title: "Glossary",
        isDone: !!moduleErrors?.glossary === false,
      },
      {
        title: "Self-reflection questions",
        isDone: !!moduleErrors?.reflection === false,
      },
    ];
  }
  return [
    {
      title: "Recap",
      isDone: !!moduleErrors?.recap === false,
    },
    {
      title: "Introduction",
      isDone: !!moduleErrors?.introduction === false,
    },
    {
      title: "Lessons",
      isDone: !!moduleErrors?.lessons === false,
    },
    {
      title: "Resources",
      isDone: !!moduleErrors?.resources === false,
    },
    {
      title: "Glossary",
      isDone: !!moduleErrors?.glossary === false,
    },
    {
      title: "Self-reflection questions",
      isDone: !!moduleErrors?.reflection === false,
    },
  ];
};

export const EditorSidebar: React.FC<EditorSidebarProps> = ({
  type,
  skill_id,
  publishCourse,
  saveChanges,
  lessons,
  saveOnExit,
}) => {
  const navigate = useNavigate();
  const {
    formState: { errors },
  } = useFormContext();

  const isErrorInOverview = Object.keys(errors?.overview ?? {})?.length > 0;
  const isErrorInModule1 = Object.keys(errors?.module_1 ?? {})?.length > 0;
  const isErrorInModule2 = Object.keys(errors?.module_2 ?? {})?.length > 0;
  const isErrorInModule3 = Object.keys(errors?.module_3 ?? {})?.length > 0;
  const isErrorInModule4 = Object.keys(errors?.module_4 ?? {})?.length > 0;
  const isErrorInAssessment = Object.keys(errors?.assessment ?? {})?.length > 0;
  const secondaryTitle =
    type === "overview"
      ? "Course Overview"
      : isModule(type)
      ? "Current Module Checklist"
      : "Skill Assessment Checklist";
  return (
    <BasicVerticalContainer className='bg-dirtyBlue flex flex-col sticky top-20'>
      <h3>Course Details Checklist</h3>
      <div className='flex gap-x-2'>
        <EditorChecklistCircle
          isDone={isErrorInOverview === false}
          title='O'
        />
        <EditorChecklistCircle
          isDone={isErrorInModule1 === false}
          title='1'
        />
        <EditorChecklistCircle
          isDone={isErrorInModule2 === false}
          title='2'
        />
        <EditorChecklistCircle
          isDone={isErrorInModule3 === false}
          title='3'
        />
        <EditorChecklistCircle
          isDone={isErrorInModule4 === false}
          title='4'
        />
        <EditorChecklistCircle
          isDone={isErrorInAssessment === false}
          title='A'
        />
      </div>
      <div className='grid grid-cols-2 gap-x-[10px] mt-4'>
        <Popconfirm
          title='Are you sure you want to publish this course?'
          okText='Yes'
          disabled={
            Object.values(errors ?? {})?.length > 0 ||
            isErrorInAssessment ||
            isErrorInModule4 ||
            isErrorInModule3 ||
            isErrorInModule2 ||
            isErrorInModule1 ||
            isErrorInOverview
          }
          cancelText='No'
          okButtonProps={{
            className: "bg-primary text-white",
            disabled:
              Object.values(errors ?? {})?.length > 0 ||
              isErrorInAssessment ||
              isErrorInModule4 ||
              isErrorInModule3 ||
              isErrorInModule2 ||
              isErrorInModule1 ||
              isErrorInOverview,
          }}
          onConfirm={() => publishCourse()}>
          <Button
            disabled={
              Object.values(errors ?? {})?.length > 0 ||
              isErrorInAssessment ||
              isErrorInModule4 ||
              isErrorInModule3 ||
              isErrorInModule2 ||
              isErrorInModule1 ||
              isErrorInOverview
            }
            className='  col-span-2'
            style={{ width: "100%", backgroundColor: "white" }}>
            Publish Course
          </Button>
        </Popconfirm>
        {false && (
          <Button
            className='bg-primary text-white col-span-1'
            onClick={saveChanges}>
            Save Changes
          </Button>
        )}
      </div>
      <h3 className='mt-4'>{secondaryTitle}</h3>
      {type === "assessment" && (
        <div className='mt-4'>Add minimum 10 questions</div>
      )}
      {isModule(type) && (
        <div className='mt-4 flex flex-col gap-y-[18px]'>
          {getModuleTabChecklist(errors?.[type], type)?.map((value) => (
            <>
              <div className='flex justify-between items-center'>
                <div className=''>{value?.title}</div>
                {value?.isDone ? (
                  <CheckCircleFilled className='text-[#86C769]' />
                ) : (
                  <CheckCircleOutlined className='text-backdrop' />
                )}
              </div>
              {value?.title === "Lessons" && (
                <div className='flex gap-x-2 -mb-2 -mt-3'>
                  {lessons?.map((item, index) => (
                    <EditorChecklistCircle
                      // @ts-ignore
                      isDone={!!errors?.[type]?.lessons?.[index] === false}
                      title={item?.order?.toString()}
                    />
                  ))}
                </div>
              )}
            </>
          ))}
        </div>
      )}
      {type === "overview" && (
        <div className='mt-4 flex flex-col gap-y-[18px]'>
          {getOverviewTabChecklist(errors?.overview)?.map((value) => (
            <div className='flex justify-between items-center'>
              <div className=''>{value?.title}</div>
              {value?.isDone ? (
                <CheckCircleFilled className='text-[#86C769]' />
              ) : (
                <CheckCircleOutlined className='text-backdrop' />
              )}
            </div>
          ))}
        </div>
      )}

      <Button
        className='mt-5 bg-backdrop text-white'
        onClick={() => {
          if (saveOnExit) saveOnExit();
          navigate(`/courses/detail/${skill_id}`);
        }}
        icon={<LogoutOutlined />}>
        Exit Course Editor
      </Button>
    </BasicVerticalContainer>
  );
};

const EditorChecklistCircle: React.FC<{ isDone: boolean; title: string }> = ({
  isDone,
  title,
}) => {
  return (
    <div
      className={`rounded-full h-6 w-6 flex items-center justify-center border ${
        isDone
          ? "border-primary text-primary bg-primary-bg"
          : "text-backdrop border-backdrop bg-white"
      }`}>
      {title}
    </div>
  );
};

import { Empty, Spin } from "antd";
import Lottie from "lottie-react";
import React from "react";
import spinner_loading_one from "../../../../assets/json/spinner_loading_one.json";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  leftRightSidebar: React.ReactNode | null;
  mainContent: React.ReactNode;
  isLoading?: boolean;
  isOpacityLoading?: boolean;
  isEmpty?: boolean;
}

export const CourseEditorLayout: React.FC<Props> = ({
  leftRightSidebar,
  mainContent,
  isLoading,
  isOpacityLoading,
  isEmpty,
}) => {
  if (isLoading)
    return (
      <div className='flex justify-center flex-col items-center h-screen'>
        <Lottie
          className='w-40'
          animationData={spinner_loading_one}
        />
        Loading...
      </div>
    );
  if (isEmpty)
    return (
      <div className='h-screen w-full'>
        <Empty description='We are unable to fetch your course at this moment in time. Please refresh the page, go back and try again, or contact us if the problem persists.' />
      </div>
    );
  return (
    <div className='grid grid-cols-12 px-4 py-5 pb-9 gap-[25px] min-h-screen'>
      <div className='col-span-3 h-full relative'>{leftRightSidebar}</div>
      <div className='col-span-9 px-[22px] py-[14px] bg-white'>
        {mainContent}
        {isOpacityLoading && (
          <div className='absolute inset-0 bg-black opacity-50 flex items-center justify-center'>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24 }}
                  spin
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

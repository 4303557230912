import { TabNames } from "../types/common";

export const checkTabName = (tabName: string): tabName is TabNames => {
  return (
    tabName === "overview" ||
    tabName === "module_1" ||
    tabName === "module_2" ||
    tabName === "module_3" ||
    tabName === "module_4" ||
    tabName === "assessment"
  );
};

export type ModuleField =
  | "glossary"
  | "introduction"
  | "recap"
  | "reflection"
  | "resources";

const conceptual_supports = ["glossary", "reflection", "resources"];
const overviews = ["introduction", "title", "recap"];

export const whichCategory = (name: ModuleField) => {
  if (conceptual_supports.includes(name)) return "conceptual_supports";
  if (overviews.includes(name)) return "overview";
  return "";
};

export const shouldValueBeUpdated = (value: string, oldValue: string) => {
  const isValueChanged = value && value !== oldValue;
  const isValueNotEmpty = value !== "" && value !== null && value !== undefined;
  return isValueChanged && isValueNotEmpty;
};

import httpRequest from "../../../config/config"

export const FetchSkillAndCourses = (params) => {
  return httpRequest.get(`/school/skills-and-courses`, {
    params,
  })
}
export const FetchDetailSkillAndCourses = (id) => {
  return httpRequest.get(`/school/skills-and-courses/${id}`)
}
export const UpdateSkillAndCourses = (id, params={}) => {
  console.log('data to edit', id, params)
  return httpRequest.patch(`/school/skills-and-courses/${id}`, {
    id: params?.id,
    level_id: params?.level_id,
    instructor_id: params?.instructor_id,
})
}

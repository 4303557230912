import * as yup from "yup";

export const CourseEditorSchema = yup.object().shape({
  overview: yup.object().shape({
    certification_description: yup
      .string()
      .min(10, "Must be at least 10 characters")
      .max(120, "Must be at most 120 characters"),

    module_1st_outcome: yup
      .string()
      .min(10, "Must be at least 10 characters")
      .max(120, "Must be at most 120 characters"),

    module_2nd_outcome: yup
      .string()
      .min(10, "Must be at least 10 characters")
      .max(120, "Must be at most 120 characters"),

    module_3rd_outcome: yup
      .string()
      .min(10, "Must be at least 10 characters")
      .max(120, "Must be at most 120 characters"),

    module_4th_outcome: yup
      .string()
      .min(10, "Must be at least 10 characters")
      .max(120, "Must be at most 120 characters"),
  }),
  module_1: yup.object().shape({
    lessons: yup
      .array()
      .min(1)
      .required("You need to add at least one lesson")
      .of(
        yup.object().shape({
          description: yup.lazy((value, s) => {
            return s.parent.video !== null
              ? yup.string().notRequired()
              : yup
                  .string()
                  .min(30, "Description must be at least 30 characters");
          }),
        })
      ),
    glossary: yup.string().required().min(30),
    introduction: yup.string().required().min(30),
    reflection: yup.string().required().min(30),
    resources: yup.string().required().min(30),
  }),
  module_2: yup.object().shape({
    lessons: yup
      .array()
      .min(1)
      .required("You need to add at least one lesson")
      .of(
        yup.object().shape({
          description: yup.lazy((value, s) => {
            return s.parent.video !== null
              ? yup.string().notRequired()
              : yup
                  .string()
                  .min(30, "Description must be at least 30 characters");
          }),
        })
      ),
    glossary: yup.string().required().min(30),
    recap: yup.string().required().min(30),
    introduction: yup.string().required().min(30),
    reflection: yup.string().required().min(30),
    resources: yup.string().required().min(30),
  }),
  module_3: yup.object().shape({
    lessons: yup
      .array()
      .min(1)
      .required("You need to add at least one lesson")
      .of(
        yup.object().shape({
          description: yup.lazy((value, s) => {
            return s.parent.video !== null
              ? yup.string().notRequired()
              : yup
                  .string()
                  .min(30, "Description must be at least 30 characters");
          }),
        })
      ),
    glossary: yup.string().required().min(30),
    introduction: yup.string().required().min(30),
    recap: yup.string().required().min(30),
    reflection: yup.string().required().min(30),
    resources: yup.string().required().min(30),
  }),
  module_4: yup.object().shape({
    lessons: yup
      .array()
      .min(1)
      .required("You need to add at least one lesson")
      .of(
        yup.object().shape({
          description: yup.lazy((value, s) => {
            return s.parent.video !== null
              ? yup.string().notRequired()
              : yup
                  .string()
                  .min(30, "Description must be at least 30 characters");
          }),
        })
      ),
    glossary: yup.string().required().min(30),
    introduction: yup.string().required().min(30),
    recap: yup.string().required().min(30),
    reflection: yup.string().required().min(30),
    resources: yup.string().required().min(30),
  }),
  assessment: yup.object().shape({
    questions: yup
      .array()
      .min(10)
      .of(
        yup.object().shape({
          question: yup.string().required().min(10),
          answers: yup
            .array()
            .min(2)
            .of(
              yup.object().shape({
                answer: yup.string().required().min(1),
                is_correct: yup.boolean(),
              })
            ),
          // .test('has-correct-answer', 'At least one answer must be correct', (answers) => {
          //   return answers.some(answer => answer.is_correct);
          // }),
        })
      ),
  }),
});

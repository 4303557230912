import { Select } from "antd";
import React from "react";
import { useCountry } from "./useCountry";

export const CountryComponent = ({ status, ...field }) => {
  const { countries } = useCountry();
  return (
    <Select
      {...field}
      status={status}
      showSearch
      className="w-full mt-1"
      filterOption={false}
      options={
        countries?.list?.map((item) => ({
          value: item.name,
          label: item.name,
        })) || []
      }
      placeholder="Select"
      listHeight={100}
      onPopupScroll={countries.handleMore}
      onSearch={(e) => countries.handleSearch(e)}
      notFoundContent={
        countries?.isLoading ? (
          <div className="py-3 px-5">loading.....</div>
        ) : (
          <div className="py-3 px-5">country not found!</div>
        )
      }
    ></Select>
  );
};

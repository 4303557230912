import axios from "axios";
import { msalInstance } from "../index";
import { scopes } from "../authConfig";


export const httpRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api/v1`,
  // baseURL: "http://localhost:3002/api/v1",
});

httpRequest.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0];
    // const { instance } = useMsal();
    if (account) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
          scopes: scopes.read,
          account: account,
        });

        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.idToken;

          if (config.headers && accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
        }
      } catch (error) {
        await msalInstance.logoutRedirect()
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default httpRequest;

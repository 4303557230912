import React from 'react'
import { RichText } from './RichText'
import { Button, Input, Radio } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
// import _ from 'lodash'

const FormAssessment = ({
  handleAddNewQuestion,
  handleRemoveQuestion,
  handleFormQuestionChange,
  handleAddNewOption,
  handleOptionValueChange,
  handleRemoveAnswerOption,
  handleOptionIsAnswerChange,
  errors,
  moduleData,
  moduleNumber,
}) => {
  return (
    <div>
      <div className='mb-12'>
        <p className='text-2xl font-extrabold text-backdrop'>
          Skill Assessment Test
        </p>

        <p className='mt-2 font-bold text-backdrop'>
          Create a multiple choice test to verify the learner’s skill
          proficiency of this skill level. The learner will have to obtain 80%
          or more to pass the level
        </p>
      </div>

      {/* Form Question */}
      <div>
        {moduleData && moduleData[moduleNumber]?.tests
          ?.filter((test) => !test.is_deleted)
          ?.map((item, indexQuestion) => (
            <div key={`${item?.id}-${indexQuestion}`} className='mb-16'>
              <div className='flex items-center justify-between'>
                <p className='mb-2 text-base font-bold text-backdrop'>
                  Question {indexQuestion + 1}
                </p>
                {/*{moduleData && moduleData[moduleNumber]?.tests?.length > 10 && (*/}
                  <p
                    className='text-blue-400 cursor-pointer'
                    onClick={() => handleRemoveQuestion(item?.id)}
                  >
                    Remove Question
                  </p>
                {/*)}{' '}*/}
              </div>
              <RichText
                className={`h-40 pb-[2.43rem] mb-1 font-default  font-normal ${errors &&
                  errors[4] &&
                  errors[4][`[${0}].tests[${indexQuestion}].question`]
                  ? 'border-2 rounded border-red-500 pb-10'
                  : ''
                  }}`}
                placeholder={'Type your question'}
                name={`Lesson ${indexQuestion}`}
                value={item?.question}
                handleChange={(event) =>
                  handleFormQuestionChange(event, item?.id, 4)
                }
              />

              <div className='mt-8'>
                <p className='mb-2 text-sm text-backdrop'>Correct</p>
                {item?.answers?.map((option, indexAnswer) => (
                  <div
                    className='flex items-center ml-5 mb-3 gap-5'
                    key={indexAnswer}
                  >
                    <div className='flex flex-col space-y-6 '>
                      <Radio
                        onChange={(event) =>
                          handleOptionIsAnswerChange(
                            event,
                            item?.id,
                            indexAnswer
                          )
                        }
                        checked={!!option?.is_correct_answer}
                        className={`w-full`}
                      ></Radio>
                    </div>
                    <div className='w-full '>
                      <Input
                        placeholder='Type answer here'
                        className={`w-full ${errors &&
                          errors[4] &&
                          errors[4][
                          `[${0}].tests[${indexQuestion}]?.answers[indexAnswer]?.answer`
                          ]
                          ? 'border-2 rounded border-red-500 pb-10'
                          : ''
                          }} `}
                        style={{ width: '100% !important' }}
                        checked={!!option?.isCorrect}
                        value={option.answer}
                        onChange={(event) =>
                          handleOptionValueChange(
                            event,
                            item?.id,
                            'answer',
                            indexAnswer
                          )
                        }
                        allowClear
                      />
                    </div>
                    {item?.answers?.length > 2 && indexAnswer > 1 ? (
                      <CloseOutlined
                        className='text-lg text-backrop cursor-pointer'
                        onClick={() =>
                          handleRemoveAnswerOption(item?.id, indexAnswer)
                        }
                      />
                    ) : (
                      <CloseOutlined className='bg-transparent text-transparent text-lg ' />
                    )}
                  </div>
                ))}
                <div className='flex items-center ml-5 mb-3 gap-11'>
                  <div className='flex flex-col space-y-6 '></div>
                  <Button
                    className='border-primary'
                    onClick={() => handleAddNewOption(item?.id)}
                  >
                    Add Answer
                  </Button>
                </div>
              </div>
            </div>
          ))}
        <Button
          className='bg-primary text-white mt-3'
          onClick={handleAddNewQuestion}
        >
          Add New Question
        </Button>
      </div>
    </div>
  )
}

export default FormAssessment

import React from "react";
import {
  Control,
  FieldErrors,
  Controller,
  UseFormHandleSubmit,
} from "react-hook-form";
import ReactQuill from "react-quill";

interface Props {
  form: {
    handleSubmit: UseFormHandleSubmit<
      {
        description: string;
        material: string;
        video: string;
      },
      undefined
    >;
    control: Control<
      {
        description: string;
        material: string;
        video: string;
      },
      any
    >;
    errors: FieldErrors<{
      description: string;
      material: string;
      video: string;
    }>;
    isDirty: boolean;
  };
}

export const Description: React.FC<Props> = ({ form }) => {
  return (
    <div className=" w-full rounded-md bg-[#FAFAFA] p-5 shadow-md mt-5">
      <h3 className=" font-semibold">Description</h3>
      <div className=" my-3">
        Describe learning outcomes or who should attend. This information will
        be visible before registration
      </div>
      <Controller
        name="description"
        control={form.control}
        render={({ field }) => (
          <ReactQuill {...field} className={`custom  bg-white`} />
        )}
      />
    </div>
  );
};

import React from "react";
import { PlusCircleTwoTone } from "@ant-design/icons";

interface AddButtonProps {
  onClick: () => void;
  className?: string;
}

export const AddButton: React.FC<AddButtonProps> = ({ onClick, className }) => {
  return (
    <PlusCircleTwoTone
      twoToneColor='#86C769'
      className={`text-[104px] hover:scale-110 transition-transform duration-300 ${className}`}
      onClick={onClick}
    />
  );
};

import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumbs } from "../components/Breadcumbs";
import { Skeleton, Typography } from "antd";
import { useNavigate } from "react-router-dom";

interface PageHeaderWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const PageHeaderWrapper: React.FC<PageHeaderWrapperProps> = ({
  children,
  className,
}) => (
  <div className={`bg-white p-4 px-6 w-full ${className ?? ""}`}>
    {children}
  </div>
);

interface PageTitleProps {
  title?: string | React.ReactNode | React.ReactElement | any;
  first_name?: string;
  last_name?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  first_name,
  last_name,
}) => {
  if (typeof title === "string") {
    return <Typography.Title>{title}</Typography.Title>;
  } else if (
    typeof title === "object" &&
    (title?.type === "span" || title?.type === "div")
  ) {
    return title;
  }
  return (
    <Typography.Title>{`Hello, ${first_name} ${last_name}!`}</Typography.Title>
  );
};

interface PageHeaderProps {
  breadcrumbItems?: any;
  title?: string | React.ReactNode;
  className?: string;
  isLoading?: boolean;
  isError?: boolean;
  subtitle?: string | React.ReactNode;
  isHeaderForAccountInfo?: boolean;
  children?: React.ReactNode | React.ReactElement;
  buttonComponent?: React.ReactNode | React.ReactElement;
  showBack?: boolean;
  needButton?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbItems,
  title,
  className,
  isLoading,
  isError,
  subtitle,
  isHeaderForAccountInfo,
  children,
  showBack,
  needButton,
  buttonComponent,
  ...rest
}) => {
  const navigate = useNavigate();

  if (isError) {
    return (
      <PageHeaderWrapper className={className ?? ""}>
        <div className="text-danger">
          Error. Please try again or contact us, if the problem persists.
        </div>
      </PageHeaderWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageHeaderWrapper className={className ?? ""}>
        <Skeleton active avatar />
      </PageHeaderWrapper>
    );
  }

  return (
    <PageHeaderWrapper
      {...rest}
      className={className ? className : (rest as any)?.className}>
      <Breadcrumbs items={breadcrumbItems} />
      {showBack && (
        <div className="mt-3">
          <p
            onClick={() => navigate(-1)}
            className="text-secondary cursor-pointer transition-transform transform hover:scale-[101%]">
            {" "}
            <ArrowLeftOutlined /> Back
          </p>
        </div>
      )}
      {title && (
        <div
          className={`mt-3 ${
            needButton ? "flex items-center justify-between" : ""
          }`}>
          <div className="">
            <PageTitle title={title} />
            {subtitle && <div className="text-gray-400">{subtitle}</div>}
          </div>
          {needButton && buttonComponent}
        </div>
      )}
      {children}
    </PageHeaderWrapper>
  );
};

import { Button, Input, Radio, Select, Skeleton, Typography } from "antd";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

const InstructorForm = ({
  isEdit = false,
  data,
  isDisabled = false,
  handle,
  isOpen,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (data && isEdit) {
      reset({
        first_name: data?.data?.first_name,
        last_name: data?.data?.last_name,
        email: data?.data?.email,
        role: data?.data?.role,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  useEffect(() => {
    if (!isEdit) {
      reset({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return isEdit && data?.loading ? (
    <Skeleton active paragraph={{ rows: 7 }} />
  ) : (
    <div className="px-3">
      <div className="flex gap-5">
        <div className="w-full">
          <label className=" capitalize" htmlFor="first_name">
            first name
          </label>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={errors?.first_name ? "error" : ""}
                id="first_name"
                className="my-2 font-default rounded-none "
                placeholder="first name"
              />
            )}
          />
        </div>
        <div className="w-full">
          <label className=" capitalize" htmlFor="last_name">
            last name
          </label>
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={errors?.last_name ? "error" : ""}
                id="last_name"
                className="my-2 font-default rounded-none "
                placeholder="last name"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full mt-3">
        <label className=" capitalize" htmlFor="last_name">
          work email
        </label>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              disabled={isEdit}
              status={errors?.email ? "error" : ""}
              id="email"
              className="my-2 font-default rounded-none "
              placeholder="name@school.com"
            />
          )}
        />
      </div>
      <div className="mt-3">
        <p>Role</p>
        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Radio.Group
              {...field}
              className="flex flex-col"
              disabled={data?.data?.role === "Admin" && isDisabled}
            >
              <Radio value={"Admin"} className="w-max  my-1">
                Administrator
              </Radio>
              <Radio value={"Instructor"} className="w-max">
                Instructor
              </Radio>
            </Radio.Group>
          )}
        />
        {errors?.role && <small className="text-danger">Role's required</small>}
      </div>
      <div className="mt-20 text-end">
        <Button
          type="primary"
          style={{ backgroundColor: "#86C769" }}
          loading={handle.loading}
          onClick={handleSubmit(handle.action)}
        >
          {isEdit ? "Save" : "Invite new user"}
        </Button>
      </div>
      {isEdit && (
        <div className="mt-5">
          <Typography.Paragraph className=" font-semibold">
            Delete Instructor and re-assign courses
          </Typography.Paragraph>
          <Typography.Paragraph>
            If you want to remove the instructor’s access to the platform,
            select whom to re-assign the courses that belong to the current
            instructor.
          </Typography.Paragraph>
          <div>
            <Typography.Paragraph>Re-assign courses to:</Typography.Paragraph>
            <Select disabled placeholder={"select"} className="w-full" />
          </div>
          <div className=" mt-5 text-end">
            <Button danger disabled>
              Delete instructor
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstructorForm;

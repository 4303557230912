import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; //import styles
import "quill/dist/quill.core.css"; //import core styles
import "quill/dist/quill.bubble.css"; //import bubble theme

export const RichText = ({
  handleChange,
  className,
  value,
  placeholder,
  disabled,
}) => {
  // const [editorHtml, setEditorHtml] = useState('')

  // const handleChange = (html) => {
  //   setEditorHtml(html)
  // }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  return (
    <ReactQuill
      className={className}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
      readOnly={disabled}
    />
  );
};

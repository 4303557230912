import React from "react";
import { CourseEditorLayout } from "./CourseEditorLayout.tsx";
import { BasicContainerWithTitle } from "./BasicContainerWithTitle.tsx";
import DotList from "../DotList";
import { BasicTitle } from "./BasicTitle.tsx";
import { BasicAutoIncreaseTextArea } from "./BasicAutoIncreaseTextArea.tsx";

import { EditorSidebar } from "./EditorSidebar.tsx";
import { Controller } from "react-hook-form";

import { useCourseOverview } from "../../hooks/useCourseOverview.ts";

const levelDescription = {
  "1": "The learner should get familiar with the main concepts and basic terminology for the skill",
  "2": "The learner should be able to apply some basic concepts on the job ",
  "3": "The learner has a solid level of knowledge and can perform job tasks independently ",
  "4": "The learner has an advanced understanding of the area and a level of expertise.",
  "5": "The learner is an expert in the area, and may be able to train and mentor others. ",
};

interface Props {
  publishCourse: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  saveChanges: () => Promise<any>;
  hitTrigger: any;
}

export const CourseEditorOverview: React.FC<Props> = ({
  publishCourse,
  saveChanges,
  hitTrigger,
}) => {
  const {
    control,
    handleSubmit,
    onSaveSubmit,
    handleBlur,
    errors,
    overviewQuery,
    skill,
    level,
    saveOnExit,
  } = useCourseOverview();

  return (
    <CourseEditorLayout
      leftRightSidebar={
        <>
          <EditorSidebar
            type='overview'
            skill_id={skill?.id ?? ""}
            publishCourse={publishCourse}
            saveChanges={saveChanges}
            saveOnExit={saveOnExit}
          />
        </>
      }
      mainContent={
        <>
          <div className='grid grid-cols-6 gap-6'>
            <BasicContainerWithTitle
              title={skill?.name ?? "Skill Information"}
              className='col-span-3'>
              <div className=''>{skill?.description}</div>
            </BasicContainerWithTitle>
            <BasicContainerWithTitle
              className='col-span-3'
              title={"Level Information"}
              titleSize='h4'>
              <div className='flex items-center gap-9 mt-1'>
                <DotList
                  value={level?.levelNum}
                  status={"PUBLISH"}
                />
                <h5 className='capitalize '>{level?.name}</h5>
              </div>
              <div className='mt-3'>
                {levelDescription?.[level?.levelNum ?? "1"]}
              </div>
            </BasicContainerWithTitle>
          </div>
          <BasicTitle
            title='Course Curriculum'
            className='mt-6'
          />
          <form onSubmit={handleSubmit(onSaveSubmit)}>
            <Controller
              name='overview.certification_description'
              control={control}
              render={({ field, fieldState, formState }) => {
                return (
                  <BasicAutoIncreaseTextArea
                    {...field}
                    isLoading={overviewQuery?.isLoading}
                    containerTitle='Skill level description'
                    placeholder='Max 120 Characters'
                    onBlur={(e) => {
                      handleBlur(e, "certification_description");
                    }}
                    showError
                    isError={!!errors?.overview?.certification_description}
                    errorMessage={
                      errors?.overview?.certification_description
                        ?.message as string
                    }
                    subtitle={
                      <span>
                        <span className=''>
                          This information will be visible in the{" "}
                          <b>Certificate of Completion</b>. Please, reference
                          the skill curriculum.
                        </span>
                      </span>
                    }
                  />
                );
              }}
            />
            <Controller
              name='overview.module_1st_outcome'
              control={control}
              render={({ field, fieldState, formState }) => (
                <BasicAutoIncreaseTextArea
                  {...field}
                  isLoading={overviewQuery?.isLoading}
                  containerTitle='Module 1'
                  placeholder='Name This Module'
                  onBlur={(e) => {
                    handleBlur(e, "module_1st_outcome");
                  }}
                  showError
                  isError={!!errors?.overview?.module_1st_outcome}
                  errorMessage={
                    errors?.overview?.module_1st_outcome?.message as string
                  }
                  subtitle={
                    <span className=''>
                      Will be displayed as the level’s <b>learning outcomes</b>
                    </span>
                  }
                />
              )}
            />

            <Controller
              name='overview.module_2nd_outcome'
              control={control}
              render={({ field, fieldState, formState }) => (
                <BasicAutoIncreaseTextArea
                  {...field}
                  isLoading={overviewQuery?.isLoading}
                  containerTitle='Module 2'
                  placeholder='Name This Module'
                  onBlur={(e) => {
                    handleBlur(e, "module_2nd_outcome");
                  }}
                  showError
                  isError={!!errors?.overview?.module_2nd_outcome}
                  errorMessage={
                    errors?.overview?.module_2nd_outcome?.message as string
                  }
                  subtitle={
                    <span className=''>
                      Will be displayed as the level’s <b>learning outcomes</b>
                    </span>
                  }
                />
              )}
            />

            <Controller
              name='overview.module_3rd_outcome'
              control={control}
              render={({ field, fieldState, formState }) => (
                <BasicAutoIncreaseTextArea
                  {...field}
                  isLoading={overviewQuery?.isLoading}
                  containerTitle='Module 3'
                  placeholder='Name This Module'
                  onBlur={(e) => {
                    handleBlur(e, "module_3rd_outcome");
                  }}
                  showError
                  isError={!!errors?.overview?.module_3rd_outcome}
                  errorMessage={
                    errors?.overview?.module_3rd_outcome?.message as string
                  }
                  subtitle={
                    <span className=''>
                      Will be displayed as the level’s <b>learning outcomes</b>
                    </span>
                  }
                />
              )}
            />

            <Controller
              name='overview.module_4th_outcome'
              control={control}
              render={({ field, fieldState, formState }) => (
                <BasicAutoIncreaseTextArea
                  {...field}
                  isLoading={overviewQuery?.isLoading}
                  containerTitle='Module 4'
                  placeholder='Name This Module'
                  onBlur={(e) => {
                    handleBlur(e, "module_4th_outcome");
                  }}
                  showError
                  isError={!!errors?.overview?.module_4th_outcome}
                  errorMessage={
                    errors?.overview?.module_4th_outcome?.message as string
                  }
                  subtitle={
                    <span className=''>
                      Will be displayed as the level’s <b>learning outcomes</b>
                    </span>
                  }
                />
              )}
            />
          </form>
        </>
      }
    />
  );
};

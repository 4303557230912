import React from "react"
import {
  SettingOutlined,
} from "@ant-design/icons"
import { Avatar, Tag, Tooltip } from "antd"
import { ProgramResponse, ProgramStatus, ProgramTrainer } from "../types/programResponse.ts"
import { VerifyIcon } from "../../../assets/icon/index.js"
import { useNavigate } from "react-router-dom"

interface ProgramCardProps {
  program: ProgramResponse
  lastCardElementRef?: any
}

const getClassNameForStatus = (status: ProgramStatus) => {
  switch (status) {
    case ProgramStatus.DRAFT:
      return "bg-secondaryYellow text-backdrop !border-gray-200"
    case ProgramStatus.IN_PROGRESS:
      return "bg-secondaryYellow text-backdrop !border-gray-200"
    case ProgramStatus.LIVE:
      return "bg-primary text-white !border-primary"
  }
}

export const ProgramCard: React.FC<ProgramCardProps> = ({ program, lastCardElementRef }) => {

  const renderTrainers = () => {
    return program?.trainers?.map((trainer: ProgramTrainer, index: number) => (
      <Tooltip key={index} title={trainer.display_name} placement="top">
        <Avatar src={trainer.avatar} className=" !border-backdrop" />
      </Tooltip>
    ))
  }

  const navigate = useNavigate()

  return (
    <div className="col-span-1 rounded-xl shadow-custom-sm border  hover:border-primary cursor-pointer transition-all bg-white" onClick={() => navigate(program?.id)} ref={lastCardElementRef}>
      <div className="py-4 px-6 space-y-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            {program?.is_issuing_certificate && <VerifyIcon />}
            <h2 className="font-semibold !leading-normal">{program?.title}</h2>
          </div>
          <SettingOutlined />
        </div>

        <div className="flex items-center gap-x-2">
          <Tag className={`mt-4 py-1 rounded-full !border-gray-200 ${getClassNameForStatus(program.status)}`}>
            {program.status}
          </Tag>
          <Tag className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white`}>
            <div className="flex gap-x-2">
              <div className="capitalize">
                <span className="text-[0.9rem]">{program?.total_course | 0}</span> courses</div>
            </div>
          </Tag>
          <Tag className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white`}>
            <div className="flex gap-x-2">
              <div className="capitalize">
                <span className="text-[0.9rem]">{program?.total_module | 0}</span> modules</div>
            </div>
          </Tag>
          <Tag className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white`}>
            <div className="flex gap-x-2">
              <div className="capitalize">
                <span className="text-[0.9rem]">{program?.total_lesson | 0}</span> lessons</div>
            </div>
          </Tag>
        </div>


        <div className="grid grid-cols-10 gap-x-1 items-center">
          <p className="col-span-1">
            Instructors
          </p>

          <div className="col-span-9">
            <Avatar.Group maxCount={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {renderTrainers()}
            </Avatar.Group>
          </div>
        </div>
        <div className="grid grid-cols-10 gap-x-1">
          <p className="col-span-1">
            Skills
          </p>

          <div className="flex items-center gap-x-2 col-span-9">
            {program?.skills?.map((skill, index) => (
              <div key={skill.id} className="!text-backdrop !bg-white">
                {skill.name}
                {index !== program.skills.length - 1 && ', '}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { isMobile } from "react-device-detect";

const Login = () => {
  // const { instance } = useMsal();
  // const login = useCallback(async () => {
  //   const result = await instance.loginRedirect(loginRequest);
  //   console.log(result);
  // }, [instance]);
  // useEffect(() => {
  //   login();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [login]);

  if (isMobile) {
    <div
      className={`bg-[#E2EBFA] m-auto min-h-screen flex justify-center ${
        isMobile ? "" : "items-center"
      } w-full `}>
      <div
        className={`flex flex-col ${
          isMobile ? "mt-40" : "items-center  justify-center"
        } my-6 w-full`}>
        <div className=' text-xl font-bold text-center mb-5'>Trainer Login</div>
        <div className='text-backdrop font-semibold text-base text-center w-full'>
          You are currently using a mobile device. Please use a desktop or a
          laptop to access the Trainer App.
        </div>
      </div>
    </div>;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}></MsalAuthenticationTemplate>
  );
};

export default Login;

import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import { CheckList } from "../components/Workshop/CheckList.tsx";
import { Detail } from "../components/Workshop/Detail.tsx";
import { useWorkshop } from "../hooks/useWorkshop.ts";
import { Result, Spin } from "antd";
import {HomeIcon} from "../../../assets/icon";

export const WorkshopDetail = () => {
  const items = [
      {
          title:
              <div className={"mt-1"}>
                  <HomeIcon />
              </div>,
          path: "/",
      },
    {
      title: "Workshop Dashboard",
    },
  ];

  const { data, form, fileMaterial, actionSave } = useWorkshop();

  return data?.isLoading ? (
    <div className="h-screen w-screen bg-slate-200 flex justify-center items-center absolute z-50">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
    </div>
  ) : data.data ? (
    <div className="">
      <header className=" bg-white pt-3 pb-1 px-3">
        <Breadcrumbs items={items} />
      </header>
      <div className=" flex gap-5 p-5">
        <CheckList form={form} actionSave={actionSave} data={data.data} />
        <Detail data={data.data} form={form} fileMaterial={fileMaterial} />
      </div>
    </div>
  ) : (
    <div className="">
      <header className=" bg-white pt-3 pb-1 px-3">
        <Breadcrumbs items={items} />
      </header>
      <Result status={404} title="Data not found!" />
    </div>
  );
};

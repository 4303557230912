import React, {useEffect, useRef} from "react"
import {
    CalendarOutlined,
    ClockCircleOutlined,
    DollarCircleOutlined,
    SettingOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons"
import {Tag} from "antd"
import {Program, WorkshopMode, WorkshopStatus,} from "../types/workshopAndPrograms"
import dayjs from "dayjs"
import {BasicUserInfo} from "../../../common/components/BasicUserInfo"

interface WorkshopCardProps {
    program: Program
    obv?: (node: (Element | null)) => void
    handleEdit: (program: Program) => void
}

const getClassNameForStatus = (status: WorkshopStatus) => {
    switch (status) {
        case WorkshopStatus.PENDING:
            return "bg-secondaryYellow text-backdrop !border-gray-200"
        case WorkshopStatus.SCHEDULED:
            return "bg-secondary text-white !border-secondary"
        case WorkshopStatus.AVAILABLE:
            return "bg-primary text-white !border-primary"
    }
}

export const WorkshopCard: React.FC<WorkshopCardProps> = ({program, obv, handleEdit}) => {
    const myDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (obv && myDivRef.current) {
            obv(myDivRef.current); // Assign the ref to 'obv'
        }

        return () => {
            if (obv) {
                obv(null); // Clean up on unmount if 'obv' is defined
            }
        };
    }, [obv]);

    return (
        <div ref={myDivRef} className="col-span-1 rounded-xl shadow-custom-sm border">
            <div className="py-4 px-6 space-y-3">
                <div className="flex items-center justify-between">
                    <h2 className="font-semibold !leading-normal">{program?.title}</h2>
                    <SettingOutlined
                        className={"cursor-pointer"}
                        onClick={(e) => {
                            handleEdit(program)
                            e.stopPropagation()
                        }}/>
                </div>

                <div className="flex items-center gap-x-2">
                    <Tag className={`mt-4 py-1 rounded-full !border-gray-200 ${getClassNameForStatus(program.status)}`}>
                        {program.status}
                    </Tag>
                    <Tag
                        className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white ${getClassNameForStatus(program.status)}`}>
                        <div className="flex gap-x-2">
                            <VideoCameraOutlined className="text-lg"/>
                            <div className="capitalize">{program?.mode}</div>
                        </div>
                    </Tag>
                    <Tag
                        className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white ${getClassNameForStatus(program.status)}`}>
                        <div className="flex gap-x-2">
                            <DollarCircleOutlined className="text-lg"/>
                            {program?.price === 0 ? "Free" : `SGD ${program?.price}`}
                        </div>
                    </Tag>

                    {program?.mode !== WorkshopMode.ON_DEMAND && (
                        <Tag
                            className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white ${getClassNameForStatus(program.status)}`}>
                            <div className="flex gap-x-2">
                                <CalendarOutlined className="text-lg"/>
                                {dayjs(program?.release_date).format("DD MMM YYYY")}
                            </div>
                        </Tag>
                    )}

                    {program?.mode !== WorkshopMode.ON_DEMAND && (
                        <Tag
                            className={`mt-4 py-1 rounded-full !border-gray-200 !text-backdrop !bg-white ${getClassNameForStatus(program.status)}`}>
                            <div className="flex gap-x-2">
                                <ClockCircleOutlined className="text-lg"/>
                                {dayjs(program?.release_date).format("hh:mm A")}
                            </div>
                        </Tag>
                    )}
                </div>


                <div className="flex items-center gap-x-4">
                    Conducted by
                    <BasicUserInfo
                        avatar={program?.trainer?.avatar}
                        name={program?.trainer?.name}
                    />
                </div>
                {/* <div
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: program?.description }}
        ></div> */}
            </div>
        </div>
    )
}

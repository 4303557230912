import {QueryClient, QueryClientProvider} from "react-query";
import {MsalProvider} from "@azure/msal-react";
import {ConfigProvider} from "antd";
import GeneralRoutes from "./routes";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App = ({instance}) => {
    return (
        <MsalProvider instance={instance}>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: "#86C769",
                            fontFamily: "Kodchasan",
                            borderRadius: 0,
                            colorText: "#36505E",
                            fontSizeHeading1: 24,
                            fontSizeHeading2: 20,
                            fontSizeHeading3: 18,
                            fontSizeHeading4: 16,
                        },
                        components: {
                            Menu: {
                                colorText: "#FFFFFF",
                            },
                            Dropdown: {
                                borderRadiusLG: 8,
                                borderRadiusSM: 4,
                                borderRadiusOuter: 4,
                                borderRadiusXS: 2,
                                borderRadius: 2
                            },
                            Popover: {
                                borderRadiusOuter: 4,
                                borderRadiusXS: 2,
                            },
                            Pagination: {
                                colorPrimary: "#36505E",
                            },
                            Tag: {
                                // colorBgContainer: "#86C769",
                                // colorBgBase: "#86C769",
                                // colorPrimaryBg: "#86C769",
                                borderRadiusSM: 3,
                                colorText: "#36505E",
                                colorBorder: "#36505E",
                            },
                            Tabs: {
                                colorBgContainer: "#D4F5C5",
                                colorBgTextActive: "#D4F5C5",
                                colorTextBase: "#36505E",
                                colorText: "#36505E",
                                colorTextHeading: "#36505E",
                                margin: 0,
                                marginXL: 0,
                                marginXS: 0,
                                marginXXS: 0,
                                marginXXL: 0,
                                controlHeightLG: 60,
                            },
                            Empty: {
                                colorTextDescription: "#36505E",
                                colorTextLabel: "#36505E",
                                colorTextBase: "#36505E",
                            },
                            Button: {
                                colorText: "#36505E",
                            },
                            Input: {
                                colorPrimary: "#009ACE",
                                colorPrimaryActive: "#009ACE",
                                colorPrimaryHover: "#009ACE",
                                controlOutlineWidth: 0,
                                colorText: "#36505E",
                                borderRadiusLG: 8,
                                borderRadiusSM: 4,
                                borderRadius: 6,
                            },
                            Select: {
                                colorPrimary: "#86C769",
                                // colorPrimaryActive: "#86C769",
                                // colorPrimaryHover: "#86C769",
                                controlOutlineWidth: 0,
                                colorText: "#36505E",
                                borderRadiusLG: 8,
                                borderRadiusSM: 4,
                                borderRadius: 2,
                            },
                            Tooltip: {
                                borderRadius: 6,
                                borderRadiusOuter: 4,
                                borderRadiusXS: 2,
                            },
                            Modal: {
                                colorText: "#36505E",
                                borderRadiusLG: 8,
                                borderRadiusSM: 4,
                                borderRadiusOuter: 4,
                                borderRadiusXS: 2,
                            },
                            Tour: {
                                borderRadius: 6,
                                borderRadiusOuter: 4,
                                borderRadiusXS: 2,
                            },
                        },
                    }}
                >
                    <GeneralRoutes/>
                </ConfigProvider>
            </QueryClientProvider>
        </MsalProvider>
    );
};

export default App;

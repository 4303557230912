import { Button, Input, Popconfirm } from "antd";
import React from "react";
import { RichText } from "./RichText";
import { VideoUpload, BitmovinPlayer } from "../../../common/components";
import { useEncodeLessonVideo, useDeleteLessonVideo } from "../hooks/useVideo";
import { useContext } from "react";
import { AutoSaveContext } from "../contexts/AutoSaveContext";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";

const FormModule = ({
  recapForm,
  moduleData,
  handleInputChange,
  handleLessonsChange,
  handleAddLesson,
  handleDeleteLesson,
  errors,
  moduleName,
  moduleNumber,
  setIsVideoToInProgress,
  moveLessonDown,
  moveLessonUp,
}) => {
  const encodeLessonVideo = useEncodeLessonVideo();
  const deleteLessonVideo = useDeleteLessonVideo();
  const autoSaveContext = useContext(AutoSaveContext);

  return (
    <div>
      {/* Overview Section */}
      <div className='mb-20'>
        <div className='mb-7'>
          <h2>Module Overview</h2>
        </div>
        <div className='mb-10'>
          <h4 className='mb-2'>Module Title</h4>
          <Input
            placeholder='Give this module a name'
            allowClear
            onChange={(e) => handleInputChange(e, "", "title", moduleData?.id)}
            className={`${
              errors &&
              errors[moduleNumber] &&
              errors[moduleNumber][`[${moduleNumber}].overview.title`]
                ? "border-2 rounded border-red-500"
                : ""
            }`}
            value={moduleData && moduleData?.overview?.title}
          />
        </div>
        <div className='mb-16'>
          <h4 className='mb-2'>Introduction</h4>
          <RichText
            className={`h-60 pb-[2.43rem] mb-1 font-default font-normal !text-[16px] !leading-5 ${
              errors &&
              errors[moduleNumber] &&
              errors[moduleNumber][`[${moduleNumber}].overview.introduction`]
                ? "border-2 rounded border-red-500 pb-10"
                : ""
            }}`}
            placeholder={
              "Write an introduction to this module and lessons outline"
            }
            handleChange={(e) =>
              handleInputChange(e, "RichText", "introduction", moduleData?.id)
            }
            value={moduleData && moduleData?.overview?.introduction}
            disabled={autoSaveContext.globalFieldsDisabled}
          />
        </div>
        {recapForm ? (
          <div className='mb-6'>
            <h4 className='mb-2'>Recap</h4>
            <RichText
              className={`h-40 pb-[2.43rem] mb-1 font-default  font-normal`}
              placeholder={
                "A short summary of knowledge learnt on the previous module"
              }
              handleChange={(e) =>
                handleInputChange(e, "RichText", "recap", moduleData?.id)
              }
              value={moduleData && moduleData?.overview?.recap}
              disabled={autoSaveContext.globalFieldsDisabled}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* Lesson Section */}
      <div className='mb-12'>
        <div className='mb-7'>
          <h2>Lessons</h2>
          <p className='mt-2 font-bold text-backdrop'>
            Split content into smaller pieces and put each as a separate lesson
          </p>
        </div>

        <div className='mb-6'>
          {moduleData &&
            moduleData?.lessons
              ?.filter((lesson) => !lesson.is_deleted)
              ?.map((fieldLesson, index) => {
                return (
                  <div
                    key={`${fieldLesson?.id}-${index}`}
                    className='mb-16'>
                    <div className='flex items-center justify-between'>
                      <div className='flex gap-4 items-center'>
                        <div className='flex'>
                          <CaretUpFilled
                            onClick={() => moveLessonUp(index, moduleNumber)}
                            disabled={index === 0}
                            className={`${
                              index === 0
                                ? "cursor-not-allowed text-gray-300"
                                : ""
                            }`}
                          />
                          <CaretDownFilled
                            disabled={index === moduleData?.lessons?.length - 1}
                            onClick={() => moveLessonDown(index, moduleNumber)}
                            className={`${
                              index === moduleData?.lessons?.length - 1
                                ? "cursor-not-allowed text-gray-300"
                                : ""
                            }`}
                          />
                        </div>
                        <h4>Lesson {index + 1}</h4>
                        <Button
                          type='link'
                          onClick={() =>
                            deleteLessonVideo.mutate({
                              lesson_id: fieldLesson?.id,
                              module_id: moduleData?.module_id,
                              video_file_name: fieldLesson?.id,
                            })
                          }
                          disabled={
                            moduleData?.lessons?.[index]?.video?.dash ===
                              undefined ||
                            moduleData?.lessons?.[index]?.video?.dash ===
                              null ||
                            moduleData?.lessons?.[index]?.video?.status ===
                              "IN_PROGRESS"
                          }
                          className=' text-sm text-blue-400 cursor-pointer'>
                          Delete Video
                        </Button>
                      </div>
                      {moduleData && moduleData?.lessons?.length > 1 && (
                        <div className='mb-2 flex items-center gap-x-6'>
                          <Popconfirm
                            title='Delete Lesson?'
                            disabled={
                              moduleData?.lessons?.[index]?.video?.status ===
                              "IN_PROGRESS"
                            }
                            icon={null}
                            description='Are you sure to delete this task?'
                            onConfirm={() =>
                              handleDeleteLesson(
                                moduleData?.id,
                                fieldLesson?.id
                              )
                            }
                            okText='Yes'
                            okButtonProps={{
                              className:
                                "text-backdrop bg-white border hover:!bg-backdrop hover:!text-white",
                            }}
                            cancelButtonProps={{
                              className:
                                "bg-primary text-white hover:!bg-primary hover:!text-white",
                            }}
                            cancelText='No'>
                            <Button
                              type='link'
                              disabled={
                                moduleData?.lessons?.[index]?.video?.status ===
                                "IN_PROGRESS"
                              }
                              className=' text-sm text-blue-400 cursor-pointer'>
                              Delete Lesson
                            </Button>
                          </Popconfirm>
                        </div>
                      )}
                    </div>
                    <div className='grid grid-cols-2 gap-x-[52px] '>
                      {moduleData?.lessons?.[index]?.video?.status ===
                        "FINISHED" &&
                      moduleData?.lessons?.[index]?.video?.dash ? (
                        <div className='!h-72'>
                          <BitmovinPlayer
                            dash={moduleData?.lessons?.[index]?.video?.dash}
                          />
                        </div>
                      ) : (
                        <VideoUpload
                          className='!h-72'
                          disabled={fieldLesson?.id?.includes("new")}
                          fileName={fieldLesson?.id}
                          inProgress={
                            moduleData?.lessons?.[index]?.video?.status ===
                            "IN_PROGRESS"
                          }
                          beforeUpload={() => {
                            autoSaveContext.disableAutoSaveFeature();
                          }}
                          afterUpload={() => {
                            setIsVideoToInProgress(
                              moduleData?.id,
                              fieldLesson?.id
                            );
                            autoSaveContext.enableAutoSaveFeature();
                          }}
                          onSuccess={() =>
                            encodeLessonVideo.mutate({
                              lesson_id: fieldLesson?.id,
                              module_id: moduleData?.module_id,
                              video_file_name: fieldLesson?.id,
                            })
                          }
                        />
                      )}
                      <div className=''>
                        <RichText
                          className={`!h-72 flex-1 font-default  font-normal ${
                            errors &&
                            errors[moduleNumber] &&
                            errors[moduleNumber][
                              `[${moduleNumber}].lessons[${index}].description`
                            ]
                              ? "border-2 rounded border-red-500 pb-10"
                              : ""
                          }}`}
                          placeholder={"Type or paste lesson content here"}
                          name={`Lesson ${index}`}
                          value={fieldLesson?.description}
                          disabled={autoSaveContext.globalFieldsDisabled}
                          handleChange={(e) =>
                            handleLessonsChange(
                              e,
                              fieldLesson?.id,
                              moduleData?.id
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          <Button
            className='border-primary -mt-24'
            onClick={async () => {
              handleAddLesson(moduleData?.id);
              // autoSaveContext.enableGlobalFields();
            }}>
            Add New Lesson
          </Button>
        </div>
      </div>

      {/* Conceptual Support Section */}
      <div>
        <div className='mb-7'>
          <h2>Conceptual Support</h2>
          <p className='mt-2 '>Help learners absorb new concepts better</p>
        </div>

        <div className='mb-16'>
          <h4>Resources</h4>
          <RichText
            className={`h-60 pb-[2.43rem] mb-1 font-default  font-normal ${
              errors &&
              errors[moduleNumber] &&
              errors[moduleNumber][
                `[${moduleNumber}].conceptual_supports.resources`
              ]
                ? "border-2 rounded border-red-500 pb-10"
                : ""
            }`}
            placeholder={
              "List supplementary materials, websites, and tools for further exploration of the topic"
            }
            handleChange={(e) =>
              handleInputChange(e, "RichText", "resources", moduleData?.id)
            }
            value={moduleData && moduleData?.conceptual_supports?.resources}
            disabled={autoSaveContext.globalFieldsDisabled}
          />
        </div>

        <div className='mb-16'>
          <h4>Glossary </h4>
          <RichText
            className={`h-60 pb-[2.43rem] mb-1 font-default  font-normal ${
              errors &&
              errors[moduleNumber] &&
              errors[moduleNumber][
                `[${moduleNumber}].conceptual_supports.glossary`
              ]
                ? "border-2 rounded border-red-500 pb-10"
                : ""
            }`}
            placeholder={"List Common terms used in this module"}
            handleChange={(e) =>
              handleInputChange(e, "RichText", "glossary", moduleData?.id)
            }
            value={moduleData && moduleData?.conceptual_supports?.glossary}
            disabled={autoSaveContext.globalFieldsDisabled}
          />
        </div>

        <div className='mb-16'>
          <h4 className='mb-2 text-sm'>Reflection</h4>
          <RichText
            className={`h-60 pb-[2.43rem] mb-1 font-default  font-normal ${
              errors &&
              errors[moduleNumber] &&
              errors[moduleNumber][
                `[${moduleNumber}].conceptual_supports.reflection`
              ]
                ? "border-2 rounded border-red-500 pb-10"
                : ""
            }`}
            placeholder={
              "Ask a few questions to guide learners reflection on the new materials"
            }
            handleChange={(e) =>
              handleInputChange(e, "RichText", "reflection", moduleData?.id)
            }
            value={moduleData && moduleData?.conceptual_supports?.reflection}
            disabled={autoSaveContext.globalFieldsDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default FormModule;

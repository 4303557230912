import React from "react";

export function Dot(props) {
  const { index, blackCount, status } = props;
  const isBlack = index < blackCount;

  return (
    <div
      className={`w-4 h-4 rounded-full mx-1 ${
        isBlack && status !== "PUBLISH"
          ? "bg-[#525150] border-backdrop border"
          : isBlack && status === "PUBLISH"
          ? "bg-[#D4F5C5] border-backdrop border"
          : "bg-white border-backdrop border-2"
      }`}
    />
  );
}
const DotList = (props) => {
  const { value, status } = props;
  const blackCount = Math.min(value, 5);

  return (
    <div className='flex'>
      {[...Array(5)].map((_, index) => (
        <Dot
          key={index}
          index={index}
          blackCount={blackCount}
          status={status}
        />
      ))}
    </div>
  );
};

export default DotList;

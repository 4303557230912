import React from "react"
import { Breadcrumbs } from "../../../common/components/Breadcumbs"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import TableDetailSkillAndCourses from "../components/TableDetailSkillAndCourses"
import { useNavigate, useParams } from "react-router-dom"
import { useFetchDetailSkillAndCourses } from "../hook/useFetchDetailSkillAndCourses"
import {HomeIcon} from "../../../assets/icon";

const DetailSkillsAndCourses = () => {

  const navigate = useNavigate()
  const { id } = useParams()

  const { detailSkillAndCourses, isDetailLoading, onSaveSubmit,
    handleSubmit, } = useFetchDetailSkillAndCourses(id)

  console.log(detailSkillAndCourses)

  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      path: "/trainer-admin/skills-courses",
      title: "Skills and Courses",
    },
    {
      path: "/trainer-admin/skills-courses/1",
      title: detailSkillAndCourses?.name,
    },
  ]
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <div className="flex items-center gap-4">
          <ArrowLeftOutlined onClick={() => navigate(-1)} className="cursor-pointer" />
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold text-backdrop">{detailSkillAndCourses?.name}</h1>
            <Tag color="green" className="bg-[#D4F5C5]">
              {detailSkillAndCourses?.groups?.name}
            </Tag>
          </div>
        </div>
        <div className="pl-3">
          <p className="mb-4">
            {detailSkillAndCourses?.description}
          </p>
        </div>
      </div>

      <div className="px-8">
        <TableDetailSkillAndCourses isDetailLoading={isDetailLoading} detailSkillAndCourses={detailSkillAndCourses} onSaveSubmit={onSaveSubmit}
          handleSubmit={handleSubmit} />
      </div>
    </div>
  )
}

export default DetailSkillsAndCourses

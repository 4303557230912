import {useQuery} from "react-query"
import instance from "../config/config"
import {useCallback, useEffect, useRef, useState} from "react"
// import { debounce } from "../utils/debounce"
import {ApiResponse, PaginatedResponse} from "../common/types/ApiResponse"
import {debounce} from "../common/helper/debounce";

// import { errorMessage } from "../common/utils/utils"

interface Params {
    key: string
    path: string
    queryParams?: any
    searchBy?: string
    enabled?: boolean
    retry?: number
}

export const useInfinite = <T>({
                                   key,
                                   path,
                                   queryParams = {
                                       page: 1,
                                       limit: 20,
                                       search: null,
                                   },
                                   searchBy = "search",
                                   enabled = true,
                                   retry = 5,
                               }: Params) => {
    const [total, setTotal] = useState(0)
    const [dataList, setDataList] = useState<T[]>([])
    const [params, setParams] = useState(queryParams)

    const {data, isLoading} = useQuery<ApiResponse<PaginatedResponse<T>>>(
        [key, params],
        () => instance.get(path, {params}),
        {
            enabled: enabled,
            retry: retry,
            onError: (error: any) => {

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                error?.response?.data?.message ||
                `An error occurred while fetching ${key}`
            },
        },
    )

    const handleChageParams = (newParams: any) => {
        setParams((prev: any) => ({...prev, ...newParams}))
    }

    const observer = useRef<IntersectionObserver | null>(null)
    const lastCardElementRef = useCallback(
        (node: Element | null) => {
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver((entries) => {
                if (
                    entries[0].isIntersecting &&
                    dataList.length &&
                    dataList?.length < total &&
                    !isLoading
                ) {
                    handleChageParams({page: params.page + 1})
                }
            })
            if (node) observer.current.observe(node)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataList.length],
    )

    const handleSearch = debounce((value: string) => {
        setParams((prev: any) => ({...prev, [searchBy]: value, page: 1}))
    }, 1000)

    useEffect(() => {
        if (!isLoading) {
            if (params.page === 1) {
                setDataList(data?.data.data.list || [])
                setTotal(data?.data.data.total_data || 0)
            } else if (
                dataList?.[dataList?.length - 1] !==
                data?.data.data.list?.[data?.data.data.list?.length - 1]
            ) {
                setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
                setTotal(data?.data.data.total_data || 0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data.data.list])

    return {
        lastCardElementRef,
        data: dataList,
        isLoading,
        handleSearch,
        handleChageParams,
        params,
    }
}

import { create } from "zustand"
import { ProgramResponse } from "../types/programResponse"

export interface QueryParams {
  page: number
  limit: number
  total: number
  search: string
}


export interface ProgramStoreState {
  programData: ProgramResponse[]
  setProgramData: (data: ProgramResponse[]) => void
  queryParams: QueryParams
  setQueryParams: (newQueryParams: QueryParams) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  error: boolean
  setError: (error: boolean) => void
  openModalNewProgram: boolean
  setOpenModalNewProgram: (open: boolean) => void
  isEditAction: boolean
  setIsEditAction: (isEdit: boolean) => void

  // detail
  programDetailData: ProgramResponse | undefined | null
  setProgramDetailData: (data: ProgramResponse | null) => void
  loadingDetailPorgram: boolean
  setLoadingDetailProgram: (loading: boolean) => void
  errorDetailProgram: boolean
  setErrorDetailProgram: (error: boolean) => void

  // builder
  openCollapseCourse: string
  setOpenCollapseCourse: (open: string) => void
  openFormAddCourse: boolean
  setOpenFormAddCourse: (open: boolean) => void
  isEditCourse: string
  setIsEditCourse: (isEdit: string) => void
}

export const initialProgramStoreState = create<ProgramStoreState>((set) => ({
  programData: [],
  setProgramData: (data: ProgramResponse[]) => set({ programData: data }),
  queryParams: {
    page: 1,
    limit: 10,
    total: 0,
    search: "",
  },
  setQueryParams: (newQueryParams: Partial<QueryParams>) => set((state) => ({
    queryParams: { ...state.queryParams, ...newQueryParams },
  })),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  error: false,
  setError: (error: boolean) => set({ error }),
  openModalNewProgram: false,
  setOpenModalNewProgram: (open: boolean) => set({ openModalNewProgram: open }),
  isEditAction: false,
  setIsEditAction: (isEdit: boolean) => set({ isEditAction: isEdit }),

  //detail
  programDetailData: null,
  setProgramDetailData: (data: ProgramResponse | null) => set({ programDetailData: data }),
  loadingDetailPorgram: false,
  setLoadingDetailProgram: (loading: boolean) => set({ loadingDetailPorgram: loading }),
  errorDetailProgram: false,
  setErrorDetailProgram: (error: boolean) => set({ errorDetailProgram: error }),

  // builder
  openCollapseCourse: "",
  setOpenCollapseCourse: (open: string) => set({ openCollapseCourse: open }),
  openFormAddCourse: false,
  setOpenFormAddCourse: (open: boolean) => set({ openFormAddCourse: open }),
  isEditCourse: '',
  setIsEditCourse: (isEdit: string) => set({ isEditCourse: isEdit }),
}))

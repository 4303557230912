import React from 'react'
import { BgHeaderProgram } from '../../../assets/images/index.js'
import { PageContent } from '../../../common/layout/PageContent.tsx'
import { HomeOutlined } from '@ant-design/icons'
import ProgramDetailContent from '../components/ProgramDetailContent.tsx'
import { PageHeaderTransparent } from '../components/PageHeaderCustom.tsx'
import * as ReactDOMServer from "react-dom/server"
import { useParams } from 'react-router-dom'
import { useProgramFetchDetail } from '../hook/useProgram.ts'
import { initialProgramStoreState } from '../store/programStore.ts'
import { Skeleton } from 'antd'
import ModalNewProgram from '../components/ModalNewProgram.tsx'

interface ProgramDetailProps { }

const ProgramDetail: React.FC<ProgramDetailProps> = () => {

  const { id } = useParams()
  useProgramFetchDetail(id!)

  const { programDetailData, loadingDetailPorgram } = initialProgramStoreState()

  const breadcrumbItems = [
    { path: "/", title: <HomeOutlined className="text-gray-400" /> },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: "Overview",
      to: "/trainer-admin/school-dashboard/overview",
    },
    {
      title: loadingDetailPorgram ? <Skeleton.Button size='small' active /> : programDetailData?.title,
      to: "/",
    },
  ]

  // magic
  const svgString = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(<BgHeaderProgram />)
  )

  return (
    <div className="">
      <div className=''>
        <PageContent
          content={<ProgramDetailContent />}
          header={
            <div className="bg-cover bg-center py-5"
              style={{ backgroundImage: `url("data:image/svg+xml,${svgString}")` }}
            >
              <PageHeaderTransparent
                breadcrumbItems={breadcrumbItems}
                title={programDetailData?.title || "loading"}
                showBack
                subtitle={programDetailData?.description || "loading"}
              />
            </div>
          }
        />
      </div>
      <ModalNewProgram />
    </div>
  )
}

export default ProgramDetail

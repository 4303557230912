import { Avatar } from "antd"
import React from "react"
import { truncateText } from "../../utils/util_funcs.ts"
import avatarImage from "../../assets/images/avatar_1.png"
import { UserOutlined } from "@ant-design/icons"

interface IBasicUserInfo {
  className?: string
  avatar: string
  name: string
  location?: string
}

const maxNameLength = 20
const maxOccupationLength = 20

export const BasicUserInfo: React.FC<IBasicUserInfo> = ({
  className,
  avatar,
  name,
  location,
}) => {
  return (
    <div className={`flex gap-x-2 items-center ${className ?? ""}`}>
      <div>
        <Avatar
          style={{ border: "1px solid #D4F5C5" }}
          className=" object-cover"
          src={avatar || avatarImage}
          icon={<UserOutlined className="text-base" />}
        />
      </div>
      <div className="flex flex-col gap-x-4">
        <div className="text-backdrop text-sm ">
          {truncateText(name, maxNameLength)}
        </div>
        {location && (
          <div className="text-[#BFBFBF] text-xs ">
            {truncateText(location, maxOccupationLength)}
          </div>
        )}
      </div>
    </div>
  )
}

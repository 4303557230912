import httpRequest from "../../config/config";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

export const useProgressCheking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    "cheking-progress",
    () => httpRequest.get("/school/onboarding/progress"),

    {
      onError: (e) => console.log(e),
      onSuccess: (res) => {
        if (!res?.data?.data) {
          if (location.pathname.includes("onboarding")) {
            navigate("/");
          }
        } else if (
          res?.data?.data &&
          location.pathname !== res?.data?.data?.redirect_path
        ) {
          navigate(res?.data?.data?.redirect_path);
        }
      },
    },
    { retry: false }
  );

  return { data: data?.data?.data, isLoading };
};

import { useFetchDetail } from "../../../common/hooks/generic_rest.ts"
import { SCHOOL_DASHBORAD_ENDPOINT } from "../apis/endpoint.ts"
import { SchoolDashboardStatResp } from "../types/schoolDashboardStat"


export const DASHBOARD_SCHOOL_STATS_QUERY_NAME = "stats-school-dashboard"
export const useSchoolDashboard = () => {

  const {
    data: schoolDashboardStat,
    isLoading: schoolDashboardStatLoading,
    isError: schoolDashboardStatError,
  } = useFetchDetail<SchoolDashboardStatResp>(`${SCHOOL_DASHBORAD_ENDPOINT.STATS}`, {
    queryName: DASHBOARD_SCHOOL_STATS_QUERY_NAME,
  })

  return {
    schoolDashboardStat,
    schoolDashboardStatLoading,
    schoolDashboardStatError,
  }
}

/*  eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useContext } from "react";
import { httpRequest } from "../../../config/config";
import { handlingError, handlingInfo } from "../../../utils/handling";
import { useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { SkillAndLevelContext } from "../contexts/SkillAndLevelContext";
import { AutoSaveContext } from "../contexts/AutoSaveContext";

const schemaAssessment = yup.array().of(
  yup.object().shape({
    tests: yup
      .array()
      .of(
        yup.object().shape({
          // id: yup.string().required(),
          question: yup.string().required(),
          answers: yup
            .array()
            .of(
              yup.object().shape({
                answer: yup.string().required(),
                is_correct_answer: yup.boolean().required(),
              })
            )
            .required("At least one answer is required")
            .min(1, "At least one answer is required"),
        })
      )
      .required("At least one test is required")
      .min(1, "At least one test is required"),
  })
);

const moduleSchema = yup.array().of(
  yup.object().shape({
    conceptual_supports: yup.object().shape({
      glossary: yup.string().required("Glossary is Required"),
      reflection: yup.string().required("Reflection is Required"),
      resources: yup.string().required("Resources is Required"),
    }),
    // learning_id: yup.string().required('Learning ID is required'),
    lessons: yup
      .array()
      .of(
        yup.object().shape({
          description: yup.string(),
          // .required("Description of Lesson is Required"),
          // id: yup.string().required('Lesson ID is Required'),
        })
      )
      .required("At least one lesson is required")
      .min(1, "At least one lesson is required"),
    // module_id: yup.string().required('Module ID is required'),
    overview: yup.object().shape({
      introduction: yup.string().required("Introduction is required"),
      // recap: yup.string().required('Recap is required'),
      title: yup.string().required("Title is required"),
    }),
  })
);

export const useLevelCourse = (skill_id, level_id, navigate) => {
  const { setSkillAndLevel } = useContext(SkillAndLevelContext);
  const autoSaveContext = useContext(AutoSaveContext);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const openMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Saving...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Saved changes!",
        duration: 2,
      });
    }, 1000);
  };

  // query
  const uniqueId = uuidv4();
  const queryClient = useQueryClient();

  // const queryKeyDetailLevel = useMemo(
  //   () => [`detail-skill-level`, skill_id, level_id],
  //   [skill_id, level_id]
  // );
  const queryKeyDetailLevel = [`detail-skill-level`, skill_id, level_id];
  const fetchListMySkill = async () => {
    try {
      const { data } = await httpRequest.get(
        `/school/my-courses/${skill_id}/level/${level_id}`,
        {
          params: {},
        }
      );
      return data;
    } catch (error) {
      return handlingError(error?.response?.data);
    }
  };
  const { data, isLoading } = useQuery(queryKeyDetailLevel, fetchListMySkill, {
    refetchOnWindowFocus: false,
  });

  const detailLevel = data?.data;
  const isDetailLoading = isLoading;
  // ---------------- end query ------------------

  // ---------------- logic UI ------------------
  const [moduleData, setModuleData] = useState(null);
  const [learningOutcame, setLearningOutcame] = useState("");
  const [previousData, setPreviousData] = useState(null);
  const [previousLearningOutcome, setPreviousLearningOutcome] = useState("");
  const [isLoadingDataFromLS, setIsLoadingDataFromLS] = useState(true);

  function moveLessonUp(index, moduleNumber) {
    if (index === 0) {
      return;
    }
    const currentModuleData = moduleData[moduleNumber];
    console.log(currentModuleData);
    const lessons = currentModuleData?.lessons
      ? [...currentModuleData?.lessons]
      : [];
    const temp = lessons[index];
    lessons[index] = lessons[index - 1];
    lessons[index - 1] = temp;

    lessons.forEach((lesson, i) => {
      lesson.order = i + 1;
    });

    setModuleData(
      moduleData.map((module, idx) => {
        if (idx === moduleNumber) {
          return {
            ...module,
            lessons,
          };
        }
        return module;
      })
    );
  }

  function moveLessonDown(index, moduleNumber) {
    if (index === moduleData?.lessons?.length - 1) {
      return;
    }

    const currentModuleData = moduleData[moduleNumber];
    console.log(currentModuleData);
    const lessons = currentModuleData?.lessons
      ? [...currentModuleData?.lessons]
      : [];
    const temp = lessons[index];
    lessons[index] = lessons[index + 1];
    lessons[index + 1] = temp;

    lessons.forEach((lesson, i) => {
      lesson.order = i + 1;
    });

    setModuleData(
      moduleData.map((module, idx) => {
        if (idx === moduleNumber) {
          return {
            ...module,
            lessons,
          };
        }
        return module;
      })
    );
  }

  useEffect(() => {
    if (skill_id && level_id) {
      setSkillAndLevel({ skill_id, level_id });
    }
  }, [skill_id, level_id]);

  // UseEffect to set data to local storage and to state when the data is fetched
  //  from the server. This will run only once
  useEffect(() => {
    console.log("Learningoutcomes", detailLevel);
    console.log("LEarnings: ", detailLevel?.learnings);
    console.log(
      "IsDetails",
      detailLevel?.learning_outcomes && detailLevel?.learnings
    );
    if (
      detailLevel?.learnings &&
      (detailLevel?.learning_outcomes || detailLevel?.learning_outcomes === "")
    ) {
      setModuleData(detailLevel?.learnings);
      setLearningOutcame(detailLevel?.learning_outcomes);
      setPreviousData(detailLevel?.learnings);
      setPreviousLearningOutcome(detailLevel?.learning_outcomes);
      localStorage.setItem(
        "moduleData",
        JSON.stringify(detailLevel?.learnings)
      );
      localStorage.setItem("learningOutcame", detailLevel?.learning_outcomes);
    }
  }, [detailLevel?.learning_outcomes, detailLevel?.learnings]);

  // UseEffect to get data from local storage and set it to moduleData and learningOutcame state every 1.5 seconds
  useEffect(() => {
    setTimeout(() => {
      const tempLocalStorageModuleData = localStorage.getItem("moduleData");
      const tempLocalStorageLearningOutcame =
        localStorage.getItem("learningOutcame");
      if (tempLocalStorageModuleData || tempLocalStorageLearningOutcame) {
        setModuleData(JSON.parse(tempLocalStorageModuleData)); // moduleData
        setLearningOutcame(tempLocalStorageLearningOutcame); // learningOutcame
        setPreviousData(JSON.parse(tempLocalStorageModuleData)); // previousData
        setPreviousLearningOutcome(tempLocalStorageLearningOutcame); // previousLearningOutcome
      }
      setIsLoadingDataFromLS(false);
    }, 1500);
  }, []);

  // UseEffect to check if there is a change in the data
  useEffect(() => {
    if (detailLevel?.learnings) {
      setPreviousData(JSON.parse(JSON.stringify(detailLevel?.learnings)));
      setPreviousLearningOutcome(
        JSON.parse(JSON.stringify(detailLevel?.learning_outcomes))
      );
    }
  }, [detailLevel?.learnings]);

  // UseEffect to check if there is a change in the data and save it to local storage and to database every 15 seconds
  useEffect(() => {
    // save to local storage
    localStorage.setItem("moduleData", JSON.stringify(moduleData));
    localStorage.setItem("learningOutcame", learningOutcame);

    const autoSaveTimer = setInterval(() => {
      setModuleData(JSON.parse(localStorage.getItem("moduleData"))); // setting the data from local storage to moduleData
      setLearningOutcame(localStorage.getItem("learningOutcame")); // setting the data from local storage to learningOutcame
      setPreviousData(JSON.parse(localStorage.getItem("moduleData"))); // setting the data from local storage to previousData
      setPreviousLearningOutcome(localStorage.getItem("learningOutcame")); // setting the data from local storage to previousLearningOutcome

      // If there is a change in the data, save it
      if (
        JSON.stringify(moduleData) !== JSON.stringify(previousData) ||
        learningOutcame !== previousLearningOutcome
      ) {
        console.log("auto save runing ");
        if (autoSaveContext.isAutoSaveEnabled) {
          SaveChanges();
        }
      }
    }, 15000);

    return () => {
      clearInterval(autoSaveTimer);
    };
  }, [moduleData, learningOutcame]);

  //*
  // PART OF MODULE HOOKS
  // */

  /**
   * Handle input change for module
   * @param {Event} event
   * @param {String} inputType
   * @param {String} name
   * @param {String} moduleId
   * @returns {void}
   * @description This function will check if the value of the input is an empty string.
   * If it is, it will set the value of the input to an empty string.
   * If it is not, it will set the value of the input to the value of the event.
   * @todo Add a check to see if the input is a RichTextEditor and the value is <p><br></p> and the name is introduction, recap, or title and the input type is RichTextEditor and the input type is RichTextEditor
   *
   */
  const handleInputChangeModule = (event, inputType, name, moduleId) => {
    setModuleData((prevModules) => {
      return prevModules.map((module) => {
        if (module?.id === moduleId) {
          // Create a deep copy of the module being edited
          const updatedModule = { ...module };

          if (inputType === "") {
            const target = event.target;
            const value = target.value;

            if (
              name === "introduction" ||
              name === "recap" ||
              name === "title"
            ) {
              updatedModule.overview[name] = value;
            } else {
              updatedModule.conceptual_supports[name] = value;
            }
          } else if (inputType === "RichText") {
            const valueType = event.trim();

            if (valueType === "<p><br></p>") {
              if (
                name === "introduction" ||
                name === "recap" ||
                name === "title"
              ) {
                updatedModule.overview[name] = "";
              } else {
                updatedModule.conceptual_supports[name] = "";
              }
            } else {
              if (
                name === "introduction" ||
                name === "recap" ||
                name === "title"
              ) {
                updatedModule.overview[name] = event;
              } else {
                updatedModule.conceptual_supports[name] = event;
              }
            }
          }

          return updatedModule;
        }

        return module;
      });
    });
  };

  /**
   * Handle input change for lessons
   * @param {Event} event
   * @param {String} lessonId
   * @param {String} moduleId
   */
  const handleLessonsChangeModule = (event, lessonId, moduleId) => {
    const valueType = event.trim();

    setModuleData((prevState) => {
      const newModules = [...prevState];
      const module = newModules.find((module) => module.id === moduleId);

      if (module) {
        const name = "description";
        const lessons = [...module.lessons];
        const lessonIndex = lessons.findIndex(
          (lesson) => lesson.id === lessonId
        );

        if (lessonIndex !== -1) {
          const lesson = {
            ...lessons[lessonIndex],
            [name]: valueType === "<p><br></p>" ? "" : event,
          };
          lessons[lessonIndex] = lesson;
        }

        module.lessons = lessons;
        return newModules;
      }

      return prevState;
    });
  };

  const setIsVideoToInProgress = (moduleId, lessonId) => {
    setModuleData((prevState) => {
      const newModules = [...prevState];
      const module = newModules.find((module) => module.id === moduleId);

      if (module) {
        const lessons = [...module.lessons];
        const lessonIndex = lessons.findIndex(
          (lesson) => lesson.id === lessonId
        );

        if (lessonIndex !== -1) {
          const lesson = {
            ...lessons[lessonIndex],
            video: {
              dash: "",
              status: "IN_PROGRESS",
            },
          };
          lessons[lessonIndex] = lesson;
        }

        module.lessons = lessons;
        return newModules;
      }

      return prevState;
    });
  };

  /**
   * Handle adding a new lesson to a module
   * @param {String} moduleId
   * @returns {void}
   * @description This function will only add a new lesson if it doesn't already exist in the database.
   * If it does, it will not add it. This is to prevent duplicate lessons from being added. The reason
   * why we need to check if the lesson already exists in the database is because the lesson id is generated
   * on the client side and not on the server side. So if the user adds a new lesson, then deletes it, and
   * then adds it again, the lesson id will be the same as the first time the user added it. So we need to
   * check if the lesson already exists in the database before adding it.
   *
   * @todo Add a check to see if the lesson already exists in the database
   */
  const handleAddLessonModule = async (moduleId) => {
    const newLesson = {
      id: `new ${uniqueId}`,
      description: "",
      is_deleted: false,
    };

    setModuleData((prevData) => {
      const newModules = [...prevData];
      const moduleIndex = newModules.findIndex(
        (module) => module?.id === moduleId
      );
      // if the module is not found, return the previous data
      if (moduleIndex !== -1) {
        const module = newModules[moduleIndex];
        const existingLessonIndex = module.lessons.findIndex(
          (lesson) => lesson.id === newLesson.id
        );

        if (existingLessonIndex === -1) {
          // Only add the new lesson if it doesn't already exist
          module.lessons.push(newLesson);
        }

        return newModules;
      }
      return prevData;
    });

    SaveChanges();
    // autoSaveContext.setGlobalFieldsDisabled(false);
  };

  /**
   * Handle deleting a lesson from a module
   * @param {String} moduleId
   * @param {String} lessonId
   * @returns {void}
   *
   * @description This function will only delete a lesson if it doesn't already exist
   * in the database. If it does, it will not delete it. This is to prevent duplicate lessons from being deleted.
   * The reason why we need to check if the lesson already exists in the database is because the lesson id is generated
   * on the client side and not on the server side. So if the user adds a new lesson, then deletes it, and then adds it
   * again, the lesson id will be the same as the first time the user added it. So we need to check if the lesson already
   * exists in the database before deleting it.
   *
   * @todo Add a check to see if the lesson already exists in the database
   * @todo Add a check to see if the lesson is already deleted
   * @todo Add a check to see if the lesson is already deleted in the database
   */
  const handleDeleteLessonModule = (moduleId, lessonId) => {
    setModuleData((prevModules) => {
      const newModules = [...prevModules];

      const moduleIndex = newModules.findIndex(
        (module) => module.id === moduleId
      );

      if (moduleIndex !== -1) {
        const module = newModules[moduleIndex];

        if (module.lessons) {
          const lessonIndex = module.lessons.findIndex(
            (lesson) => lesson.id === lessonId
          );

          if (lessonIndex !== -1) {
            if (module.lessons[lessonIndex].id.includes("new")) {
              module.lessons.splice(lessonIndex, 1);
            } else {
              module.lessons[lessonIndex] = {
                ...module.lessons[lessonIndex],
                is_deleted: true,
              };
            }
          }
        }
      }

      return newModules;
    });
    SaveChanges();
  };

  //*
  // END PART OF MODULE HOOKS
  // */

  //*
  // PART OF ASSESSMENT HOOKS
  // */

  /**
   * Handle adding a new question to the assessment
   * @returns {void}
   * @description This function will only add a new question if it doesn't already exist
   * in the database. If it does, it will not add it. This is to prevent duplicate questions from being added. The reason why we need to check if the question already exists in the database is because the question id is generated on the client side and not on the server side. So if the user adds a new question, then deletes it, and then adds it again, the question id will be the same as the first time the user added it. So we need to check if the question already exists in the database before adding it.
   */
  const handleAddNewQuestion = () => {
    const newQuestion = {
      id: `new ${uniqueId}`,
      question: "",
      answers: [
        {
          answer: "",
          is_correct_answer: false,
        },
        {
          answer: "",
          is_correct_answer: false,
        },
      ],
      is_deleted: false,
    };

    setModuleData((prevData) => {
      const module = prevData[4];
      const newQuestions = [...module.tests, newQuestion];
      const newModule = { ...module, tests: newQuestions };
      const newData = [...prevData];
      newData[4] = newModule;
      return newData;
    });
  };

  /**
   * Handle deleting a question from the assessment
   * @param {String} itemId
   * @returns {void}
   * @description This function will only delete a question if it doesn't already exist
   * in the database. If it does, it will not delete it. This is to prevent duplicate questions from being deleted. The reason why we need to check if the question already exists in the database is because the question id is generated on the client side and not on the server side. So if the user adds a new question, then deletes it, and then adds it again, the question id will be the same as the first time the user added it. So we need to check if the question already exists in the database before deleting it.
   */
  const handleRemoveQuestion = (itemId) => {
    setModuleData((prevModules) => {
      const newModules = [...prevModules];

      const module = newModules[4];
      if (module.tests) {
        const itemIndex = module.tests.findIndex((item) => item.id === itemId);

        if (itemIndex !== -1) {
          if (module.tests[itemIndex].id.includes("new")) {
            module.tests.splice(itemIndex, 1);
          } else {
            module.tests[itemIndex] = {
              ...module.tests[itemIndex],
              is_deleted: true,
            };
          }
          newModules[4] = module;
        }
      }

      return newModules;
    });
  };

  /**
   * Handle input change for question
   * @param {Event} event
   * @param {String} testId
   * @param {String} moduleNumber
   * @returns {void}
   * @description This function will check if the value of the question is an empty string. If it is, it will set the value of the question to an empty string. If it is not, it will set the value of the question to the value of the event.
   */
  const handleFormQuestionChange = (event, testId, moduleNumber) => {
    const valueType = event.trim();

    if (valueType === "<p><br></p>") {
      setModuleData((prevState) => {
        const name = "question";
        const module = { ...prevState[moduleNumber] };
        const tests = [...module.tests];
        const testIndex = tests.findIndex((test) => test.id === testId);
        if (testIndex !== -1) {
          const test = { ...tests[testIndex], [name]: "" };
          tests[testIndex] = test;
        }
        module.tests = tests;
        const newData = [...prevState];
        newData[moduleNumber] = module;
        return newData;
      });
    } else {
      setModuleData((prevState) => {
        const name = "question";
        const module = { ...prevState[moduleNumber] };
        const tests = [...module.tests];
        const testIndex = tests.findIndex((test) => test.id === testId);
        if (testIndex !== -1) {
          const test = { ...tests[testIndex], [name]: event };
          tests[testIndex] = test;
        }
        module.tests = tests;
        const newData = [...prevState];
        newData[moduleNumber] = module;
        return newData;
      });
    }
  };

  const handleAddNewOption = (questionId) => {
    // Create a new option
    const newOption = {
      answer: "",
      is_correct_answer: false,
    };

    setModuleData((prevData) => {
      const module = prevData[4];

      if (module.tests) {
        const question = module.tests.find((q) => q.id === questionId);

        if (question) {
          const newOptions = question.answers
            ? [...question.answers, newOption]
            : [newOption];

          const updatedQuestion = {
            ...question,
            answers: newOptions,
          };

          const updatedModule = {
            ...module,
            tests: module.tests.map((q) =>
              q.id === questionId ? updatedQuestion : q
            ),
          };

          const newData = [...prevData];
          newData[4] = updatedModule;
          return newData;
        }
      }

      return prevData;
    });
  };

  const handleRemoveAnswerOption = (questionId, answerOptionIndex) => {
    setModuleData((prevData) => {
      const module = prevData[4];

      if (module.tests) {
        const question = module.tests.find((q) => q.id === questionId);

        if (question && question.answers) {
          const updatedAnswers = [...question.answers];
          updatedAnswers.splice(answerOptionIndex, 1);

          const updatedQuestion = {
            ...question,
            answers: updatedAnswers,
          };

          const updatedModule = {
            ...module,
            tests: module.tests.map((q) =>
              q.id === questionId ? updatedQuestion : q
            ),
          };

          const newData = [...prevData];
          newData[4] = updatedModule;
          return newData;
        }
      }

      return prevData;
    });
  };

  const handleOptionValueChange = (event, questionId, field, optionIndex) => {
    setModuleData((prevData) => {
      const module = prevData[4];

      if (module.tests) {
        const question = module.tests.find((q) => q.id === questionId);

        if (question) {
          const updatedOption = {
            ...question.answers[optionIndex],
            [field]: event.target.value,
          };
          const updatedOptions = [...question.answers];
          updatedOptions[optionIndex] = updatedOption;

          const updatedModule = { ...module };
          const questionIndex = module.tests.indexOf(question);

          if (questionIndex !== -1) {
            updatedModule.tests[questionIndex].answers = updatedOptions;
            const newData = [...prevData];
            newData[4] = updatedModule;
            return newData;
          }
        }
      }

      return prevData;
    });
  };

  const handleOptionIsAnswerChange = (event, questionId, optionIndex) => {
    setModuleData((prevData) => {
      const module = { ...prevData[4] };

      if (module.tests) {
        const question = module.tests.find((q) => q.id === questionId);

        if (question && question.answers) {
          const updatedOptions = question.answers.map((option, idx) => ({
            ...option,
            is_correct_answer:
              idx === optionIndex ? event.target.checked : false,
          }));

          const updatedQuestion = {
            ...question,
            answers: updatedOptions,
          };

          const updatedModule = {
            ...module,
            tests: module.tests.map((q) =>
              q.id === questionId ? updatedQuestion : q
            ),
          };

          const newData = [...prevData];
          newData[4] = updatedModule;
          return newData;
        }
      }

      return prevData;
    });
  };

  //*
  // END PART OF ASSESSMENT HOOKS
  // */

  function handleBack() {
    navigate(-1);
  }

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (index) => {
    setActiveTab(index);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: async () => {
      try {
        const assessmentToValidate = moduleData.slice(-1); // get the last item in the array
        const moduleToValidate = moduleData.slice(0, -1); // get all items in the array except the last item

        let moduleErrors = {};
        let assessmentErrors = {};

        try {
          await moduleSchema.validate(moduleToValidate, { abortEarly: false });
        } catch (moduleErr) {
          moduleErrors = moduleErr.inner.reduce((acc, curr) => {
            const path = curr.path;
            const message = curr.message;
            const index = path.match(/\d+/)[0];

            return {
              ...acc,
              [index]: {
                ...acc[index],
                ...acc[index]?.[path],
                [path]: message,
              },
            };
          }, moduleErrors);
        }

        try {
          await schemaAssessment.validate(assessmentToValidate, {
            abortEarly: false,
          });
        } catch (assessmentErr) {
          assessmentErrors = assessmentErr.inner.reduce((acc, curr) => {
            const path = curr.path;
            const message = curr.message;
            const index = 4;

            return {
              ...acc,
              [index]: {
                ...acc[index],
                ...acc[index]?.[path],
                [path]: message,
              },
            };
          }, assessmentErrors);
        }

        if (
          Object.keys(moduleErrors).length > 0 ||
          Object.keys(assessmentErrors).length > 0
        ) {
          return {
            values: {},
            errors: { ...moduleErrors, ...assessmentErrors },
          };
        }

        return {
          values: {
            moduleData,
          },
          errors: {},
        };
      } catch (err) {
        console.error("Error:", err);
        return { values: {}, errors: {} };
      }
    },
  });

  // publish
  const onSubmit = async (data) => {
    try {
      const response = await httpRequest.patch(
        `/school/my-courses/${skill_id}/level/${level_id}/publish`
      );
      handlingInfo("Publish Level Successfully", () =>
        queryClient.invalidateQueries(["fetch-job-function-data"])
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      handlingError(error?.response?.data?.data);
    }
  };

  /**
   * Save changes
   * @returns {void}
   * @description This function will save the changes made to the module data and the learning outcome, assessment,
   * and module data to the database.
   */
  const SaveChanges = async () => {
    const tempData = [...moduleData];
    let convertToReq = convertDataToRequriedFormatForServer(tempData);
    const dataAssessment = tempData?.slice(-1); // get the last item in the array
    const temporaryAssessment = dataAssessment[0]?.tests; // get the tests array from the last item in the array

    const assessments = temporaryAssessment?.map((obj) => {
      const { id, ...rest } = obj;
      return { assessment_id: id, ...rest };
    });

    const finalData = {
      modules: convertToReq.modules,
      learning_outcome: learningOutcame,
      assessment: assessments,
    };

    // START
    // This is to check if there are new
    const updatedModules = finalData?.modules?.map((module) => ({
      ...module,
      module_id: module?.module_id,
      assessment_id: module?.assessment_id?.includes("new")
        ? ""
        : module?.assessment_id,
      lessons: module?.lessons?.map((lesson) => ({
        ...lesson,
        id: lesson?.id?.includes("new") ? "" : lesson?.id,
      })),
    }));

    const updatedAssessment = finalData?.assessment?.map((item) => ({
      ...item,
      assessment_id: item?.assessment_id?.includes("new")
        ? ""
        : item?.assessment_id,
    }));

    const updatedData = {
      ...data,
      modules: updatedModules,
      assessment: updatedAssessment,
      learning_outcome: learningOutcame,
    };

    saveAction(updatedData, "Module successfully updated");
    // saveActionMutation.mutate({
    //   updatedData,
    //   msg: "Module successfully updated",
    // });
  };

  /**
   * Save changes
   * @param {Object} updatedData
   * @param {String} msg
   * @returns {Object}
   * @description This function will save the changes made to the module data and the learning outcome,
   * assessment, and module data to the database.
   */
  const saveAction = async (updatedData, msg) => {
    autoSaveContext.startAutoSaving();
    try {
      const response = await httpRequest.patch(
        `/school/my-courses/${skill_id}/level/${level_id}`,
        updatedData
      );
      await queryClient.invalidateQueries([
        `detail-skill-level`,
        skill_id,
        level_id,
      ]);
      if (msg) {
        openMessage();
      }
      return response.data;
    } catch (error) {
      message.error(error);
    } finally {
      autoSaveContext.stopAutoSaving();
    }
  };

  // const saveActionMutation = useMutation(
  //   async (updatedData) => {
  //     const response = await httpRequest.patch(
  //       `/school/my-courses/${skill_id}/level/${level_id}`,
  //       updatedData
  //     );
  //     return response.data;
  //   },
  //   {
  //     // When mutate is called:
  //     onMutate: () => {
  //       autoSaveContext.startAutoSaving();
  //     },

  //     // If the mutation is successful:
  //     onSuccess: (data, variables, context) => {
  //       queryClient.invalidateQueries([
  //         `detail-skill-level`,
  //         skill_id,
  //         level_id,
  //       ]);
  //       if (context?.msg) {
  //         openMessage();
  //       }
  //     },

  //     // If the mutation fails:
  //     onError: (error) => {
  //       message.error(error);
  //     },

  //     // When the mutation is complete (whether it succeeds or fails):
  //     onSettled: () => {
  //       autoSaveContext.stopAutoSaving();
  //     },
  //   }
  // );

  const handleSubmitPublishLevel = handleSubmit(onSubmit);

  /**
   * Convert data to the format required by the server
   * @param {Array} data
   * @returns {Object}
   * @description This function will convert the data to the format required by the server.
   */
  function convertDataToRequriedFormatForServer(data) {
    const modules = [];

    data?.forEach((item) => {
      // console.log('ITEM CONVERT', item)
      const module = {
        conceptual_supports: item.conceptual_supports,
        learning_id: item.id,
        lessons: item.lessons,
        module_id: item.module_id,
        assessment_id: item.id,
        overview: item.overview,
        tests: item.tests,
      };

      modules.push(module);
    });

    return { modules };
  }

  // ---------------- logic UI ------------------

  return {
    detailLevel,
    isDetailLoading,
    activeTab,
    setActiveTab,
    handleBack,

    handleAddNewQuestion,
    handleRemoveQuestion,
    handleFormQuestionChange,
    handleAddNewOption,
    handleOptionValueChange,
    handleRemoveAnswerOption,
    handleOptionIsAnswerChange,
    handleSubmitPublishLevel,
    register,
    handleSubmit,
    errors,
    onSubmit,

    handleInputChangeModule,
    handleLessonsChangeModule,
    handleAddLessonModule,
    handleDeleteLessonModule,
    handleChangeTab,

    moduleData,
    setModuleData,
    learningOutcame,
    setLearningOutcame,
    SaveChanges,
    // AutoSaveChanges,

    previousData,
    isLoadingDataFromLS,

    contextHolder,

    setIsVideoToInProgress,

    moveLessonUp,
    moveLessonDown,
  };
};

import { useMutation, useQuery, useQueryClient } from "react-query"
import httpRequest from "../../../config/config"
import { useEffect, useState } from "react"
import { message } from "antd"
import Swal from "sweetalert2"

export const useSchool = () => {
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(
    "school",
    () => httpRequest.get("/school/settings/profile"),
    { retry: false }
  )

  const [imageUrl, setImageUrl] = useState("")
  const [imageFile, setImageFile] = useState(null)

  const [imageSignatureUrl, setImageSignatureUrl] = useState("")

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const imageUrl = URL.createObjectURL(file)
    setImageUrl(imageUrl)
    setImageFile(file)
  }

  const uploadImageSignature = useMutation(
    (formData) => {
      return httpRequest.patch(`/school/settings/signature`, formData)
        .then((response) => {
          console.log("response", response)
          message.success("successfully updated the signature")
          // setImageSignatureUrl(`${response?.data?.data?.admin_signature}?${Date.now()}`)
          return response.data
        })
        .catch((error) => {
          console.error("Error uploading image signature", error)
          throw error
        })
    },
    {
      mutationKey: 'school',
      onSuccess: () => {
        queryClient.invalidateQueries('school')
      },
    }
  )

  const handleImageSignatureChange = async (event) => {
    const file = event.target.files[0]

    // Validate the file type (allowing PNG and JPG/JPEG)
    if (file && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.warning('Only PNG and JPG files are allowed!')
      return
    }

    // Proceed if the file is a PNG
    const imageSignatureUrl = URL.createObjectURL(file)
    setImageSignatureUrl(imageSignatureUrl)

    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      // Mutate the upload
      uploadImageSignature.mutate(formData)
    }
  }

  // handle action
  const { mutate: updateSchool, isLoading: loadingUpdateSchool } = useMutation({
    mutationFn: (val) => {
      httpRequest.patch(`/school/settings/profile`, val)
    },
    mutationKey: "update-school",
  })
  // upload image
  const { mutate: uploadImage } = useMutation({
    mutationFn: (val) => {
      return httpRequest.patch(`/school/settings/logo`, val)
    },
    mutationKey: "updload-image-school",
  })

  const handleUpdateNoUpload = (val) => {
    updateSchool(val, {
      onSuccess: () => {
        message.success("success update school")
        queryClient.refetchQueries("cheking-progress")
      },
      onError: (err) => {
        message.error(err?.response?.data?.data || "something wrong")
      },
    })
  }
  const handleUpdateWithUpload = (val) => {
    const formData = new FormData()

    uploadImage(formData, {
      onSuccess: (res) => {
        updateSchool(val, {
          onSuccess: () => {
            setImageFile(null)
            message.success("success update profile")
            queryClient.refetchQueries("cheking-progress")
          },
        })
      },
      onError: (err) => {
        message.error(err?.response?.data?.data || "something wrong")
      },
    })

  }
  const handleUpdate = (val) => {
    if (imageFile) {
      handleUpdateWithUpload(val)
    } else {
      handleUpdateNoUpload(val)
    }
  }


  useEffect(() => {
    if (data?.data?.data?.logo)
      if (
        data?.data?.data?.logo !==
        "https://stgreenskills001.blob.core.windows.net/greenskills-recruiter-assets-production/avatar-placeholder.png"
      )
        setImageUrl(`${data?.data?.data?.logo}?${Date.now()}`)


    if (data?.data?.data?.admin_signature)
      if (
        data?.data?.data?.admin_signature !==
        "https://stgreenskills001.blob.core.windows.net/greenskills-recruiter-assets-production/avatar-placeholder.png"
      )
        setImageSignatureUrl(`${data?.data?.data?.admin_signature}?${Date.now()}`)



  }, [data])


  const deleteSignature = useMutation(
    (data) => {
      // message.loading({ content: 'Deleting...', key: 'deleteLoading', duration: 1 });
      return httpRequest.patch(`/school/settings/delete-signature`, data)
        .then((response) => {
          console.log("response", response)
          message.success("Successfully deleted the signature")

          setImageSignatureUrl(null)

          return response.data
        })
        .catch((error) => {
          console.error("Error deleting signature", error)
          throw error
        })
    },
    {
      mutationKey: 'school',
      onSuccess: () => {
        queryClient.invalidateQueries('school')
      },
    }
  )

  const handleRemoveClick = (id) => {
    Swal.fire({
      title: `<div className="text-left">Are you sure?</div>`,
      html: `<div className="text-gray-500 text-left">to delete signature</div>`,
      width: '400px',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      customClass: {
        popup: 'rounded-xl',
        container: '',
        denyButtonColor: '#D0DDE5',
        confirmButton:
          'bg-warning capitalize border-transparent focus:border-transparent focus:ring-0 rounded-md ',
        cancelButton:
          'bg-danger capitalize border-transparent focus:border-transparent focus:ring-0 rounded-md',
      },
      confirmButtonColor: '#FAC61D',
      cancelButtonColor: '#D0DDE5',
    }).then((result) => {
      if (result.isConfirmed) deleteSignature.mutate({ _id: id })
    })

  }

  const updateAdminSignature = useMutation(
    (data) => {
      return httpRequest.patch(`/school/settings/certificate`, data)
        .then((response) => {
          console.log("response", response)
          message.success("Successfully updated Admin to sign certificates")

          queryClient.invalidateQueries([
            "certificateData",
          ])

          return response.data
        })
        .catch((error) => {
          console.error("Error updating updated Admin to sign certificates", error)
          message.error("Failed to update the updated Admin to sign certificates")
          throw error
        })
    },
    {
      mutationKey: 'adminSignature',
      onSuccess: () => {
        queryClient.invalidateQueries('adminSignature')
      },
    }
  )

  const handleUpdateOwnerCertificate = (data) => {
    updateAdminSignature.mutate(data)
  }

  const { data: certificateData, isLoading: isLoadingCertificateData } = useQuery(
    "certificateData",
    () => httpRequest.get("/school/settings/certificate"),
    { retry: false }
  )

  return {
    school: {
      detail: data?.data?.data,
      loading: isLoading,
      update: handleUpdate,
      loadingUpdate: loadingUpdateSchool,
    },
    image: {
      imageUrl,
      handleImageChange,
    },
    imageSignature: {
      imageSignatureUrl,
      handleImageSignatureChange,
      handleRemoveClick
    },
    handleUpdateOwnerCertificate,
    certificateData,
    isLoadingCertificateData,
  }
}

import React from "react"
import { Rate } from "antd"

export default function FiveCircleRate({
  circleSize = 20,
  defaultValue = 0,
  disabled,
  count,
  color,
}) {
  const renderCharacter = (input, size, disabled) => {
    const fill =
      defaultValue <= input?.index ? "none" : color ? color : "#525150"
    const stroke = "#36505E"
    const strokeWidth = 1
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </svg>
    )
  }

  return (
    <Rate
      className='!inline-flex'
      character={(obj) => renderCharacter(obj, circleSize, true)}
      disabled={disabled}
      defaultValue={defaultValue}
      count={count === 0 ? 5 : count ? count : 5}
    />
  )
}

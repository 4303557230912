import React from "react";
import { SetupCompany } from "../components";
import { useSchool } from "../hooks/useSchool";

const SchoolForm = () => {
  const { school, image } = useSchool();

  return (
    <div>
      <div className=" bg-[#E2EBFA]  mt-10">
        <SetupCompany
          data={school.detail}
          image={image}
          update={school.update}
          loading={school.loadingUpdate}
        />
      </div>
    </div>
  );
};

export default SchoolForm;

import { Tag } from "antd";
import React from "react";

interface CourseStatusTagProps {
  status: "PUBLISH" | "ON_PROGRESS" | "DRAFT" | "ON_REVIEW" | string;
}
export const CourseStatusTag: React.FC<CourseStatusTagProps> = ({ status }) => {
  let tagBgColor = "";
  let fontColor = "";
  let borderColor = "";
  let tagText = "not available";
  switch (status) {
    case "DRAFT":
      tagBgColor = "bg-darkGrey";
      fontColor = "text-[#F5F5F5]";
      borderColor = "border-darkGrey";
      tagText = "not published";
      break;
    case "ON_PROGRESS":
      tagBgColor = "bg-secondaryYellow";
      fontColor = "text-brown";
      borderColor = "border-brown";
      tagText = "in progress";
      break;
    case "ON_REVIEW":
      tagBgColor = "bg-danger";
      fontColor = "text-white";
      borderColor = "border-danger";
      tagText = "in review";
      break;
    case "PUBLISH":
      tagBgColor = "bg-primary";
      fontColor = "text-white";
      borderColor = "border-primary";
      tagText = "live";
      break;
    default:
      tagBgColor = "bg-red-500";
      fontColor = "text-white";
      tagText = status;
      break;
  }
  return (
    <Tag className={`text-[12px] ${tagBgColor} ${fontColor} ${borderColor} `}>
      {tagText}
    </Tag>
  );
};

import React from "react";
import DotList from "../../DotList";

interface LevelNameProps {
  levelName: string;
  levelNum: number;
  status: string;
}
export const LevelName: React.FC<LevelNameProps> = ({
  levelName,
  levelNum,
  status,
}) => {
  return (
    <div className=''>
      <div className='capitalize'>{levelName}</div>
      <DotList
        value={levelNum}
        status={status}
      />
    </div>
  );
};

import React from 'react'
import { initialProgramStoreState } from '../store/programStore.ts'
import { Button, DatePicker, Input, Popover, Select } from 'antd'
import { Controller } from 'react-hook-form'
import { useProgramBuilder } from '../hook/useProgram.ts'
import { CalendarOutlined, PlusCircleFilled } from '@ant-design/icons'
import { TrasherIcon } from '../../../assets/icon/index.js'
import dayjs from 'dayjs'
import { Skill } from '../types/programResponse.ts'
import { Instructor } from './CertificateEditorContent.tsx'
import { useInstructor } from '../hook/useInstructor.js'

interface FormAddCourseProps {

}

const FormAddCourse: React.FC<FormAddCourseProps> = () => {

  const { setOpenFormAddCourse, programDetailData, isEditCourse, setIsEditCourse } = initialProgramStoreState()

  const { handleSubmit, errors, control, onSubmit, addModule, removeModule, addLesson, removeLesson, handleLessonInputChange, handleModuleInputChange, modules, watch } = useProgramBuilder()

  function transformDataToOptions(data: Skill[]) {
    const options = data?.map((item) => ({
      value: item.id,
      label: item.name,
    }))
    return options
  }

  const { list } = useInstructor()

  return (
    <div className="col-span-1 rounded-xl shadow-custom-sm border bg-white">
      <div className='p-4 space-y-3'>
        <p className='!tracking-widest text-xs text-[#966F02]'>COURSE</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-4 my-5'>
            <div className='space-y-2'>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="title"
                    status={errors?.title ? 'error' : ''}
                    className="my-1 font-default"
                    placeholder="Course Title"
                  />
                )}
              />
              {errors.title && <p className="text-red-500">Course title is required</p>}
            </div>

            <div className='flex items-center justify-between gap-x-5'>
              <div className='space-y-2 w-full'>
                <Controller
                  name="release_date"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value ? dayjs(value) : null} // Mengubah value menjadi dayjs object jika ada
                      id="release_date"
                      status={errors?.release_date ? 'error' : ''}
                      className="my-1 font-default w-full"
                      placeholder="Select Release Date"
                      format="YYYY-MM-DD" // Hanya untuk menampilkan
                      onChange={(date) => {
                        if (date) {
                          onChange(dayjs(date).toISOString()) // Mengonversi ke format ISO 8601
                        } else {
                          onChange("") // Jika tidak ada tanggal yang dipilih
                        }
                      }}
                      suffixIcon={<CalendarOutlined />} // Ikon kalender
                    />
                  )}
                />
                {/* {errors.release_date && <p className="text-red-500">Release date is required</p>} */}
              </div>
              <div className='space-y-2 w-full'>
                <Controller
                  name="trainer_id"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="trainer_id"
                      status={errors?.trainer_id ? 'error' : ''}
                      className="my-1 font-default rounded-lg w-full"
                      placeholder="Select Instructor"
                      value={watch('trainer_id') || 'Select Instructor'}
                      options={list?.data?.map((user: Instructor) => ({
                        value: user.id,
                        label: user.display_name,
                      })
                      )}
                      allowClear
                    />
                  )}
                />
                {/* {errors.trainer_id && <p className="text-red-500">Instructor is required</p>} */}
              </div>
            </div>

            <p className="font-bold flex items-center gap-x-2 cursor-pointer hover:text-primary transform"
              onClick={() => addModule()}
            >
              <PlusCircleFilled />
              Add Module
            </p>
          </div>

          <div className='space-y-4'>
            {modules.map((module, indexModule) => (
              <div key={indexModule} className="col-span-1 rounded-xl shadow-custom-sm border bg-white">
                <div className='p-3 w-full space-y-3'>
                  <div className='flex items-center gap-x-4 w-full pr-4'>
                    <div className='space-y-2 w-full'>
                      <Controller
                        name={`modules.${indexModule}.introduction`}
                        control={control}
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            id={`modules.${indexModule}.introduction`}
                            status={errors?.modules?.[indexModule]?.introduction ? 'error' : ''}
                            className="my-1 font-default"
                            placeholder="Module Introduction"
                            onChange={(e) => handleModuleInputChange(indexModule, e.target.value, 'introduction')}
                          />
                        )}
                      />
                      {/* {errors?.modules?.[indexModule]?.introduction && <p className="text-red-500">Module introduction is required</p>} */}
                    </div>

                    <Popover placement="top" content={<p>Remove Module {module?.introduction || indexModule + 1}</p>}>
                      <div className='cursor-pointer' onClick={() => removeModule(indexModule)}>
                        <TrasherIcon />
                      </div>
                    </Popover>
                  </div>
                  <p className="font-bold flex items-center gap-x-2 cursor-pointer hover:text-primary transform"
                    onClick={() => addLesson(indexModule)}
                  >
                    <PlusCircleFilled />
                    Add Lesson
                  </p>

                  <div className="overflow-x-auto mt-4">
                    <table className="w-full">
                      <tbody className="bg-white">
                        {module?.lessons?.map((lesson, indexLesson) => (
                          <tr key={indexLesson} className="w-full">
                            <td className="px-4 py-1 w-10">{indexLesson + 1}.</td>
                            <td className="px-4 py-1 w-full flex items-center gap-x-4">
                              <div className="space-y-2 w-full">
                                <Controller
                                  name={`modules.${indexModule}.lessons.${indexLesson}.title`}
                                  control={control}
                                  // rules={{ required: true }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      id={`modules.${indexModule}.lessons.${indexLesson}.title`}
                                      status={errors?.modules?.[indexModule]?.lessons?.[indexLesson]?.title ? 'error' : ''}
                                      className="my-1 font-default w-full"
                                      placeholder="Lesson Title"
                                      onChange={(e) => handleLessonInputChange(indexModule, indexLesson, e.target.value, 'title')}
                                    />
                                  )}
                                />
                                {/* {errors?.modules?.[indexModule]?.lessons?.[indexLesson]?.title && (
                                  <p className="text-red-500">Lesson title is required</p>
                                )} */}
                              </div>

                              <div className="space-y-2 w-1/2">
                                <Controller
                                  name={`modules.${indexModule}.lessons.${indexLesson}.program_skill_id`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      id={`modules.${indexModule}.lessons.${indexLesson}.program_skill_id`}
                                      status={errors?.modules?.[indexModule]?.lessons?.[indexLesson]?.skill ? 'error' : ''}
                                      className="my-1 font-default w-full"
                                      placeholder="Select skill (optional)"
                                      onChange={(value) => handleLessonInputChange(indexModule, indexLesson, value, 'program_skill_id')}
                                      options={transformDataToOptions(programDetailData?.skills || [])}
                                      allowClear
                                    />
                                  )}
                                />
                                {errors?.modules?.[indexModule]?.lessons?.[indexLesson]?.skill && (
                                  <p className="text-red-500">Skill is required</p>
                                )}
                              </div>

                              <Popover placement="top" content={<p>Remove Lesson {lesson?.title || indexLesson + 1}</p>}>
                                <div className='cursor-pointer' onClick={() => removeLesson(indexModule, indexLesson)}>
                                  <TrasherIcon />
                                </div>
                              </Popover>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <Button className="rounded-lg px-7 my-5 mx-auto w-full" type="primary" htmlType="submit">Submit</Button> */}
          <div className='flex items-center justify-end gap-x-3 mt-4'>
            <Button
              type="default"
              className="rounded-lg bg-red-400 text-white"
              onClick={() => {
                setOpenFormAddCourse(false)
                isEditCourse && setIsEditCourse('')
              }}
            >Cancel</Button>

            <Button
              type="primary"
              className="rounded-lg bg-primary"
              onClick={handleSubmit(onSubmit)}
            >Save</Button>
          </div>

        </form>
      </div>
    </div>
  )
}



export default FormAddCourse

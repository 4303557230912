import React, { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BookIcon, LampIcon, OverViewIcon, StarIcon } from '../../../assets/icon'

interface MenuItem {
  name: string
  icon: FC<{ className: string }> | any
  route: string
}
interface ROUTES {
  ROUTES: any
}

const DashboardSubMenu: FC<ROUTES> = ({ROUTES}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const subMenuDashboard: MenuItem[] = [
    { name: "Overview", icon: OverViewIcon, route: ROUTES.OVERVIEW },
    { name: "Green Skills", icon: LampIcon, route: ROUTES.GREEN_SKILLS },
    { name: "Workshops", icon: StarIcon, route: ROUTES.WORKSHOPS },
    { name: "Programs", icon: BookIcon, route: ROUTES.PROGRAMS },
  ];

  return (
    <div className="flex justify-between items-center bg-secondaryBlue rounded-xl py-2 px-4">
      {subMenuDashboard.map((item, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`px-4 py-1 rounded-xl flex items-center gap-x-2 cursor-pointer ${location.pathname === item.route ? 'bg-white text-gray-800' : 'text-gray-600'
              }`}
            onClick={() => navigate(item.route)}
          >
            <item.icon className="w-6 h-6" />
            {item.name}
          </div>
        </div>
      ))}
    </div>
  )
}

export default DashboardSubMenu

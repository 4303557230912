import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {getInstructorList} from '../apis/instructors'
import {Button, Empty, Input, Select, Space} from 'antd'
import {WorkshopCard} from './WorkshopCard'
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons'
import {NewWorkshopModal} from './NewWorkshopModal'
import {NewProgramModal} from './NewProgramModal'
import {useInfinite} from "../../../hook/useInfinite";
import {Program} from "../types/workshopAndPrograms";

interface WorkshopContentProps {

}

const WorkshopContent: React.FC<WorkshopContentProps> = () => {

    const instructorsQuery = useQuery("instructors", () => getInstructorList())


    const [isWorkshopModalVisible, setIsWorkshopModalVisible] =
        React.useState(false)
    const [isProgramModalVisible, setIsProgramModalVisible] =
        React.useState(false)
    //
    // const [search, setSearch] = React.useState<string | undefined>(undefined)
    // const [status, setStatus] = React.useState(undefined)
    // const [trainer, setTrainer] = React.useState<string | undefined>(undefined)
    // const [mode, setMode] = React.useState(undefined)

    // const workshopListQuery = useQuery("workshops", () =>
    //   workshopAndProgramsList({
    //     title: search,
    //     status: status,
    //     trainer_id: trainer,
    //     mode: mode,
    //   })
    // )

    // React.useEffect(() => {
    //   workshopListQuery.refetch()
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [status, trainer, mode, search])
    //
    const handleMenuClick = () => {
        setIsWorkshopModalVisible(true)
    }

    const {
        lastCardElementRef,
        data: dataList,
        isLoading,
        handleSearch,
        handleChageParams,
    } = useInfinite<Program>({
        key: "workshops",
        path: "/school/programs-and-workshops",
        searchBy: "title",
        queryParams: {
            title: undefined,
            status: undefined,
            trainer_id: undefined,
            mode: undefined,
            page: 1,
            limit: 10
        }
    })


    // edit
    const [dataEdit, setDataEdit] = useState<Program | undefined>(undefined)
    const handleEditWorkShop = (program: Program) => {
        setDataEdit(program)
        setIsWorkshopModalVisible(true)
    }

    return (
        <div className='w-full'>

            <div className='flex items-center justify-end mb-5 mt-10'>
                <Button className="bg-primary text-white" onClick={() => handleMenuClick()}>
                    <Space>
                        New Workshop
                    </Space>
                </Button>
            </div>

            <div className='flex items-center gap-x-56 justify-end mb-12 px-4 py-6 shadow-custom-sm border rounded-lg'>
                <div className="bg-white flex gap-4 flex-wrap items-center w-full">
                    <div
                        className="flex flex-grow min-w-[80px]"
                        style={{borderBottom: "1px solid #D0DDE5"}}
                    >
                        <SearchOutlined/>
                        <Input
                            bordered={false}
                            onChange={(e) => {
                                handleSearch(e.target.value)
                            }}
                            placeholder="search"
                        />
                    </div>
                    <div className="bg-grey-select min-w-[122px]">
                        <Select
                            placeholder="Status"
                            options={[
                                {
                                    label: "All",
                                    value: "all",
                                },
                                {label: "Pending", value: "pending"},
                                {label: "Scheduled", value: "scheduled"},
                                {label: "Available", value: "available"},
                            ]}
                            onSelect={(value) => {
                                if (value === "all") {
                                    // setStatus(undefined)
                                    handleChageParams({status: undefined, page: 1})
                                } else {
                                    // setStatus(value)
                                    handleChageParams({status: value, page: 1})
                                }
                            }}
                            className="w-full"
                        />
                    </div>
                    <div className="bg-grey-select min-w-[122px]">
                        <Select
                            placeholder="Trainer"
                            notFoundContent={"No status found"}
                            showSearch
                            options={instructorsQuery.data?.data?.data?.map((instructor) => ({
                                label: instructor.display_name,
                                value: instructor.id,
                            }))}
                            loading={instructorsQuery.isLoading}
                            onSelect={(value) => handleChageParams({trainer_id: value, page: 1})}
                            filterOption={false}
                            className="w-full"
                        />
                    </div>
                    <div className="bg-grey-select min-w-[122px]">
                        <Select
                            placeholder="Mode"
                            filterOption={false}
                            options={[
                                {
                                    label: "All",
                                    value: "all",
                                },
                                {label: "On Demand", value: "on-demand"},
                                {label: "Live Stream", value: "live-stream"},
                                {label: "Pre Recorded", value: "pre-recorded"},
                            ]}
                            onSelect={(value) => {
                                if (value === "all") {
                                    handleChageParams({mode: undefined, page: 1})
                                } else {
                                    handleChageParams({mode: value, page: 1})
                                }
                            }}
                            className="w-full"
                        />
                    </div>
                </div>

            </div>
            <div className=" bg-white">
                {isLoading && dataList.length === 0 ? (
                    <div className="flex justify-center items-center h-40">
                        <LoadingOutlined className={"text-4xl"}/>
                    </div>
                ) : dataList.length < 1 ? (
                    <div className="h-40">
                        <Empty/>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 gap-4">
                        {dataList.map((workshop, i) => (
                            <WorkshopCard
                                key={workshop.id} program={workshop}
                                obv={i === dataList.length - 1 ? lastCardElementRef : undefined}
                                handleEdit={handleEditWorkShop}
                            />
                        ))}
                    </div>
                )}
            </div>

            <NewWorkshopModal
                open={isWorkshopModalVisible}
                onCancel={() => {
                    setIsWorkshopModalVisible(false)
                    setDataEdit(undefined)
                }}
                closeModal={() => {
                    setIsWorkshopModalVisible(false)
                    setDataEdit(undefined)
                }}
                data={dataEdit}
            />
            <NewProgramModal
                open={isProgramModalVisible}
                onCancel={() => setIsProgramModalVisible(false)}
            />
        </div>
    )
}

export default WorkshopContent

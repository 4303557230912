import React, { useEffect } from "react";
import { Tabs } from "antd";

import { CourseEditorOverview } from "../components/CourseEditor/CourseEditorOverview.tsx";
import { CourseEditorModule } from "../components/CourseEditor/CourseEditorModule.tsx";
import { CourseEditorAssessment } from "../components/CourseEditor/CourseEditorAssessment.tsx";

import spinner_loading_one from "../../../assets/json/spinner_loading_one.json";
import Lottie from "lottie-react";
import { TabNames } from "../types/common.ts";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useCourseEditor } from "../hooks/useCourseEditor.ts";
import { FormProvider } from "react-hook-form";

const CourseEditor = () => {
  const {
    moduleListQuery,
    methods,
    publishCourse,
    saveChanges,
    assessmentListQuery,
    overviewQuery,
  } = useCourseEditor();

  useEffect(() => {
    if (
      !moduleListQuery.isLoading &&
      !assessmentListQuery.isLoading &&
      !overviewQuery.isLoading
    ) {
      methods.trigger();
    }
  }, [
    assessmentListQuery.isLoading,
    assessmentListQuery?.data?.data?.data,
    methods,
    moduleListQuery.isLoading,
    moduleListQuery?.data?.data?.data,
    overviewQuery?.data?.data?.data,
    overviewQuery.isLoading,
  ]);

  const moduleList = React.useMemo(() => {
    return moduleListQuery?.data?.data?.data
      ? moduleListQuery.data.data.data.map((module, index) => {
          const moduleName = `module_${index + 1}` as TabNames;
          return {
            label: (
              <div className='flex items-center'>
                {methods.formState ? (
                  methods?.formState?.errors?.[moduleName] &&
                  Object.keys(methods?.formState?.errors?.[moduleName] ?? {})
                    ?.length > 0 ? (
                    <CloseCircleFilled className='text-danger' />
                  ) : (
                    <CheckCircleFilled className='text-primary' />
                  )
                ) : null}
                <span className='text-sm'>{`Module ${index + 1}`}</span>
              </div>
            ),
            key: moduleName,
            children: (
              <CourseEditorModule
                key={moduleName}
                publishCourse={publishCourse}
                saveChanges={saveChanges}
                module={module}
                moduleNum={index + 1}
                moduleName={moduleName}
                hitTrigger={methods.trigger}
              />
            ),
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    moduleListQuery?.data?.data?.data,
    methods.formState,
    publishCourse,
    saveChanges,
  ]);

  if (moduleListQuery.isLoading) {
    return (
      <div className='flex justify-center flex-col items-center h-screen'>
        <Lottie
          className='w-40'
          animationData={spinner_loading_one}
        />
        Loading...
      </div>
    );
  }
  return (
    <div className='w-full overflow-y-auto scrollbar-hide bg-[#F5F5F5] square-navigation relative'>
      <FormProvider {...methods}>
        <Tabs
          type='card'
          className='!sticky top-10 z-10'
          defaultActiveKey='1'
          onChange={(key) => {}}
          animated={false}
          // activeKey={activeKey}
          tabBarStyle={{ width: "100%", backgroundColor: "white" }}
          items={[
            {
              label: (
                <div className='flex items-center'>
                  {methods.formState ? (
                    methods?.formState?.errors?.["overview"] &&
                    Object.keys(
                      methods?.formState?.errors?.["overview"] ?? {}
                    ) ? (
                      <CloseCircleFilled className='text-danger' />
                    ) : (
                      <CheckCircleFilled className='text-primary' />
                    )
                  ) : null}
                  <span className='text-sm'>Overview</span>
                </div>
              ),
              key: "overview",
              children: (
                <CourseEditorOverview
                  publishCourse={publishCourse}
                  saveChanges={saveChanges}
                  hitTrigger={methods.trigger}
                />
              ),
            },
            ...moduleList,
            {
              label: (
                <div className='flex items-center'>
                  {methods.formState ? (
                    methods?.formState?.errors?.["assessment"] &&
                    Object.keys(
                      methods?.formState?.errors?.["assessment"] ?? {}
                    ) ? (
                      <CloseCircleFilled className='text-danger' />
                    ) : (
                      <CheckCircleFilled className='text-primary' />
                    )
                  ) : null}
                  <span className='text-sm'>Assessment</span>
                </div>
              ),
              key: "assessment",
              children: (
                <CourseEditorAssessment
                  publishCourse={publishCourse}
                  saveChanges={saveChanges}
                />
              ),
            },
          ]}
        />
      </FormProvider>
    </div>
  );
};

export default CourseEditor;

import { Button, Input, Select } from "antd";
import React from "react";
import { useCoursesDropdown } from "../../../hook/useCoursesDropdown";

export const Filter = ({ setParamStudent }) => {
  const { dataList, handleScroll, isLoading, handleClear, handleSearch } =
    useCoursesDropdown();
  return (
    <div className=" grid grid-cols-5 gap-5">
      <div className=" col-span-4 flex justify-between gap-5">
        <Input.Search
          disabled
          placeholder="Input search text"
          allowClear
          enterButton={
            <Button
              type="primary"
              style={{ backgroundColor: "#86C769" }}
              disabled
            >
              Search
            </Button>
          }
        />
      </div>

      <Select
        filterOption={false}
        options={dataList?.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        showSearch
        allowClear
        onClear={(e) => setParamStudent((prev) => ({ ...prev, course_id: "" }))}
        onSelect={(e) => setParamStudent((prev) => ({ ...prev, course_id: e }))}
        onSearch={handleSearch}
        isLoading={isLoading}
        onDropdownVisibleChange={handleClear}
        onPopupScroll={handleScroll}
        placeholder="select skill"
        className="w-full col-span-1"
      />
    </div>
  );
};

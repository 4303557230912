import React from "react";
import { useCourseEditorStore } from "../stores/useCourseEditorStore.ts";
import { CourseData, FormValues, Lesson } from "../types/common.ts";
import {
  AddCourseLevelModuleData,
  useAddCourseLevelModuleLesson,
  useDeleteCourseLevelModuleLesson,
  useFetchCourseLevelModuleDetail,
  useUpdateCourseLevelModule,
  useUpdateCourseLevelModuleLesson,
} from "./courseEditor.ts";
import { useFieldArray, useFormContext } from "react-hook-form";
import { message } from "antd";
import {
  ModuleField,
  shouldValueBeUpdated,
  whichCategory,
} from "../utils/util_func.ts";
import { useQueryClient } from "react-query";

export const useCourseModule = (moduleName: string, module: CourseData) => {
  const { skill, level } = useCourseEditorStore((state) => state);
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();
  const moduleDetailQuery = useFetchCourseLevelModuleDetail(
    skill?.id,
    level?.id,
    module?.id
  );

  React.useEffect(() => {
    const handleTrigger = async () => {
      await trigger();
    };
    handleTrigger();
  }, [moduleDetailQuery?.data?.data?.data?.lessons?.length, trigger]);
  const updateLessonMutation = useUpdateCourseLevelModuleLesson();
  const queryClient = useQueryClient();

  const [fieldSaving, setFieldSaving] = React.useState({
    glossary: { isSaving: false, isSaved: true },
    introduction: { isSaving: false, isSaved: true },
    recap: { isSaving: false, isSaved: true },
    reflection: { isSaving: false, isSaved: true },
    resources: { isSaving: false, isSaved: true },
    title: { isSaving: false, isSaved: true },
  });

  const { fields: lessons, replace } = useFieldArray({
    control,
    // @ts-ignore
    name: `${moduleName}.lessons`,
    keyName: "fid",
  });

  const updateModuleDetail = useUpdateCourseLevelModule();
  const addLesson = useAddCourseLevelModuleLesson();
  const deleteLesson = useDeleteCourseLevelModuleLesson();

  /** Order starts from 1, therefore is always 1 greater than the index of lesson in array */
  const moveLessonUp = (lesson: Lesson) => {
    if (lesson.order === 1) {
      return;
    }
    message.open({
      content: "Moving lesson up...",
      key: "up",
      type: "loading",
    });

    const findElementBefore = lessons?.find(
      (item) => item.order === lesson.order - 1
    );
    const copyOfElementBefore = {
      ...findElementBefore,
      pdf: findElementBefore?.pdf?.map((items) => ({ ...items })),
      video: findElementBefore?.video ? { ...findElementBefore?.video } : null,
      order: lesson.order,
    };
    const copyOfElement = {
      ...lesson,
      pdf: lesson?.pdf?.map((items) => ({ ...items })),
      video: lesson?.video ? { ...lesson?.video } : null,
      order: lesson.order - 1,
    };

    updateLessonMutation.mutateAsync(
      {
        course_id: skill?.id ?? "",
        level_id: level?.id ?? "",
        lesson_id: copyOfElement?.id ?? "",
        module_id: module?.id ?? "",
        formData: copyOfElement,
      },
      {
        onError(error, variables, context) {},
        onSuccess: async (data) => {
          updateLessonMutation.mutate(
            {
              course_id: skill?.id ?? "",
              level_id: level?.id ?? "",
              lesson_id: copyOfElementBefore.id,
              module_id: module?.id ?? "",
              formData: copyOfElementBefore,
            },
            {
              onError(error, variables, context) {},
              onSuccess: async (data) => {
                const a: any = await queryClient.fetchQuery([
                  "course-level-module-detail",
                  skill?.id ?? "",
                  level?.id ?? "",
                  module?.id ?? "",
                ]);
                replace([...a?.data?.data.lessons]);
                message.success({
                  content: "Lesson Moved up!",
                  key: "up",
                  duration: 0.5,
                });
              },
            }
          );
        },
      }
    );
  };

  const moveLessonDown = (lesson: Lesson) => {
    if (lesson.order === lessons?.length) {
      return;
    }

    message.loading({
      content: "Moving lesson down...",
      key: "down",
      type: "loading",
    });
    const findElementAfter = lessons?.find(
      (item) => item.order === lesson.order + 1
    );
    const copyOfElementAfter = {
      ...findElementAfter,
      pdf: findElementAfter?.pdf?.map((items) => ({ ...items })),
      video: findElementAfter?.video ? { ...findElementAfter?.video } : null,
      order: lesson.order,
    };
    const copyOfElement = {
      ...lesson,
      pdf: lesson?.pdf?.map((items) => ({ ...items })),
      video: lesson?.video ? { ...lesson?.video } : null,
      order: lesson.order + 1,
    };

    updateLessonMutation.mutateAsync(
      {
        course_id: skill?.id ?? "",
        level_id: level?.id ?? "",
        lesson_id: copyOfElement?.id ?? "",
        module_id: module?.id ?? "",
        formData: copyOfElement,
      },
      {
        onError(error, variables, context) {},
        onSuccess: async (data) => {
          updateLessonMutation.mutate(
            {
              course_id: skill?.id ?? "",
              level_id: level?.id ?? "",
              lesson_id: copyOfElementAfter.id,
              module_id: module?.id ?? "",
              formData: copyOfElementAfter,
            },
            {
              onError(error, variables, context) {},
              onSuccess: async (data) => {
                const a: any = await queryClient.fetchQuery([
                  "course-level-module-detail",
                  skill?.id ?? "",
                  level?.id ?? "",
                  module?.id ?? "",
                ]);
                replace([...a?.data?.data.lessons]);
                message.success({
                  content: "Lesson Moved down!",
                  key: "down",
                  duration: 0.5,
                });
              },
            }
          );
        },
      }
    );
  };

  const addLessonHandler = () => {
    // server update
    if (skill?.id && level?.id && module?.id) {
      addLesson.mutateAsync(
        {
          course_id: skill?.id,
          level_id: level?.id,
          module_id: module?.id,
          formData: {
            description: "",
            order: lessons ? lessons?.length + 1 : 1,
            file: null,
          },
        },
        {
          onSuccess: async (data, variables, context) => {
            const a: any = await queryClient.fetchQuery([
              "course-level-module-detail",
              skill?.id ?? "",
              level?.id ?? "",
              module?.id ?? "",
            ]);
            replace([...a?.data?.data.lessons]);
            message.success("Lesson added!");
          },
        }
      );
    }
  };

  const removeLessonHandler = (lesson_id: string) => {
    // optimistic update
    if (lessons) {
      replace(lessons?.filter((item: any) => item.id !== lesson_id));
    }

    // update to server
    if (skill?.id && level?.id && module?.id) {
      deleteLesson.mutateAsync(
        {
          course_id: skill?.id,
          level_id: level?.id,
          module_id: module?.id,
          lesson_id,
        },
        {
          onSuccess: async () => {
            message.success("Lesson removed!");
            const a: any = await queryClient.fetchQuery([
              "course-level-module-detail",
              skill?.id ?? "",
              level?.id ?? "",
              module?.id ?? "",
            ]);
            replace([...a?.data?.data.lessons]);
          },
          onError: (error, variables, context) => {
            // if this results in an error remove the optimistic update
            if (moduleDetailQuery.data?.data?.data?.lessons) {
              replace([...moduleDetailQuery.data?.data?.data?.lessons]);
            }
          },
        }
      );
    }
  };

  /**
   * @description Optimistic update for video status. This updates the status of the video to IN_PROGRESS after it is successfully uploaded to Azure Storage.
   */
  const videoStatusUpdateAfterUpload = (
    lesson_id: string,
    isError: boolean = false,
    deleted?: boolean
  ) => {
    if (lessons) {
      replace(
        lessons?.map((lesson: any) => {
          if (deleted) {
            return {
              ...lesson,
              video: null,
            };
          }
          if (lesson.id === lesson_id) {
            return {
              ...lesson,
              video: {
                ...lesson.video,
                status: "IN_PROGRESS",
                dash: null,
              },
            };
          }

          return lesson;
        })
      );
    }
  };

  const deletePdfFileFromLesson = (lesson_id: string, pdf_url: string) => {
    if (lessons) {
      replace(
        lessons?.map((lesson: any) => {
          if (lesson.id === lesson_id) {
            return {
              ...lesson,
              pdf: lesson.pdf?.filter((pdf: any) => pdf.url !== pdf_url),
            };
          }
          return lesson;
        })
      );
    }
  };

  const addPdfFileToLesson = (
    lesson_id: string,
    pdf_url: string,
    name: string
  ) => {
    if (lessons) {
      replace(
        lessons?.map((lesson: any) => {
          if (lesson.id === lesson_id) {
            const pdfs = lesson.pdf ? [...lesson.pdf] : [];
            return {
              ...lesson,
              pdf: [...pdfs, { url: pdf_url, name: name }],
            };
          }
          return lesson;
        })
      );
    }
  };
  const handleBlur = (value: string, name: ModuleField) => {
    const formData = getValues();
    const cateogry = whichCategory(name);
    const oldValue = moduleDetailQuery.data?.data?.data?.[cateogry]?.[name];
    const allowUpdate = shouldValueBeUpdated(value, oldValue);

    const isFormDataChanged = Object.keys(formData).some((key) => {
      const formValue = formData[key];
      const cateogry = whichCategory(name);
      const originalValue =
        moduleDetailQuery.data?.data?.data?.[cateogry]?.[key];
      return shouldValueBeUpdated(formValue, originalValue);
    });

    if (
      skill?.id &&
      level?.id &&
      module?.id &&
      (allowUpdate || isFormDataChanged)
    ) {
      setFieldSaving((prev) => ({
        ...prev,
        [name]: {
          isSaving: true,
          isSaved: false,
        },
      }));
      updateModuleDetail.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          module_id: module?.id,
          formData: {
            ...(formData as unknown as AddCourseLevelModuleData),
            [name]: value,
          },
        },
        {
          onSuccess: (data, variables, context) => {
            message.success("Module updated!");
            setFieldSaving((prev) => ({
              ...prev,
              [name]: {
                isSaving: false,
                isSaved: true,
              },
            }));
          },
          onError: (error, variables, context) => {
            // if this results in an error remove the optimistic update
            // if (moduleDetailQuery.data?.data?.data?.conceptual_supports) {
            //   reset({
            //     ...moduleDetailQuery.data?.data?.data?.conceptual_supports,
            //   });
            // }
          },
        }
      );
    }
  };
  const saveOnExit = () => {
    const formData = getValues();
    if (skill?.id && level?.id && module?.id) {
      updateModuleDetail.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          module_id: module?.id,
          formData: formData[moduleName] as unknown as AddCourseLevelModuleData,
        },
        {
          onSuccess: (data, variables, context) => {
            message.success("Module updated!");
          },
          onError: (error, variables, context) => {},
        }
      );
    }
  };
  return {
    moduleDetailQuery,
    handleBlur,
    addLessonHandler,
    removeLessonHandler,
    moveLessonUp,
    moveLessonDown,
    videoStatusUpdateAfterUpload,
    fieldSaving,
    lessons,
    errors,
    control,
    addLesson,
    skill,
    level,
    deleteLesson,
    addPdfFileToLesson,
    deletePdfFileFromLesson,
    saveOnExit,
  };
};

import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button, Empty, Popover } from "antd";

const ListCourse = ({ courses, view }) => {
  const contentInfoStatus = (
    <div className="space-y-1">
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-white"></div>
        <span>not started</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-secondaryYellow"></div>
        <span>in progress</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-[#EF7574]"></div>
        <span>in review</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="border border-backdrop h-6 w-6 bg-primary-bg"></div>
        <span>live (published)</span>
      </div>
    </div>
  );
  return (
    <>
      <table className="w-full mt-6">
        <thead>
          <tr className="grid grid-cols-12 bg-[#D4F5C5]">
            <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-6">
              Skill Name
            </td>
            <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
              Status of Courses{" "}
              <Popover content={contentInfoStatus}>
                <InfoCircleOutlined className="cursor-pointer hover:text-primary" />
              </Popover>
            </td>
            <td className="font-bold border-t border-r border-b border-gray-300 py-3 px-5 text-backdrop col-span-3"></td>
          </tr>
        </thead>
        <tbody>
          {!courses ? (
            <tr>
              <td className=" col-span-12 border-l border-b border-r py-5">
                <Empty />
              </td>
            </tr>
          ) : view.dataView === 2 ? (
            courses
              ?.filter((v, aw) => aw <= view.dataView - 1)
              .map((item, index) => (
                <tr
                  key={index}
                  className="grid grid-cols-12 py-1  border-l border-b border-r"
                >
                  <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-6 flex items-center">
                    {item?.name}
                  </td>
                  <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-3 flex gap-1">
                    <div className="flex gap-1">
                      {item?.levels?.map((v, i) => (
                        <p
                          key={i}
                          className={`border-backdrop border-[1.6px] w-6 text-center flex justify-center ${
                            v?.accessible_for_me
                              ? "cursor-pointer"
                              : "opacity-40"
                          } ${
                            v?.status === "DRAFT"
                              ? "bg-white"
                              : v?.status === "ON_PROGRESS" ||
                                v?.status === "REJECTED"
                              ? "bg-[#FFEFA0]"
                              : v?.status === "PUBLISH"
                              ? "bg-primary-bg"
                              : "bg-[#EF7574]"
                          }`}
                        >
                          {v?.proficiency}
                        </p>
                      ))}
                    </div>
                  </td>
                  <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-3 flex justify-end">
                    <Link
                      to={`/courses/detail/${item?.id}`}
                      className=" text-[#009ACE]"
                    >
                      Go to skill
                    </Link>
                  </td>
                </tr>
              ))
          ) : (
            courses?.map((item, index) => (
              <tr
                key={index}
                className="grid grid-cols-12 py-1  border-l border-b border-r"
              >
                <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-6 flex items-center">
                  {item?.name}
                </td>
                <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-3 flex gap-1">
                  <div className="flex gap-1">
                    {item?.levels?.map((v, i) => (
                      <p
                        key={i}
                        className={`border-backdrop border-[1.6px] w-6 text-center flex justify-center ${
                          v?.accessible_for_me ? "cursor-pointer" : "opacity-40"
                        } ${
                          v?.status === "DRAFT"
                            ? "bg-white"
                            : v?.status === "ON_PROGRESS" ||
                              v?.status === "REJECTED"
                            ? "bg-[#FFEFA0]"
                            : v?.status === "PUBLISH"
                            ? "bg-primary-bg"
                            : "bg-[#EF7574]"
                        }`}
                      >
                        {v?.proficiency}
                      </p>
                    ))}
                  </div>
                </td>
                <td className="font-medium border-gray-300 py-2 px-5 text-backdrop col-span-3 flex justify-end">
                  <Link
                    to={`/courses/detail/${item?.id}`}
                    className=" text-[#009ACE]"
                  >
                    Go to skill
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {view.dataView < courses?.length && (
        <div className="text-end mt-3">
          <Button
            className=" border-primary"
            onClick={() => view.setDataView(undefined)}
          >
            View All
          </Button>
        </div>
      )}
    </>
  );
};

export default ListCourse;

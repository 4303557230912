import { useFormContext } from "react-hook-form";
import { useCourseEditorStore } from "../stores/useCourseEditorStore.ts";
import {
  OverviewForm,
  useFetchCourseLevelOverview,
  useUpdateCourseLevelOverview,
} from "./courseEditor.ts";
import { FormValues } from "../types/common.ts";
import React from "react";

export const useCourseOverview = () => {
  const { skill, level, checkErrors, setCheckErrors, setErrorInTab } =
    useCourseEditorStore((state) => state);
  const overviewQuery = useFetchCourseLevelOverview(skill?.id, level?.id);
  const updateOverview = useUpdateCourseLevelOverview();
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<FormValues>();

  React.useEffect(() => {
    const hasError = Object.keys(errors?.overview ?? {}).length > 0;
    if (hasError) {
      setErrorInTab("overview", true);
    } else {
      setErrorInTab("overview", false);
    }
    setCheckErrors(null);
  }, [checkErrors, trigger, errors, setCheckErrors, setErrorInTab]);

  const onSaveSubmit = (data) => {
    if (skill?.id && level?.id)
      updateOverview.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          formData: data,
        },
        {
          onSuccess: () => {},
        }
      );
  };

  function handleBlur(
    e: React.FocusEvent<HTMLTextAreaElement, Element>,
    name:
      | "certification_description"
      | "module_1st_outcome"
      | "module_2nd_outcome"
      | "module_3rd_outcome"
      | "module_4th_outcome"
  ) {
    const formData = getValues();
    const value = e?.target?.value?.trim();
    const isValueChanged =
      value &&
      value !== overviewQuery?.data?.data?.data?.certification_description;
    const isValueNotEmpty =
      value !== "" && value !== null && value !== undefined;

    const isFormDataChanged = Object.keys(formData).some((key) => {
      const formValue = formData[key];
      const originalValue = overviewQuery?.data?.data?.data[key];
      return formValue && formValue !== originalValue;
    });

    const isFormDataNotEmpty = Object.values(formData).every(
      (value) => value !== "" && value !== null && value !== undefined
    );

    if (
      skill?.id &&
      level?.id &&
      (isValueChanged || isFormDataChanged) &&
      (isValueNotEmpty || isFormDataNotEmpty)
    ) {
      updateOverview.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          formData: {
            ...(formData as unknown as OverviewForm),
            [name]: value,
          },
        },
        {
          onSuccess: () => {},
        }
      );
    }
  }

  const saveOnExit = () => {
    const formData = getValues();
    const isFormDataChanged = Object.keys(formData).some((key) => {
      const formValue = formData[key];
      const originalValue = overviewQuery?.data?.data?.data[key];
      return formValue && formValue !== originalValue;
    });

    const isFormDataNotEmpty = Object.values(formData).every(
      (value) => value !== "" && value !== null && value !== undefined
    );

    if (skill?.id && level?.id && isFormDataChanged && isFormDataNotEmpty) {
      updateOverview.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          formData: formData as unknown as OverviewForm,
        },
        {
          onSuccess: () => {},
        }
      );
    }
  };

  return {
    control,
    handleSubmit,
    onSaveSubmit,
    handleBlur,
    overviewQuery,
    skill,
    level,
    errors: errors,
    checkErrors,
    saveOnExit,
  };
};

import React, { useEffect, useState } from "react";
import avatar from "../../../assets/images/avatar_1.png";
import { Divider, Typography } from "antd";
import TableCourses from "../../courses/components/TableCourses";

const SkillAssignment = ({ user, data, isLoading }) => {
  const [fullName, setFullName] = useState("user");
  useEffect(() => {
    if (user) {
      if (user?.first_name && user?.last_name) {
        return setFullName(`${user?.first_name} ${user?.last_name}`);
      } else if (user?.first_name && !user?.last_name) {
        return setFullName(`${user?.first_name}`);
      } else if (!user?.first_name && user?.last_name) {
        return setFullName(`${user?.last_name}`);
      }
    }
  }, [user]);

  return (
    <div>
      <div className=" flex gap-3 items-center">
        <img
          src={user?.avatar || avatar}
          className="h-10 w-10 rounded-b-full object-contain"
          alt="avatar"
        />

        <Typography.Paragraph className=" capitalize !m-0 p-0 ">{`${fullName}'s`}</Typography.Paragraph>
      </div>
      <Divider className="my-1 p-0 border-black" />
      <div className="mt-5">
        <TableCourses
          isLoading={isLoading}
          listMyCourses={data?.data?.data}
          isRedirect={false}
        />
      </div>
    </div>
  );
};

export default SkillAssignment;

import React from "react";
import { Button, Empty, Input, Skeleton, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const InstructorList = ({ data, params, stage, edit, remove }) => {
  return (
    <div className=" ">
      <div className=" grid grid-cols-3 gap-5">
        <div className=" col-span-2 flex justify-between gap-5">
          <Input.Search
            onChange={(e) => params?.handleSearch(e.target.value)}
            defaultValue={params?.params?.name}
            placeholder="Input search text"
            allowClear
            enterButton={
              <Button type="primary" style={{ backgroundColor: "#86C769" }}>
                Search
              </Button>
            }
          />
          {/* <Select
            style={{ width: 200 }}
            // disabled
            placeholder="All Statuses"
            defaultValue={params?.params?.status}
            onChange={(e) =>
              params?.setParams((prev) => ({ ...prev, status: e }))
            }
            options={[
              { value: "active", label: "Active" },
              { value: "pending", label: "Pending" },
            ]}
          /> */}
        </div>
        <div className="text-end">
          <Button
            type="primary"
            style={{ backgroundColor: "#86C769" }}
            onClick={() => stage.setIsOpenNew(true)}
          >
            New User
          </Button>
        </div>
      </div>

      <table className="w-full mt-6">
        <thead className="">
          <tr className="grid grid-cols-12 bg-[#FFEFA0]">
            <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
              User
            </td>
            <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
              Role
            </td>
            <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
              Email
            </td>

            <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
              Status
            </td>
            <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
          </tr>
        </thead>

        <tbody className="">
          {data?.isLoading ? (
            <tr className="grid grid-cols-12">
              <td className=" col-span-12 border-b  border-l border-r border-gray-300 p-5">
                <Skeleton active paragraph={false} className="mb-3" />
                <Skeleton active paragraph={false} className="mb-3" />
                <Skeleton active paragraph={false} className="mb-3" />
                <Skeleton active paragraph={false} className="mb-3" />
              </td>
            </tr>
          ) : data?.data?.length > 0 ? (
            data?.data?.map((v, i) => (
              <tr key={i} className="grid grid-cols-12">
                <td className="border-l border-b border-gray-300 py-2 px-5 col-span-3 flex items-center gap-5">
                  <img
                    src={v.avatar}
                    className="h-10 w-10 rounded-full object-contain"
                    alt="avatar"
                  />
                  <div className=" truncate capitalize">{`${v.first_name} ${v.last_name}`}</div>
                </td>
                <td className="border-b border-gray-300 py-2 px-5 col-span-3 flex items-center">
                  <div className=" truncate capitalize">{v.role}</div>
                </td>
                <td className="border-b border-gray-300 py-2 px-5 col-span-3 flex items-center ">
                  <div className=" truncate">{v.email || "-"}</div>
                </td>
                <td className="border-b border-gray-300 py-2 px-5 col-span-2 flex items-center ">
                  <Tag
                    color={v.status === "active" ? "green" : ""}
                    className=" capitalize py-0.5 px-2"
                  >
                    {v.status}
                  </Tag>
                </td>
                <td className="border-b border-r border-gray-300 py-2 px-5 col-span-1 flex items-center justify-between gap-5 ">
                  <EditOutlined
                    className=" cursor-pointer"
                    onClick={() => edit(v.id)}
                  />
                  <DeleteOutlined
                    onClick={() =>
                      v.role !== "Admin" &&
                      stage.isDisabled &&
                      remove?.handleOpen(v)
                    }
                    className={`${
                      v.role === "Admin" && stage.isDisabled
                        ? "text-slate-400 cursor-not-allowed"
                        : "cursor-pointer "
                    }`}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="grid grid-cols-12">
              <td className=" col-span-12 border-b  border-l border-r border-gray-300 py-5">
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InstructorList;

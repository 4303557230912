import React from "react";
import {
  LinkedinFilled,
  MediumOutlined,
  TwitterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Typography } from "antd";
import place from "../../assets/icon/pin_map.svg";

const ViewProfile = ({ handleEdit, user, school }) => {
  return (
    <div className="">
      <div className="flex items-center justify-center flex-col gap-5 ">
        {user?.data?.avatar ? (
          <img
            src={`${user?.data?.avatar}?${Date.now()}`}
            className="border border-primary rounded-full h-28 w-28 object-contain bg-white"
            alt="profile"
          />
        ) : (
          <div className="w-28 h-28 border border-gray-300 bg-primary-bg flex justify-center items-center rounded-full">
            <UserOutlined className="text-[30px]" />
          </div>
        )}
        <div className="flex gap-3">
          {user?.data?.linkedin_url && (
            <a
              target="_blank"
              href={
                user?.data?.linkedin_url?.startsWith("http")
                  ? user?.data?.linkedin_url
                  : `http://${user?.data?.linkedin_url}`
              }
              rel="noreferrer"
            >
              <LinkedinFilled />
            </a>
          )}
          {user?.data?.twitter_url && (
            <a
              target="_blank"
              href={
                user?.data?.twitter_url?.startsWith("http")
                  ? user?.data?.twitter_url
                  : `http://${user?.data?.twitter_url}`
              }
              rel="noreferrer"
            >
              <TwitterOutlined />
            </a>
          )}
          {user?.data?.medium_url && (
            <a
              target="_blank"
              href={
                user?.data?.medium_url?.startsWith("http")
                  ? user?.data?.medium_url
                  : `http://${user?.data?.medium_url}`
              }
              rel="noreferrer"
            >
              <MediumOutlined />
            </a>
          )}
        </div>
      </div>
      <div className="flex flex-col my-10">
        <Typography.Title level={4} className=" capitalize">
          {`${user?.data?.first_name} ${user?.data?.last_name}`}
        </Typography.Title>
        <p>
          {user?.data?.job_title} at{" "}
          <span className=" capitalize">{school?.name}</span>
        </p>
        <div className="flex gap-3 items-center my-1">
          <img src={place} alt="pin-map" />
          <p>{user?.data?.country || "-"}</p>
        </div>
        <p className="mt-1 whitespace-pre-line first-letter:capitalize">
          {user?.data?.about || "-"}
        </p>
      </div>
      <div className="my-3 text-end">
        <Button
          className="bg-primary text-white border-0 hover:bg-[#D4F5C5]"
          onClick={handleEdit}
        >
          Edit Profile
        </Button>
      </div>
    </div>
  );
};

export default ViewProfile;

import httpRequest from "../../../config/config";
import { ApiResponseSimple } from "../../../common/types/ApiResponse";

interface IInstructorQuery {
  name?: string;
  status?: "active" | "pending";
}

interface IInstructor {
  id: string;
  tenant_id: string;
  display_name: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  country: string;
  status: string;
  avatar: string;
  about: string;
  job_title: string;
}

export const getInstructorList = async (data?: IInstructorQuery) => {
  return httpRequest.get<ApiResponseSimple<IInstructor[]>>(
    "/school/instructors",
    {
      params: data,
    }
  );
};

import React from "react";
import { BasicTitleContainer } from "./BasicTitle.tsx";
import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { BasicContainerRichEditor } from "./BasicContainerRichEditor.tsx";
import { BasicContainerWithTitle } from "./BasicContainerWithTitle.tsx";
import { Input, Radio, Switch } from "antd";
import { BasicContainer } from "./BasicContainer.tsx";
import { Answer, FormValues, QuestionData } from "../../types/common.ts";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";
import { SavingTag } from "../../../../common/components/SavingTag.tsx";
import { Slider } from "antd";
import { useQuestionContainer } from "../../hooks/useQuestionContainer.ts";

const { TextArea } = Input;

type QuestionFormType = FieldArrayWithId<
  {
    questions: QuestionData[] | undefined;
  },
  "questions",
  "fid"
>;

interface Props {
  question: QuestionFormType;
  handleDelete: () => void;
  handleMoveUp: () => void;
  handleMoveDown: () => void;
  totalQuestions: number;
  addAnswer: (assessment_id: string) => void;
  deleteAnswer: (assessment_id: string, answerIndex: number) => void;
  handleToggleIsTimed: (value: boolean) => void;
  setTime: (questionId: string, value: number) => void;
  questionIndex: number;
  replaceAssessmentList: (a: any) => void;
}

export const QuestionContainer: React.FC<Props> = ({
  question,
  handleDelete,
  handleMoveUp,
  handleMoveDown,
  totalQuestions,
  addAnswer,
  deleteAnswer,
  handleToggleIsTimed,
  setTime,
  questionIndex,
  replaceAssessmentList,
}) => {
  const {
    isCollapsed,
    setIsCollapsed,
    handleQuestionBlur,
    handleAnswerBlur,
    radioChangeHandler,
    skill,
    level,
    control,
    errors,
    updateAssessment,
  } = useQuestionContainer(question, replaceAssessmentList);

  return (
    <div className='mt-4'>
      <BasicTitleContainer className=''>
        <div className='flex items-center justify-between'>
          <div className=''>
            <div className='flex gap-x-4'>
              <CaretDownFilled
                className={`${
                  isCollapsed ? "-rotate-90" : ""
                } text-[22px] hover:scale-125 duration-100 transition-transform`}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
              <div className='flex'>
                <CaretUpFilled
                  onClick={question?.order === 1 ? undefined : handleMoveUp}
                  disabled={question?.order === 1}
                  className={`${
                    question?.order === 1
                      ? "cursor-not-allowed text-gray-500"
                      : ""
                  }`}
                />
                <CaretDownFilled
                  onClick={
                    question?.order === totalQuestions
                      ? undefined
                      : handleMoveDown
                  }
                  disabled={question?.order === totalQuestions}
                  className={`${
                    question?.order === totalQuestions
                      ? "cursor-not-allowed text-gray-500"
                      : ""
                  }`}
                />
              </div>
              <h3 className=''>Question {question?.order}</h3>
            </div>
          </div>
          <div className='flex items-center gap-x-4'>
            <SavingTag isSaving={updateAssessment.isLoading} />
            {question?.order === totalQuestions && (
              <DeleteOutlined
                className={
                  totalQuestions === 1
                    ? "cursor-not-allowed text-gray-400 text-[22px]"
                    : "text-backdrop text-[22px] hover:scale-125 duration-100 transition-transform"
                }
                onClick={() => (totalQuestions === 1 ? null : handleDelete())}
                disabled={totalQuestions === 1}
              />
            )}
          </div>
        </div>
      </BasicTitleContainer>
      <div
        className={`transition-all delay-150 duration-300 overflow-hidden ${
          isCollapsed ? "h-0" : "min-h-[550px]"
        }`}>
        <BasicContainerRichEditor
          className='mt-4'
          placeholder='Type your question'
          control={control}
          showError
          errorMessage={"The question is Required"}
          // @ts-ignore
          isError={!!errors?.assessment?.questions?.[questionIndex]?.question}
          name={`assessment.questions.${questionIndex}.question`}
          richTextProps={{
            onBlur(previousSelection, source, editor) {
              handleQuestionBlur(editor.getHTML());
            },
          }}
        />

        <div className='grid grid-cols-12 gap-x-6'>
          <AnswersContainer
            className='mt-6 col-span-9'
            addAnswer={addAnswer}
            deleteAnswer={deleteAnswer}
            totalAnswers={question?.answers?.length}
            answers={question?.answers}
            handleAnswerBlur={handleAnswerBlur}
            radioChangeHandler={radioChangeHandler}
            questionIndex={questionIndex}
            question={question}
          />
          <div className=' mt-6 col-span-3'>
            <BasicContainer>
              <div className='flex items-center gap-x-3'>
                <Switch
                  checked={question?.timer > 0}
                  onChange={(value) => handleToggleIsTimed(value)}
                />
                <h3 className=''>Timed Answer</h3>
              </div>
              {question?.timer > 0 ? (
                <div className='mt-6'>
                  <div>
                    The learner will have {question?.timer} minutes to answer
                    this question
                  </div>
                  <Controller
                    name={`assessment.questions.${questionIndex}.timer`}
                    control={control}
                    render={({ field, fieldState, formState }) => {
                      return (
                        <Slider
                          {...field}
                          min={0.5}
                          max={10}
                          onChange={(value) => {
                            if (skill?.id && level?.id && question?.id) {
                              setTime(question?.id, value);
                            }
                          }}
                          trackStyle={{ backgroundColor: "#91D5FF" }}
                          style={{
                            color: "green",
                          }}
                          defaultValue={8}
                          marks={{
                            1: {
                              label: 1,
                              style: { marginTop: 10 },
                            },
                            2: {
                              label: 2,
                              style: { marginTop: 10 },
                            },
                            3: {
                              label: 3,
                              style: { marginTop: 10 },
                            },
                            4: {
                              label: 4,
                              style: { marginTop: 10 },
                            },
                            5: {
                              label: 5,
                              style: { marginTop: 10 },
                            },
                            6: {
                              label: 6,
                              style: { marginTop: 10 },
                            },
                            7: {
                              label: 7,
                              style: { marginTop: 10 },
                            },
                            8: {
                              label: 8,
                              style: { marginTop: 10 },
                            },
                            9: {
                              label: 9,
                              style: { marginTop: 10 },
                            },
                            10: {
                              label: 10,
                              style: { marginTop: 10 },
                            },
                          }}
                        />
                      );
                    }}
                  />
                </div>
              ) : (
                <div className='mt-6'>
                  The learner can take all the time in the Universe to answer
                  this question.
                </div>
              )}
            </BasicContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AnswersContainerProps {
  className?: string;
  addAnswer: (assessment_id: string) => void;
  deleteAnswer: (assessment_id: string, answerIndex: number) => void;
  answers: Answer[];
  totalAnswers: number;
  handleAnswerBlur: (value: string, index: number) => void;
  radioChangeHandler: (value: boolean, index: number) => void;
  questionIndex: number;
  question: QuestionFormType;
}

const AnswersContainer: React.FC<AnswersContainerProps> = ({
  className,
  addAnswer,
  deleteAnswer,
  answers,
  totalAnswers,
  handleAnswerBlur,
  radioChangeHandler,
  questionIndex,
  question,
}) => {
  return (
    <BasicContainerWithTitle
      className={className}
      title='Answers'
      titleSize='h3'
      extraTitle={
        <PlusOutlined
          className='hover:scale-125 text-base hover:shadow-md transition-transform duration-200'
          onClick={() => addAnswer(question?.id)}
        />
      }>
      {answers?.map((answer, index) => (
        <AnswerOption
          totalAnswers={totalAnswers}
          key={index}
          deleteAnswer={() => deleteAnswer(question?.id, index)}
          answer={answer}
          handleAnswerBlur={handleAnswerBlur}
          radioChangeHandler={radioChangeHandler}
          answerIndex={index}
          totalCorrectAnswers={
            answers?.filter((answer) => answer?.is_correct_answer)?.length
          }
          questionIndex={questionIndex}
        />
      ))}
    </BasicContainerWithTitle>
  );
};

interface AnswerOptionProps {
  deleteAnswer: () => void;
  answer: Answer;
  radioChangeHandler: (value: boolean, index: number) => void;
  handleAnswerBlur: (value: string, index: number) => void;
  answerIndex: number;
  totalAnswers: number;
  totalCorrectAnswers: number;
  questionIndex: number;
}

const AnswerOption: React.FC<AnswerOptionProps> = ({
  deleteAnswer,
  answer,
  radioChangeHandler,
  handleAnswerBlur,
  answerIndex,
  totalAnswers,
  totalCorrectAnswers,
  questionIndex,
}) => {
  const [checkedRadio, setCheckedRadio] = React.useState<boolean>(
    answer?.is_correct_answer
  );
  const { control } = useFormContext<FormValues>();

  const handleRadioChange = (event: any) => {
    setCheckedRadio(!checkedRadio);
    radioChangeHandler(!checkedRadio, answerIndex);
  };

  return (
    <div className='flex justify-between items-baseline gap-x-6'>
      <div className='flex flex-col space-y-6 '>
        <Radio
          onClick={(event) => handleRadioChange(event)}
          checked={checkedRadio}
          className={`w-full`}></Radio>
      </div>
      <Controller
        name={`assessment.questions.${questionIndex}.answers.${answerIndex}.answer`}
        control={control}
        render={({ field, fieldState, formState }) => {
          return (
            <div className='flex flex-col justify-start items-start w-full'>
              <TextArea
                {...field}
                className='mt-4 rounded-md'
                placeholder={"Type Answer Here"}
                onBlur={(event) => {
                  handleAnswerBlur(event?.target?.value, answerIndex);
                }}
                autoSize={{ minRows: 1, maxRows: 6 }}
              />
              {fieldState?.error ? (
                <div className='mt-2 text-danger'>
                  {fieldState?.error
                    ? "Answer is required and must be above 2 characters"
                    : null}
                </div>
              ) : null}
            </div>
          );
        }}
      />
      {answerIndex > 1 && (
        <CloseOutlined
          // className=''
          className={
            totalAnswers === 2
              ? "cursor-not-allowed  text-gray-400 "
              : "hover:scale-125 text-base hover:shadow-md transition-transform duration-200"
          }
          onClick={() => (totalAnswers === 2 ? null : deleteAnswer())}
          disabled={totalAnswers === 2}
        />
      )}
    </div>
  );
};

import React from "react"
import {HomeOutlined} from "@ant-design/icons"
import {Breadcrumbs} from "../../../common/components/Breadcumbs"
import InputSearch from "../../../common/components/InputSearch"
import InputDropdown from "../components/InputDropdown"
import TableSkillAndCourses from "../components/TableSkillAndCourses"
import {useFetchSkillAndCourses} from "../hook/useFetchSkillAndCourses"
import {useInstructor} from "../hook/useInstructor"
import {Button, Skeleton, Tag} from "antd";
import {useSchool} from "../../onboarding/hooks/useSchool";
import {useNavigate} from "react-router-dom";

const ListCoursesAndCourses = () => {

	const {
		listSkillAndCourses,
		isLoading, // handlePageChange,
		// handleLimitChange,
		levelName,
		handleSetFilterByLevel,
		handleSearch,
		search,
		handleChangeFilterByTrainer,
		trainerName
	} = useFetchSkillAndCourses()

	const navigate = useNavigate()

	const {list} = useInstructor()
	const {
		school,
	} = useSchool()

	const itemTrainers = list?.data?.map((item) => (
		{
			name: `${item?.first_name} ${item?.last_name}`,
			id  : item?.id,
		}
	))

	const items = [
		{
			path : "/",
			title: <HomeOutlined className="text-gray-400"/>,
		},
		{
			path : "/trainer-admin/skills-courses",
			title: "Skills and Courses",
		},
	]

	const itemsPublishLevel = [
		{
			name: 'Level 1',
			id  : '1'
		},
		{
			name: 'Level 2',
			id  : '2'
		},
		{
			name: 'Level 3',
			id  : '3'
		},
		{
			name: 'Level 4',
			id  : '4'
		},
		{
			name: 'Level 5',
			id  : '5'
		}
	]

	return (
		<div>
			<div className="px-6 py-2 bg-white ">
				<div className="mb-3">
					<Breadcrumbs items={items}/>
				</div>
				<h1 className="text-2xl font-bold text-backdrop">Skills & Courses</h1>
			</div>

			<div className="mx-5 px-5 py-5 my-5  bg-white">
				{school?.loading ? <Skeleton active={true}/> : <div>
					<div className={`flex items-center gap-6`}>
						<h4>Certificate Status</h4>
						<Tag
							style={{
								backgroundColor: school?.detail?.is_signature_exist ? "#D4F5C5" : "red",
								color          : school?.detail?.is_signature_exist ? "#000" : "#FFF",
							}}
							className={`rounded-full px-4 border-none`}
						>
							{school?.detail?.is_signature_exist ? "Enabled" : "Disabled"}
						</Tag>
					</div>
					{!school?.detail?.is_signature_exist && <p>Your school does not issue certificates of completion. Set up
						missing information to enable Certificates of
						Completion</p>}

					<Button onClick={() => navigate(`certificate`)} className={`border-primary mt-5`}>Edit Certificates</Button>

				</div>}


				<div className={`mt-5`}>
					<h4>Skills your School Teaches</h4>
					<p className="mb-3">
						Skills to teach are based on the agreement with Skilledin. Once the
						skill is added to your school, you need to assign a trainer for each
						skill. The trainers will then see Skills to Teach in their dashboard.
					</p>

					<div className="space-y-3">
						<div className="grid grid-cols-12 gap-8">
							<div className="col-span-8">
								<InputSearch
									placeholder={"Search"}
									value={search}
									handleSearchChange={handleSearch}
								/>
							</div>
							<div className="col-span-2">
								<InputDropdown
									defaultValue={'Published Level'}
									data={itemsPublishLevel}
									handleChangeFilter={handleSetFilterByLevel}
									selected={levelName ? levelName : 'All Publish Level'}
								/>
							</div>
							<div className="col-span-2">
								<InputDropdown
									defaultValue={'Trainers'}
									data={itemTrainers}
									handleChangeFilter={handleChangeFilterByTrainer}
									selected={trainerName ? trainerName : 'All Trainer'}
								/>
							</div>
						</div>
						<div>
							<TableSkillAndCourses isLoading={isLoading} listSkillAndCourses={listSkillAndCourses}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ListCoursesAndCourses

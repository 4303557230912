export const SCHOOL_DASHBORAD_ENDPOINT = {
  STATS: `school/dashboard/admin`,
  LIST_PROGRAMS: `school/programs`,
  CREATE_PROGRAMS: `school/programs`,
  DETAIL_PROGRAM: (id: string) => `school/programs/${id}`,
  UPDATE_PROGRAM: (id: string) => `school/programs/${id}`,

  CREATE_COURSE: `school/programs/courses`,
  DELETE_COURSE: (id: string) => `school/programs/courses/${id}`,
  UPDATE_COURSE: (id: string) => `school/programs/courses/${id}`,
  NOTIFY_TRAINER: (id: string) => `school/programs/notify/${id}`,

  SHOW_CERTIFICATE: `school/settings/certificate
`
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  LinkedinOutlined,
  MediumOutlined,
  TwitterOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import { CountryComponent } from "./country/Country";
const { TextArea } = Input;

const EditProfile = ({ user, school, loading }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (user) {
      reset({
        first_name: user?.data?.first_name,
        last_name: user?.data?.last_name,
        about: user?.data?.about,
        country: user?.data?.country || null,
        job_title: user?.data?.job_title,
        organization: user?.data?.data?.organization,
        linkedin_url: user?.data?.linkedin_url,
        twitter_url: user?.data?.twitter_url,
        medium_url: user?.data?.medium_url,
      });
    }
  }, [user?.data?.data]);

  return (
    <div className="">
      <div className="flex items-center justify-center flex-col gap-5 relative">
        {user?.avatar?.imageUrl ? (
          <img
            src={user?.avatar?.imageUrl}
            className="border rounded-full h-28 w-28 object-contain bg-white"
            alt="profile"
          />
        ) : (
          <div className="w-28 h-28 border border-gray-300 bg-primary-bg flex justify-center items-center rounded-full">
            <UserOutlined className="text-[30px]" />
          </div>
        )}
        <label
          htmlFor="image-profile"
          className="absolute bottom-1 left-[55%] w-6 h-6 text-center rounded-full bg-white cursor-pointer"
        >
          <EditOutlined className="text-primary" />
        </label>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="image-profile"
          className=" hidden"
          onChange={user?.avatar?.handleImageChange}
        />
      </div>
      <form className="mt-5 " onSubmit={handleSubmit(user?.action?.update)}>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="text-xs" htmlFor="first_name">
              First Name
            </label>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="first_name"
                  status={errors?.first_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="First Name"
                />
              )}
            />
          </div>
          <div>
            <label className="text-xs" htmlFor="last_name">
              Last Name
            </label>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="last_name"
                  status={errors?.last_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="Last Name"
                />
              )}
            />
          </div>
        </div>
        <div>
          <label className="text-xs" htmlFor="about">
            About the Instructor
          </label>
          <Controller
            name="about"
            control={control}
            rules={{ required: true, maxLength: 1024 }}
            render={({ field }) => (
              <TextArea
                rows={4}
                {...field}
                // style={{ resize: "none" }}
                id="about"
                status={errors?.about ? "error" : ""}
                className="my-1 font-default rounded-none -mt-0"
                placeholder="Showcase your Green Skills expertise and experience"
                maxLength={1024}
                showCount
              />
            )}
          />
        </div>
        <div>
          <div
            className="text-xs mt-1 -mb-0.5"
            // style={{ border: "1px solid red" }}
          >
            <label className="text-xs" htmlFor="country">
              Country
            </label>
          </div>
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CountryComponent
                status={errors?.location && "error"}
                id="country"
                className="mt-10 "
                {...field}
              />
            )}
          />
        </div>
        <div className="mt-1">
          <label className="text-xs" htmlFor="job_title">
            Job Title
          </label>
          <Controller
            name="job_title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                id="job_title"
                status={errors?.job_title ? "error" : ""}
                className="my-1 font-default rounded-none -mt-0"
                placeholder="e.g. Associate Professor"
              />
            )}
          />
        </div>

        <div className="mt-1">
          <label className="text-xs" htmlFor="about">
            School
          </label>
          <p className=" capitalize">{school?.name}</p>
        </div>
        <div className="mt-7">
          <p className="">Social Media (optional)</p>
          <Controller
            name="linkedin_url"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="linkedin_url"
                prefix={<LinkedinOutlined />}
                className="my-2 font-default rounded-none "
                placeholder="linkedin.com/in/account"
              />
            )}
          />
          <Controller
            name="twitter_url"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="twitter_url"
                prefix={<TwitterOutlined />}
                className="my-2 font-default rounded-none "
                placeholder="twitter.com/account"
              />
            )}
          />
          <Controller
            name="medium_url"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="medium_url"
                prefix={<MediumOutlined />}
                className="my-2 font-default rounded-none "
                placeholder="medium.com/@account"
              />
            )}
          />
        </div>
      </form>
      <div className="flex justify-end mt-5">
        <Button
          className="bg-primary text-white border-0 hover:bg-[#D4F5C5]"
          onClick={handleSubmit(user?.action?.update)}
          loading={user?.action?.loading}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;

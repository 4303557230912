import React from "react";
// import logo_triangle from "../../../assets/images/green_logo_triangle.svg";
import logo_label from "../../../assets/images/skilledin3.png";
import { Tag } from "antd";

export const Header = ({ step, refetch }) => {
  return (
    // <div className="text-center">
    //   <div className="">
    //     <div>
    //       <Image className="mx-auto" src={logo_triangle} />
    //     </div>
    //     <div>
    //       <Image className="mx-auto mt-2" src={logo_label} />
    //     </div>
    //   </div>
    //   <p className="font-semibold my-3">
    //     A few steps to get you up and running as a Green Instructor!
    //   </p>
    //   {refetch && (
    //     <div className="text-primary">
    //       Sorry, the last link has been expired. So we generate the new one for
    //       you
    //     </div>
    //   )}
    //   {step !== 2 && (
    //     <p>
    //       Fill in the information to be displayed in
    //       <span className="font-semibold"> “Meet the Instructor”</span> on the
    //       skills you teach
    //     </p>
    //   )}
    // </div>
    <div className=' flex gap-1 items-center mb-10'>
      <img
        src={logo_label}
        alt='logo'
        className='w-14 h-14 object-contain'
      />
      <Tag color='orange'>pre-Alpha 0.0.4</Tag>
    </div>
  );
};

import React, { useEffect } from "react";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Typography } from "antd";
import { CountryComponent } from "../../../common/components";

const { TextArea } = Input;

export const InformationUser = ({ data, avatar, action }) => {
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        first_name: data?.first_name,
        last_name: data?.last_name,
        about: data?.about,
        country: data?.country || null,
        job_title: data?.job_title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="px-[25%]">
      <Typography.Paragraph className="text-center">
        {data?.role === "Admin"
          ? "You are setting up an Admin account for your school. Admins in Skilledin Green have an instructor role as well. Fill in information, that is visible to learners on the skills you teach."
          : "Fill in the information to be displayed in “Meet the Instructor” on the skills you teach"}
      </Typography.Paragraph>
      <div className="flex flex-col items-center">
        <p className="mb-3">Profile Picture</p>
        <div className="flex items-center justify-center flex-col gap-5 relative">
          {avatar?.imageUrl ? (
            <img
              src={avatar?.imageUrl}
              className="border rounded-full h-32 w-32 object-contain bg-white"
              alt="profile"
            />
          ) : (
            <div
              className={`w-28 h-28 border ${
                errors?.avatar ? " border-danger" : "border-gray-300"
              } bg-primary-bg flex justify-center items-center rounded-full`}
            >
              <UserOutlined className="text-[30px]" />
            </div>
          )}
          <label
            htmlFor="image-profile"
            className="absolute bottom-0 left-[65%] w-6 h-6 text-center rounded-full bg-white cursor-pointer"
          >
            <EditOutlined className="text-primary" />
          </label>

          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            id="image-profile"
            className=" hidden"
            {...register("avatar", {
              required: true,
              onChange: (e) => {
                avatar?.handleImageChange(e);
              },
            })}
          />
        </div>
      </div>
      <form className="mt-5">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <label className="text-xs" htmlFor="first_name">
              First Name
            </label>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="first_name"
                  status={errors?.first_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="First Name"
                />
              )}
            />
          </div>
          <div>
            <label className="text-xs" htmlFor="user_last_name">
              Last Name
            </label>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="last_name"
                  status={errors?.last_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="Last Name"
                />
              )}
            />
          </div>
        </div>
        <div>
          <label className="text-xs" htmlFor="about">
            About the Instructor
          </label>
          <Controller
            name="about"
            control={control}
            rules={{ required: true, maxLength: 1024 }}
            render={({ field }) => (
              <TextArea
                rows={4}
                {...field}
                id="about"
                status={errors?.about ? "error" : ""}
                className="my-1 font-default rounded-none -mt-0"
                placeholder="Showcase your Green Skills expertise and experience"
                maxLength={1024}
                showCount
              />
            )}
          />
        </div>
        <div>
          <div
            className="text-xs mt-1 -mb-0.5"
            // style={{ border: "1px solid red" }}
          >
            <label className="text-xs" htmlFor="country">
              Country
            </label>
          </div>
          {/* <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                status={errors.country ? "error" : ""}
                id="country"
                className="my-1 font-default w-full"
                placeholder="Select country"
                showSearch
                // options={
                //   countries?.data?.data?.map((item) => ({
                //     value: item.name,
                //     label: item.name,
                //   })) || []
                // }
                // defaultValue={user?.data?.data?.country_id || null}
                onSelect={(val) => setValue("country", val)}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            )}
          /> */}
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CountryComponent
                status={errors?.location && "error"}
                id="country"
                className="mt-10"
                {...field}
              />
            )}
          />
        </div>
        <div>
          <label className="text-xs" htmlFor="job_title">
            Job Title
          </label>
          <Controller
            name="job_title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                id="job_title"
                status={errors?.job_title ? "error" : ""}
                className="my-1 font-default rounded-none -mt-0"
                placeholder="e.g. Associate Professor"
              />
            )}
          />
        </div>
      </form>
      <div className="text-right my-10">
        <Button
          type="primary"
          loading={action.loading}
          className="bg-[#86C769]"
          onClick={handleSubmit(action.update)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

import { Avatar, Button, Empty, Spin, Tooltip } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"

const TableSkillAndCourses = ({ isLoading, listSkillAndCourses }) => {
  const navigate = useNavigate()

  return (
    <table className="w-full mt-6">
      <thead className="grid grid-cols-12 bg-[#D4F5C5]">
        <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-5">
          Skill Name
        </td>
        <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
          Trainers
        </td>
        <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
          Published Courses
        </td>

        <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-3">
          Price per Course
        </td>
      </thead>
      {isLoading ? (
        <div className="py-4 border text-center">
          <Spin spinning={isLoading} size="large" />
        </div>
      ) : listSkillAndCourses === null ? (
        <div className="py-4 border text-center">
          <Empty />
        </div>
      ) : (
        listSkillAndCourses?.map((item, index) => (
          <tbody className="grid grid-cols-12" key={index}>
            <td className="font-medium border-t border-l border-b border-gray-300 py-2 px-5 text-backdrop col-span-5 flex items-center">
              {item?.name}
            </td>
            <td className="font-medium border-t border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center">
              {item?.trainers ? (
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                >
                  {Array.from(
                    new Set(item.trainers.map((trainer) => trainer.id))
                  ).map((trainerId) => {
                    const trainer = item.trainers.find((t) => t.id === trainerId)

                    return (
                      <Tooltip
                        key={trainer?.id}
                        title={trainer?.first_name + " " + trainer?.last_name}
                        placement="top"
                      >
                        <Avatar
                          src={trainer?.avatar ? trainer?.avatar : undefined}
                        >
                          {!trainer?.avatar && trainer?.first_name + trainer?.last_name}
                        </Avatar>
                      </Tooltip>
                    )
                  })}
                </Avatar.Group>
              ) : (
                '-'
              )}
            </td>
            <td className="font-medium border-t border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center">
              {item?.published_level}
            </td>

            <td className="font-medium border-t border-b border-r border-gray-300 py-2 px-5 text-backdrop col-span-3">
              <div className="flex items-center justify-between">
                <p>${item?.price_per_level}</p>
                <Button
                  onClick={() =>
                    navigate(`/trainer-admin/skills-courses/${item?.id}`)
                  }
                >
                  Details
                </Button>
              </div>
            </td>
          </tbody>
        ))
      )}
    </table>
  )
}

export default TableSkillAndCourses

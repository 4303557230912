import React from "react";
import ViewProfile from "./ViewProfile";
import EditProfile from "./EditProfile";

const Profile = ({ isEdit, handleEdit, data, school }) => {
  return (
    <>
      {isEdit ? (
        <EditProfile user={data} school={school} />
      ) : (
        <ViewProfile handleEdit={handleEdit} user={data} school={school} />
      )}
    </>
  );
};

export default Profile;

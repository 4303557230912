import { message } from "antd"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { FetchDetailSkillAndCourses, UpdateSkillAndCourses } from "../apis/skillAndCourses"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const useFetchDetailSkillAndCourses = (id) => {
  

  const useFetchData = (params = {}) => {
    console.log('params', params)
    const handleFetchError = (error) => {
      message.error(`An error occurred while fetching skill and courses: ${error}`)
    }

    return useQuery(['detail-skill-and-courses', params], () => FetchDetailSkillAndCourses(params), {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    })
  }

  const { data: detailSkillAndCourses, isLoading: isDetailLoading } = useFetchData(id)


  const schema = yup.object().shape({
    id: yup.string().required('Course ID is required'),
    level_id: yup.string().required('Level ID is required'),
    instructor_id: yup.string().required('Instructor ID is required'),
  })
  const {
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const useEditSkillAndCourse = () => {
    const queryClient = useQueryClient()

    const handleEditSuccess = () => {
      message.success('Trainer assign to course level successfully')
    }

    const handleEditError = (error) => {
      message.error(`An error occurred while editing a job: ${error}`)
    }

    return useMutation((params) => UpdateSkillAndCourses(id, params), {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['detail-skill-and-courses'])

        handleEditSuccess()
      },
      onError: handleEditError,
    })
  }


  const editSkillAndCourseMutate = useEditSkillAndCourse()

  const onSaveSubmit = async (params) => {
    editSkillAndCourseMutate.mutate(params)
  }

  return {
    detailSkillAndCourses: detailSkillAndCourses?.data?.data,
    isDetailLoading,
    onSaveSubmit,
    handleSubmit,
  }
}

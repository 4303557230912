import React from "react";
// import {
//   Header,
//   InformationUser,
//   SocialMedia,
//   StripeOnboard,
// } from "./components";
// import useOnboarding from "./hooks/useOnboarding";
// import { SetupCompany } from "./components/SetupCompany";
// import CustomHeader from "../../common/layout/CustomHeader";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../common/components/NotFound";
import SchoolForm from "./pages/SchoolForm";
import StripeForm from "./pages/StripeForm";
import UserForm from "./pages/UserForm";
import SocialForm from "./pages/SocialForm";

const Index = () => {
  return (
    <Routes>
      <Route path="/school/detail" element={<SchoolForm />} />
      <Route path="/school/stripe" element={<StripeForm />} />

      <Route path="/user/profile" element={<UserForm />} />
      <Route path="/user/social" element={<SocialForm />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Index;

import React from 'react'
import FormAssessment from './FormAssessment'

const TabModuleAssesmentContainer = ({
  // questionFields,
  handleAddNewQuestion,
  handleRemoveQuestion,
  handleFormQuestionChange,
  handleAddNewOption,
  handleOptionValueChange,
  handleRemoveAnswerOption,
  handleOptionIsAnswerChange,
  register,
  handleSubmit,
  errors,
  onSubmit,
  moduleData,
  moduleNumber,
  handleLessonsChangeModule,
}) => {
  return (
    <div>
      <FormAssessment
        moduleData={moduleData}
        // questionFields={questionFields}
        handleAddNewQuestion={handleAddNewQuestion}
        handleRemoveQuestion={handleRemoveQuestion}
        handleFormQuestionChange={handleFormQuestionChange}
        handleAddNewOption={handleAddNewOption}
        handleOptionValueChange={handleOptionValueChange}
        handleRemoveAnswerOption={handleRemoveAnswerOption}
        handleOptionIsAnswerChange={handleOptionIsAnswerChange}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        moduleNumber={moduleNumber}
        moduleName='Module-5'
        handleLessonsChangeModule={handleLessonsChangeModule}
      />
    </div>
  )
}

export default TabModuleAssesmentContainer

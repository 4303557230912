import React from "react";
import { useUser } from "../hooks/useUser";
import { SocialMedia } from "../components";

const SocialForm = () => {
  const { user } = useUser();
  return (
    <div>
      <div className=" bg-[#E2EBFA]  mt-10">
        <SocialMedia data={user.data} action={user.action} />
      </div>
    </div>
  );
};

export default SocialForm;

import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { useEffect, useState } from "react";
import { message } from "antd";

export const useUser = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    "user-school",
    () => httpRequest.get("/school/instructors/profile"),
    { retry: false }
  );

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
    setImageFile(file);
  };

  // handle action
  const { mutate: updateUser, isLoading: loadingUpdateUSer } = useMutation({
    mutationFn: (val) => {
      return httpRequest.patch(`/school/instructors/profile`, val);
    },
    mutationKey: "update-user-school",
  });

  // upload image
  const { mutate: uploadImage } = useMutation({
    mutationFn: (val) => {
      return httpRequest.post(`/school/instructors/profile/avatar`, val);
    },
    mutationKey: "updload-image-school",
  });

  const handleUpdateNoUpload = (val) => {
    updateUser(val, {
      onSuccess: () => {
        message.success("success update user");
        queryClient.refetchQueries("cheking-progress");
        queryClient.refetchQueries("list-instructors");
        queryClient.refetchQueries("dashboard");
        queryClient.invalidateQueries(["user-school"]);
      },
      onError: (err) => {
        message.error(err?.response?.data?.data || "something wrong");
      },
    });
  };
  const handleUpdateWithUpload = (val) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    uploadImage(formData, {
      onSuccess: (res) => {
        updateUser(val, {
          onSuccess: () => {
            setImageFile(null);
            message.success("success update profile");
            queryClient.refetchQueries("cheking-progress");
            queryClient.refetchQueries("list-instructors");
            queryClient.refetchQueries("dashboard");
            queryClient.refetchQueries("user-school");
          },
        });
      },
      onError: (err) => {
        message.error(err?.response?.data?.data || "something wrong");
      },
    });
  };

  // handle update user for info and social media
  const handleUpdate = (val) => {
    delete val.avatar;
    if (imageFile) {
      handleUpdateWithUpload(val);
    } else {
      handleUpdateNoUpload(val);
    }
  };

  const handleSocailMedia = (val) => {
    updateUser(
      { ...val, social_onboard: "done" },
      {
        onSuccess: () => {
          message.success("success update user");
          queryClient.refetchQueries("cheking-progress");
          queryClient.refetchQueries("user-school");
          queryClient.refetchQueries("list-instructors");
        },
        onError: (err) => {
          message.error(err?.response?.data?.data || "something wrong");
        },
      }
    );
  };

  useEffect(() => {
    if (data?.data?.data?.avatar)
      setImageUrl(`${data?.data?.data?.avatar}?${Date.now()}`);
  }, [data?.data?.data?.avatar]);

  return {
    user: {
      data: data?.data?.data,
      loading: isLoading,
      avatar: { imageUrl, handleImageChange },
      action: {
        update: handleUpdate,
        updateSocial: handleSocailMedia,
        loading: loadingUpdateUSer,
      },
    },
  };
};

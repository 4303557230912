import React from 'react'
import space from '../../../assets/images/space.png'
import astro from '../../../assets/images/astro.png'
import rocket from '../../../assets/images/rocket.png'

function NotFound() {
  return (
    <div className="w-full h-screen bg-gray-200 relative">
      <div className="flex justify-center pt-20 pb-10">
        <img src={space} alt="planet" className=" h-32 " />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <div className="text-7xl text-gray-700 -mt-16">404</div>
        <div className="text-gray-700 tracking-wide text-2xl">
          404 It looks like this page got lost in space
        </div>
      </div>
      <img
        src={astro}
        alt="astro"
        className=" h-44 absolute top-[65%] left-[20%] transform -translate-x-1/4 -translate-y-1/2 "
      />
      <img
        src={rocket}
        alt="rocket"
        className=" h-44 absolute top-[50%] right-[15%] transform -translate-x-1/2 -translate-y-1/2 "
      />
    </div>
  )
}

export default NotFound

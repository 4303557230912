import React from "react";

interface Props {
  leftBox?: React.ReactNode | React.ReactElement;
  rightBox?: React.ReactNode | React.ReactElement;
  className?: string;
  showError?: boolean;
  isError?: boolean;
  errorMessages?: string[];
}

export const BasicTwoBoxContainer: React.FC<Props> = ({
  leftBox,
  rightBox,
  className,
  showError,
  isError,
  errorMessages,
}) => {
  return (
    <div className={`grid grid-cols-6 gap-6 ${className}`}>
      {showError &&
        isError &&
        errorMessages?.map((message, index) => (
          <div className='col-span-6 text-danger text-center capitalize text-xs'>
            {message}
          </div>
        ))}
      <div className={rightBox ? "col-span-3" : "col-span-6"}>{leftBox}</div>
      <div className={leftBox ? "col-span-3" : "col-span-6"}>{rightBox}</div>
    </div>
  );
};

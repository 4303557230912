import React, {useEffect} from "react";
import {DatePicker, Input, message, Modal, ModalProps, Select, Switch, Tooltip, UploadFile,} from "antd";
import {InfoCircleOutlined, InfoCircleTwoTone, PlusOutlined,} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createWorkshop, updateWorkshop} from "../apis/workshops";
import {getInstructorList} from "../apis/instructors";
import {Controller, useForm} from "react-hook-form";
import dayjs, {Dayjs} from "dayjs";
import {UploadWithFocus} from "../../../common/components/UploadWithFocus";
import {beforeUpload} from "../../../utils/util_funcs";
import {Program, WorkshopMode} from "../types/workshopAndPrograms";

interface FormValues {
    file: File | null;
    title: string;
    trainer_id: string;
    delivery_mode: WorkshopMode;
    livestream_date?: Dayjs | undefined | null;
    release_date?: Dayjs | undefined | null;
    is_replayable?: boolean;
    is_paid?: boolean;
    price?: number;
    replay_price?: number;
}

interface NewWorkshopModalProps extends ModalProps {
    closeModal: () => void;
    data?: Program
}

export const NewWorkshopModal: React.FC<NewWorkshopModalProps> = (props) => {
    const queryClient = useQueryClient();
    const instructorsQuery = useQuery("instructors", () => getInstructorList());

    const {data} = props
    const id = data?.id || ""

    const createWorkshopMutation = useMutation(createWorkshop, {
        onSuccess: () => {
            queryClient.invalidateQueries("workshops");
        },
    });

    const updateWorkshopMutation = useMutation((formData: FormData) => updateWorkshop(id, formData), {
        onSuccess: () => {
            queryClient.invalidateQueries("workshops");
        },
    });

    const {
        control,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm<FormValues>();

    const deliveryMode: WorkshopMode = watch("delivery_mode");
    const is_replayable = watch("is_replayable", true);
    const is_paid = watch("is_paid");
    // const file = watch("file");

    const onSubmit = async (data: FormValues) => {
        message.open({
            content: function () {
                if (id) {
                    return "Updating Workshop"
                }
                return "Creating Workshop"
            }(),
            key: function () {
                if (id) {
                    return "update-workshop"
                }
                return "create-workshop"
            }(),
            type: "loading",
        });
        let updatedData = {...data};
        if (!updatedData.is_paid) {
            updatedData.price = 0
            updatedData.replay_price = 0
        } else if (!updatedData.is_replayable) {
            delete updatedData.replay_price;
            delete updatedData.is_replayable;
        }

        if (updatedData?.delivery_mode === "on-demand") {
            delete updatedData.is_replayable;
            delete updatedData.replay_price;
            delete updatedData.release_date
        }

        const formData = new FormData();
        formData.append("item_type", "workshop");
        for (const [key, value] of Object.entries(updatedData)) {
            if (value === undefined || value === null) continue;
            if (key === "file") {
                // @ts-ignore
                if (value?.uid === "-1" || value?.name === "dummmmy-11093.jpg") {
                    formData.append(key, "");
                    continue;
                } else {
                    // @ts-ignore
                    formData.append("file", value);
                    continue;
                }
            } else if (key === "livestream_date" || key === "release_date") {
                // @ts-ignore
                const date = dayjs(value).unix();
                // @ts-ignore
                formData.append(key, date);
                continue;
            } else {
                // @ts-ignore
                formData.append(key, value);
            }
        }

        if (!data) {
            await createWorkshopMutation.mutateAsync(formData, {
                onSuccess: () => {
                    message.open({
                        content: "Workshop created successfully",
                        key: "create-workshop",
                        type: "success",
                        duration: 2,
                    });
                    reset();
                    props?.closeModal();
                },
                onError: (error) => {
                    message.open({
                        content: "An error occurred while creating the workshop",
                        key: "create-workshop",
                        type: "error",
                        duration: 2,
                    });
                },
            })
        } else {
            await updateWorkshopMutation.mutateAsync(formData, {
                onSuccess: () => {
                    message.open({
                        content: "Workshop update successfully",
                        key: "update-workshop",
                        type: "success",
                        duration: 2,
                    });
                    reset();
                    props?.closeModal();
                },
                onError: (error) => {
                    message.open({
                        content: "An error occurred while updating the workshop",
                        key: "update-workshop",
                        type: "error",
                        duration: 2,
                    });
                },
            })
        }
    };
    useEffect(() => {
        if (data) {
            const dummyFile = new File(["dummy content"], "dummmmy-11093.jpg", {
                type: "image/jpeg",
                lastModified: new Date().getTime(),
            });
            reset({
                title: data.title,
                delivery_mode: data.mode,
                trainer_id: data.trainer_id,
                is_replayable: data.is_replayable,
                is_paid: data.is_paid,
                price: data.price,
                replay_price: data.replay_price,
                release_date: function () {
                    if (data.release_date) return dayjs(data.release_date)
                    return null
                }(),
                file: function () {
                    if (data.cover_image) {
                        return dummyFile
                    }
                    return null
                }(),
            })
            setFileList([
                {"uid": data.id, status: "done", url: `${data.cover_image}?${Date.now()}`, name: ""}
            ])
        } else {
            reset({
                title: undefined,
                delivery_mode: undefined,
                trainer_id: undefined,
                is_replayable: false,
                is_paid: false,
                price: undefined,
                replay_price: undefined,
                release_date: null,
                file: null,
            })
            setFileList([])
        }

    }, [data, reset]);

    const [fileList, setFileList] = React.useState<UploadFile[]>([])
    const customRequest = (file: UploadFile) => {
        setFileList([{...file, name: "banner.jpg"}])
    };

    return (
        <Modal
            title={`${data ? "Edit Workshop" : "New Workshop"}`}
            {...props}
            okButtonProps={{
                loading: createWorkshopMutation.isLoading,
                children: "Create",
                // type: "primary",
                className: "bg-primary",
            }}
            onOk={handleSubmit((data) => {
                onSubmit(data);
            })}
            className="modal-rounded"
            width="647px"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-start justify-center gap-5">
                    <div className="w-[246px] h-[124px] flex-col">
                        <Controller
                            name="file"
                            control={control}
                            rules={{required: "Banner image is required"}}
                            defaultValue={null}
                            render={({field}) => (
                                <UploadWithFocus
                                    {...field}
                                    showUploadList={{
                                        showPreviewIcon: true,
                                        showRemoveIcon: true,
                                    }}
                                    beforeUpload={(file: any) => {
                                        beforeUpload(file).then((filez) => {
                                            if (filez) {
                                                field.onChange(filez);
                                            }
                                        });
                                    }}
                                    fileList={fileList}
                                    onRemove={() => {
                                        setFileList(prev => []);
                                        reset({file: null})
                                        return false
                                    }}
                                    maxCount={1}
                                    customRequest={(e: any) => {
                                        const data: UploadFile = {
                                            uid: e.uid,
                                            name: e.name,
                                            url: URL.createObjectURL(e.file),
                                            status: "done"
                                        }
                                        customRequest(data)
                                    }}
                                    className="full-width-upload"
                                    listType="picture-card"
                                >
                                    {fileList.length < 1 && (
                                        <div className="flex-col items-center justify-center gap-1">
                                            {<PlusOutlined/>}
                                            <div className="text-gray-400">Cover</div>
                                            <div className="text-gray-400">
                                                16:9, 800x450, max 2 MB{" "}
                                            </div>
                                        </div>
                                    )}
                                </UploadWithFocus>
                            )}
                        />
                        {errors.file && (
                            <p className="text-danger w-full my-0">{errors?.file?.message}</p>
                        )}
                    </div>
                    <div className="flex flex-col gap-4 justify-start h-full flex-grow">
                        <div className="">
                            <Controller
                                name="title"
                                control={control}
                                rules={{required: "Title is required"}}
                                render={({field}) => (
                                    <Input.TextArea
                                        {...field}
                                        placeholder="Workshop Title (120 char max)"
                                        className=" !resize-none"
                                        autoSize={{minRows: 3}}
                                    />
                                )}
                            />
                            {errors.title && (
                                <p className="text-danger w-full my-0 ">
                                    {errors?.title?.message}
                                </p>
                            )}
                        </div>
                        <div className="flex gap-4 items-center bg-grey-select">
                            <div className="">Instructor</div>
                            <Controller
                                name="trainer_id"
                                control={control}
                                rules={{required: "Instructor is required"}}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        status={errors.trainer_id ? "error" : ""}
                                        defaultValue={data?.trainer.name}
                                        options={instructorsQuery.data?.data?.data?.map(
                                            (instructor) => ({
                                                label: instructor.display_name,
                                                value: instructor.id,
                                            })
                                        )}
                                        placeholder="select trainer"
                                        loading={instructorsQuery.isLoading}
                                        className="flex-grow"
                                    />
                                )}
                            />
                            {errors.trainer_id && (
                                <p className="text-danger w-full my-0">
                                    {errors?.trainer_id?.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <h4>Delivery Mode</h4>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-start gap-4">
                            <div className="flex items-center gap-2 w-[200px]">
                                Delivery Mode
                                <Tooltip title="There should be something inside this">
                                    <InfoCircleTwoTone/>
                                </Tooltip>
                            </div>
                            <Controller
                                name="delivery_mode"
                                control={control}
                                defaultValue={WorkshopMode.ON_DEMAND}
                                rules={{required: "Delivery Mode is required"}}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        placeholder="select mode"
                                        options={[
                                            {label: "On Demand", value: "on-demand"},
                                            {
                                                label: "Live Stream",
                                                value: "live-stream",
                                                disabled: true,
                                            },
                                            {label: "Pre Recorded", value: "pre-recorded"},
                                        ]}
                                        className="flex-grow"
                                    />
                                )}
                            />
                        </div>
                        <div className="flex items-center justify-start gap-4">
                            {deliveryMode === WorkshopMode.LIVE_STREAM ? (
                                <>
                                    <div className="flex items-center gap-2 w-[200px]">
                                        Livestream Date
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Controller
                                            name="livestream_date"
                                            control={control}
                                            rules={
                                                deliveryMode === WorkshopMode.LIVE_STREAM
                                                    ? {required: "Livestream Date is required"}
                                                    : undefined
                                            }
                                            render={({field}) => (
                                                <DatePicker
                                                    {...field}
                                                    placeholder="Select Date and Time"
                                                    disabledDate={(current) => {
                                                        return current && current < dayjs().startOf("day");
                                                    }}
                                                    showTime={{
                                                        showNow: true,
                                                    }}
                                                    format="YYYY-MM-DD HH:mm"
                                                />
                                            )}
                                        />
                                        {errors.livestream_date && (
                                            <p className="text-danger w-full my-0">
                                                {errors?.livestream_date?.message}
                                            </p>
                                        )}
                                    </div>
                                </>
                            ) : deliveryMode === WorkshopMode.PRE_RECORDED ? (
                                <>
                                    <div className="flex items-center gap-2 w-[200px] ">
                                        Release Date
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Controller
                                            name="release_date"
                                            control={control}
                                            rules={
                                                deliveryMode === WorkshopMode.PRE_RECORDED
                                                    ? {required: "Release Date is required"}
                                                    : undefined
                                            }
                                            render={({field}) => (
                                                <DatePicker
                                                    {...field}
                                                    placeholder="Select Date and Time"
                                                    disabledDate={(current) => {
                                                        return current && current < dayjs().startOf("day");
                                                    }}
                                                    showTime={{
                                                        showNow: true,
                                                    }}
                                                    format="YYYY-MM-DD HH:mm"
                                                />
                                            )}
                                        />
                                        {errors.release_date && (
                                            <p className="text-danger w-full my-0">
                                                {errors?.release_date?.message}
                                            </p>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </div>
                        {deliveryMode && deliveryMode !== WorkshopMode.ON_DEMAND && (
                            <div className=" mt-2">
                                <div className="flex items-center justify-start gap-4">
                                    <div className="flex items-center gap-2 w-[200px]">
                                        Replay Accessibility
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Controller
                                            name="is_replayable"
                                            control={control}
                                            defaultValue={true}
                                            render={({field}) => (
                                                <Switch {...field} className="bg-darkGrey" checked={field.value}/>
                                            )}
                                        />
                                        {/* Based on above switch value */}
                                        {is_replayable
                                            ? "Allow new participant to watch the replay "
                                            : "Replay will not be accessible"}
                                    </div>
                                </div>
                                {is_replayable && (
                                    <div className="flex items-center gap-2">
                                        <InfoCircleOutlined className="text-danger"/>
                                        The replay will be available to new participants for up to
                                        12 months
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    <h4>Payments</h4>
                    <div className="flex items-center gap-2 mt-2">
                        <Controller
                            name="is_paid"
                            control={control}
                            defaultValue={false}
                            render={({field}) => (
                                <Switch
                                    {...field}
                                    className="bg-darkGrey"
                                    checked={field.value}
                                />
                            )}
                        />
                        Paid Workshop
                    </div>

                    <div className={" mt-4"}>
                        <div className="flex items-center gap-4">
                            <div className="w-[200px]">Participants will be charged</div>
                            <Controller
                                name="price"
                                control={control}
                                rules={{
                                    required: is_paid ? "This field is required" : false,
                                    min: is_paid ? {value: 15, message: "Must be greater than or equal 15"} : undefined
                                }}
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        type="number"
                                        disabled={!is_paid}
                                        className="w-fit"
                                        addonBefore="SGD"
                                        placeholder="min $15"
                                    />
                                )}
                            />

                        </div>
                        {errors.price && (
                            <p className="text-danger w-full my-0">
                                {errors?.price?.message}
                            </p>
                        )}
                    </div>

                    {deliveryMode &&
                        deliveryMode !== WorkshopMode.ON_DEMAND &&
                        is_replayable && (
                            <div className={"mt-4"}>
                                <div className="flex items-center gap-4">
                                    <div className=" w-[200px]">
                                        Replay price <br></br>(new attendees)
                                    </div>
                                    <Controller
                                        name="replay_price"
                                        control={control}
                                        rules={{
                                            required: is_paid ? "This field is required" : false,
                                            min: is_paid ? {
                                                value: 15,
                                                message: "Must be greater than or equal 15"
                                            } : undefined
                                        }}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                type="number"
                                                disabled={!is_paid}
                                                className="w-fit"
                                                addonBefore="SGD"
                                                placeholder="min $15"
                                            />
                                        )}
                                    />
                                </div>
                                {errors.replay_price && (
                                    <p className="text-danger w-full my-0">
                                        {errors?.replay_price?.message}
                                    </p>
                                )}
                            </div>
                        )}
                </div>
            </form>
        </Modal>
    );
};

import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Breadcrumbs } from "../../../common/components/Breadcumbs";
import InstructorList from "../components/InstructorList";
import { useInstructor, useInstructorCourse } from "../hook/useInstructor";
import { Button, Drawer, Modal, Select, Typography } from "antd";
import InstructorForm from "../components/InstructorForm";
import SkillAssignment from "../components/SkillAssignment";
import {HomeIcon} from "../../../assets/icon";

const Instructor = () => {
  const {
    list,
    params,
    stage,
    detail,
    edit,
    add,
    remove,
    delegateTrainer,
    setDelgateTrainer,
  } = useInstructor();
  const { data, isLoading } = useInstructorCourse(remove?.user?.id);
  const items = [
      {
          title:
              <div className={"mt-1"}>
                  <HomeIcon />
              </div>,
          path: "/",
      },
      {
          title: "Admin",
      },
    {
      path: "/trainer-admin/instructors",
      title: "Instructor Management",
    },
  ];
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop capitalize">
          Instructor Management
        </h1>
      </div>
      <div className="mx-5 px-5 py-5 my-5  bg-white">
        <InstructorList
          data={list}
          params={params}
          stage={stage}
          edit={detail?.action}
          remove={remove}
        />
      </div>
      <Drawer
        title="New Instructor"
        placement="right"
        closable={false}
        onClose={() => {
          stage.setIsOpenNew(false);
        }}
        open={stage.isOpenNew}
        width={"736px"}
        extra={
          <CloseOutlined
            className=" cursor-pointer"
            onClick={() => {
              stage.setIsOpenNew(false);
            }}
          />
        }
      >
        <InstructorForm handle={add} isOpen={stage.isOpenNew} />
      </Drawer>
      <Drawer
        title="Edit Instructor"
        placement="right"
        closable={false}
        onClose={() => {
          stage.setIsOpenEdit(false);
        }}
        open={stage.isOpenEdit}
        width={"736px"}
        extra={
          <CloseOutlined
            className=" cursor-pointer"
            onClick={() => {
              stage.setIsOpenEdit(false);
            }}
          />
        }
      >
        <InstructorForm
          isEdit
          data={detail}
          isDisabled={stage.isDisabled}
          handle={edit}
        />
      </Drawer>
      <Modal
        open={stage.isOpenDelete}
        centered
        footer={null}
        width={586}
        className="p-5"
        closable={false}
        onCancel={remove.handleClose}
      >
        <Typography.Paragraph className=" font-semibold">
          Are you sure you want to remove the instructor?
        </Typography.Paragraph>
        <Typography.Paragraph>
          If you want to remove the instructor’s access to the platform, select
          whom to re-assign the courses that belong to the current instructor.
        </Typography.Paragraph>
        <div className="mb-7">
          <SkillAssignment
            user={remove.user}
            data={data}
            isLoading={isLoading}
          />
        </div>
        <div>
          <Typography.Paragraph>Re-assign courses to:</Typography.Paragraph>
          <Select
            filterOption={false}
            value={delegateTrainer}
            options={list?.data
              ?.filter((item) => item.id !== remove?.user?.id)
              .map((item) => ({
                value: item.id,
                label: `${item.first_name} ${item.last_name}`,
              }))}
            onSelect={(a) => setDelgateTrainer(a)}
            placeholder={"select"}
            className="w-full"
            disabled={!data?.data?.data?.list?.length > 0}
          />
        </div>
        <div className=" mt-5 text-end">
          <Button
            danger
            loading={remove.isLoading}
            onClick={remove.handleDelete}
            disabled={data?.data?.data?.list?.length > 0 && !delegateTrainer}
          >
            Delete instructor
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Instructor;

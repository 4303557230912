import { Divider, Image, QRCode, QRCodeProps, Skeleton } from 'antd'
import React from 'react'
import { CertifBorder, SkilledinGreen } from '../../../assets/images'
import { useSchool } from '../../onboarding/hooks/useSchool'
import dayjs from 'dayjs'
import { useProgramFetchDetail } from '../hook/useProgram.ts'
import { useParams } from 'react-router-dom'
import { initialProgramStoreState } from '../store/programStore.ts'

interface CertificatePreviewContentProps {

}

const CertificatePreviewContent: React.FC<CertificatePreviewContentProps> = () => {
  const { id } = useParams()
  useProgramFetchDetail(id!)
  const { certificateData, isLoadingCertificateData } = useSchool()
  const { programDetailData, loadingDetailPorgram } = initialProgramStoreState()

  return (
    <div className='w-full'>
      <div className='!min-w-[1000px] !max-w-[1300px] mx-16 bg-white rounded-xl p-3 border shadow-custom-sm relative'>
        <div className='flex items-center justify-between'>
          <Image preview={false} src={CertifBorder} alt="border" height={120} className='' />
          <Image preview={false} src={CertifBorder} alt="border" height={120} className='rotate-90' />
        </div>

        <div className='flex items-center justify-center -mt-14'>
          <div className='flex items-center flex-col space-y-7'>
            {isLoadingCertificateData && loadingDetailPorgram ? <Skeleton.Image active /> :
              <Image preview={false} src={certificateData?.data?.data?.school?.logo} alt="border" height={100} className='' />
            }

            <div className='text-center space-y-1'>
              <h2 className='uppercase text-[#966F02] font-sen font-thin' style={{ letterSpacing: "0.4rem" }}>certificate of completion</h2>
              <p>This is to certify that</p>
            </div>
            <div className='text-center space-y-1'>
              <p className='text-4xl font-sofia'>Learner Name</p>
              <p>has successfully completed</p>
            </div>
            {loadingDetailPorgram && loadingDetailPorgram ? <Skeleton.Button active block />
              :
              <h1 className='uppercase text-[40px] font-bold font-rammetto'>{programDetailData?.title}</h1>
            }
            <div className='text-center space-y-3 text-base'>
              <p>A program delivered by {isLoadingCertificateData && loadingDetailPorgram ? <Skeleton.Button active size='small' />
                : certificateData?.data?.data?.school?.name} and offered through Skilledin Green platform</p>
              <p>Skills and modules: {' '}
                {loadingDetailPorgram && loadingDetailPorgram ? <Skeleton.Button active size='small' />
                  : programDetailData?.skills?.map((skill, index) => (
                    <span key={skill.id} className="!text-backdrop !bg-white">
                      {skill.name}
                      {index !== programDetailData.skills.length - 1 && ', '}
                    </span>
                  ))}
              </p>
            </div>
            <div className='grid grid-cols-12 w-full gap-x-2 !mt-10'>
              <div className='col-span-4'>
                <div className='flex items-center justify-center'>
                  {isLoadingCertificateData && loadingDetailPorgram ? <Skeleton.Image active /> :
                    <Image preview={false} src={certificateData?.data?.data?.admin_signature} alt="border" height={90} className=' ' />
                  }
                </div>
                <Divider className='border-b-2 border-[#9CAFB9] -mt-1 mb-2' />
                <p className='font-bold  text-base'>{certificateData?.data?.data?.owner_certificate?.display_name}</p>
                <p className=''>{certificateData?.data?.data?.owner_certificate?.job_title} at {certificateData?.data?.data?.school?.name}</p>
                <p className=' text-base'>{dayjs(Date.now()).format('DD MMM YYYY')}</p>
              </div>
              <div className='col-span-4'>
                <div className='justify-center flex'>
                  <Image preview={false} src={SkilledinGreen} alt="border" height={90} className='' />
                </div>
                <p className='text-center mt-6 text-base'>Skilledin Green</p>
              </div>
              <div className='col-span-4'>
                <div className='justify-center flex'>
                  <QRCode
                    style={{ marginBottom: 16 }}
                    errorLevel={'H' as QRCodeProps['errorLevel']}
                    value="https:green.skilledin.io"
                    bordered={false}
                    size={100}
                  />
                </div>
                <p className='text-center text-base'>Online version</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <Image preview={false} src={CertifBorder} alt="border" height={120} className='-rotate-90' />
          <Image preview={false} src={CertifBorder} alt="border" height={120} className='rotate-180' />
        </div>
      </div>
    </div>
  )
}

export default CertificatePreviewContent

import React, { useEffect } from "react";
import { passwordResetRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleResetPasswordRedirect = () => {
    instance
      .loginRedirect(passwordResetRequest)
      .then(() => navigate("/"))

      .catch((error) => console.log(error));
  };
  useEffect(() => {
    handleResetPasswordRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>Redirecting to Password Reset...</div>
    </div>
  );
};

export default ResetPassword;

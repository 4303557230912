import React from "react"
import InputSearch from "../../../common/components/InputSearch"
import InputDropdown from "../components/InputDropdown"
import TableSkillAndCourses from "../components/TableSkillAndCourses"
import { useFetchSkillAndCourses } from "../hook/useFetchSkillAndCourses"
import { useInstructor } from "../hook/useInstructor"


const GreenSkillContent = () => {
  const {
    listSkillAndCourses,
    isLoading, // handlePageChange,
    // handleLimitChange,
    levelName,
    handleSetFilterByLevel,
    handleSearch,
    search,
    handleChangeFilterByTrainer,
    trainerName
  } = useFetchSkillAndCourses()

  const { list } = useInstructor()


  const itemTrainers = list?.data?.map((item) => (
    {
      name: `${item?.first_name} ${item?.last_name}`,
      id: item?.id,
    }
  ))

  const itemsPublishLevel = [
    {
      name: 'Level 1',
      id: '1'
    },
    {
      name: 'Level 2',
      id: '2'
    },
    {
      name: 'Level 3',
      id: '3'
    },
    {
      name: 'Level 4',
      id: '4'
    },
    {
      name: 'Level 5',
      id: '5'
    }
  ]

  return (
    <div>
      <div className="py-5 ">
        <div className={`my-5`}>
          <p className="mb-3">
            Contact <a href={`mailto:info@skilledin.io`} className="text-secondary cursor-pointer hover:border-b">Skilledin Green</a> if you want to teach Green skills from Jobs-Skills framework
          </p>

          <div className="space-y-3">
            <div className="grid grid-cols-12 gap-8">
              <div className="col-span-8">
                <InputSearch
                  placeholder={"Search"}
                  value={search}
                  handleSearchChange={handleSearch}
                />
              </div>
              <div className="col-span-2">
                <InputDropdown
                  defaultValue={'Published Level'}
                  data={itemsPublishLevel}
                  handleChangeFilter={handleSetFilterByLevel}
                  selected={levelName ? levelName : 'All Publish Level'}
                />
              </div>
              <div className="col-span-2">
                <InputDropdown
                  defaultValue={'Trainers'}
                  data={itemTrainers}
                  handleChangeFilter={handleChangeFilterByTrainer}
                  selected={trainerName ? trainerName : 'All Trainer'}
                />
              </div>
            </div>
            <div>
              <TableSkillAndCourses isLoading={isLoading} listSkillAndCourses={listSkillAndCourses} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GreenSkillContent

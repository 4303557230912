import { TabNames } from "./../types/common";
import { create } from "zustand";

type State = {
  skill: {
    id: string | null;
    name: string;
    description: string;
  };
  level: {
    levelNum: number | null;
    name: string;
    id: string | null;
  };
  checkErrors: null | TabNames;
  errorsInTabs: {
    [key in TabNames]: boolean | null;
  };
  initialize: (skill: State["skill"], level: State["level"]) => void;
  initializeAsync: (skill_id: string, level_id: string) => void;
  setCheckErrors: (value: State["checkErrors"]) => void;
  setErrorInTab: (tabName: TabNames, value: boolean | null) => void;
  resetErrorsInTabs: () => void;
  reset: () => void;
};

export const useCourseEditorStore = create<State>((set) => ({
  skill: {
    id: null,
    name: "",
    description: "",
  },
  level: {
    levelNum: null,
    id: null,
    name: "",
  },
  checkErrors: null,
  errorsInTabs: {
    overview: null,
    module_1: null,
    module_2: null,
    module_3: null,
    module_4: null,
    assessment: null,
  },
  initialize: (skill, level) => {
    set({ skill, level });
  },
  initializeAsync: async (skill_id: string, level_id: string) => {
    set({
      skill: { id: null, name: "", description: "" },
      level: { levelNum: null, name: "", id: null },
    });
  },
  setCheckErrors: (value) => {
    set({ checkErrors: value });
  },
  setErrorInTab: (tabName: TabNames, value: boolean | null) => {
    set((state) => ({
      errorsInTabs: {
        ...state.errorsInTabs,
        [tabName]: value,
      },
    }));
  },
  resetErrorsInTabs: () => {
    set({
      errorsInTabs: {
        overview: null,
        module_1: null,
        module_2: null,
        module_3: null,
        module_4: null,
        assessment: null,
      },
    });
  },
  reset: () => {
    set({
      skill: { id: null, name: "", description: "" },
      level: { levelNum: null, name: "", id: null },
    });
  },
}));

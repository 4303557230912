/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { PaginatedResponse } from "../../../common/types/ApiResponse";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RcFile } from "antd/es/upload";
import { BeforeUploadFileType } from "rc-upload/lib/interface";
import { message } from "antd";

export type Workshop = {
  id: string;
  title: string;
  release_date?: string;
  published_at?: string;
  mode: string;
  status: string;
  description: string;
  material?: string;
  documents?: { name: string; url: string; created_at: string }[];
  media?: {
    status: string;
    dash: string;
    encoding_id: string;
    blob_path: string;
    duration: number;
  };
};

export const useWorkshopList = () => {
  const [dataList, setDataList] = useState<Workshop[]>([]);
  const [params, setParams] = useState({
    page: 1,
    title: "",
    limit: 10,
  });
  const [total, setTotal] = useState<number>(0);

  const { data, isLoading } = useQuery(["workshop-trainer-list", params], () =>
    httpRequest.get<AxiosResponse<PaginatedResponse<Workshop>>>(
      "/school/my-courses/workshops-and-programs",
      { params }
    )
  );

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data?.data?.list || []);
        setTotal(data?.data.data.total_data || 0);
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])]);
        setTotal(data?.data.data.total_data || 0);
      }
    }
  }, [data?.data.data.list]);

  return {
    dataList,
    isLoading,
    total,
    params,
    setParams,
  };
};

export const useWorkshop = () => {
  const [pdfTemp, setPdfTemp] = useState<
    { name: string; url: string; created_at: string }[] | undefined
  >(undefined);
  const { id } = useParams();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    description: yup
      .string()
      .required("Description is required")
      .notOneOf(["<p><br></p>"], "Description is required"),
    video: yup.string().required("Video is required"),
  });

  const { data, isLoading } = useQuery(["workshop-detail", id], () =>
    httpRequest.get<AxiosResponse<Workshop>>(
      `/school/my-courses/workshops-and-programs/${id}`
    )
  );

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      material: "",
      video: "",
    },
  });

  // action files
  const beforeFileUpload = (file: RcFile) => {
    const isPdf = file?.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      return false;
    }
    return true;
  };

  const { mutateAsync: mutateFileUpload, isLoading: isLoadingFileUpload } =
    useMutation({
      mutationKey: "file-upload-workshop",
      mutationFn: async (data: FormData) => {
        return httpRequest.post(
          `/school/my-courses/workshops-and-programs/${id}/docs`,
          data
        );
      },
    });

  const handleFileUpload = async (
    file: Exclude<BeforeUploadFileType, File | boolean> | RcFile
  ) => {
    message.open({
      key: "upload-file-workshop",
      content: "Uploading file...",
      duration: 0,
      type: "loading",
    });
    const form = new FormData();
    form.append("file", file);
    try {
      const res = await mutateFileUpload(form);
      if (res) {
        message.open({
          key: "upload-file-workshop",
          content: "Success upload",
          type: "success",
        });
        if (res?.data?.data?.documents?.length > 0) {
          setPdfTemp(res.data.data.documents);
        }
      }
    } catch (error: any) {
      message.open({
        key: "upload-file-workshop",
        content: error?.response?.data?.data || "Failed upload",
        type: "error",
      });
    }
  };

  const { mutateAsync: mutateFileDelete, isLoading: isLoadingFileDelete } =
    useMutation({
      mutationKey: "file-delete-workshop",
      mutationFn: async (data: string) => {
        return httpRequest.delete(
          `/school/my-courses/workshops-and-programs/${id}/docs/${data}`
        );
      },
    });

  const handleFileDelete = async (name: string) => {
    message.open({
      key: "delete-file-workshop",
      content: "Deleting file...",
      duration: 0,
      type: "loading",
    });

    try {
      const res = await mutateFileDelete(name);
      if (res) {
        message.open({
          key: "delete-file-workshop",
          content: "Success delete",
          type: "success",
        });
        setPdfTemp(res.data.data.documents);
      }
    } catch (error: any) {
      message.open({
        key: "delete-file-workshop",
        content: error?.response?.data?.data || "Failed delete",
        type: "error",
      });
    }
  };

  // action video
  const { mutateAsync: mutateVideoDelete, isLoading: isLoadingVideoDelete } =
    useMutation({
      mutationKey: "file-delete-workshop",
      mutationFn: async (data: string) => {
        return httpRequest.delete(
          `/school/my-courses/workshops-and-programs/${id}/vod/${data}`
        );
      },
    });

  const handleVideoDelete = async (name: string) => {
    message.open({
      key: "delete-video-workshop",
      content: "Deleting video...",
      duration: 0,
      type: "loading",
    });

    try {
      const res = await mutateVideoDelete(name);
      if (res) {
        message.open({
          key: "delete-video-workshop",
          content: "Delete success",
          type: "success",
        });
        if (data?.data?.data) {
          data.data.data.media = undefined;
        }
        setValue("video", "");
        trigger("video");
      }
    } catch (error: any) {
      message.open({
        key: "delete-video-workshop",
        content: error?.response?.data?.data || "Delete failded",
        type: "error",
      });
    }
  };

  const { mutateAsync: mutateVideoEncode, isLoading: isEncodeLoading } =
    useMutation({
      mutationKey: "video-encode-workshop",
      mutationFn: async (data: string) => {
        return httpRequest.post(
          `/school/my-courses/workshops-and-programs/${id}/vod/${data}`
        );
      },
    });

  const handleVideoEncode = async (name: string) => {
    try {
      const res = await mutateVideoEncode(name);
      if (res) {
        const media = res.data.data.media;
        if (data?.data?.data) {
          data.data.data.media = media;
        }
        setValue("video", name || "encoding");
        trigger("video");
      }
    } catch (error: any) {
      message.open({
        key: "encode-video-workshop",
        content: error?.response?.data?.data || "Encode failded",
        type: "error",
      });
    }
  };

  // action publish and save
  const { mutateAsync: mutateSave, isLoading: isSaveLoading } = useMutation({
    mutationKey: "workshop-save",
    mutationFn: async (data: any) => {
      return httpRequest.patch(
        `/school/my-courses/workshops-and-programs/${id}`,
        data
      );
    },
  });

  const handleSave = async () => {
    try {
      message.open({
        key: "save-workshop",
        content: "Saving...",
        duration: 0,
        type: "loading",
      });
      const data = getValues();
      const form = {
        description: (function () {
          if (data.description === "<p><br></p>") {
            return "";
          }
          return data.description;
        })(),
        material: (function () {
          if (data.material === "<p><br></p>") {
            return "";
          }
          return data.material;
        })(),
      };
      await mutateSave(form);
      message.open({
        key: "save-workshop",
        content: "Success!",
        type: "success",
      });
    } catch (error) {
      message.error({
        key: "save-workshop",
        content: JSON.stringify(error?.response?.data?.data),
        type: "error",
      });
    }
  };

  const { mutateAsync: mutatePublish, isLoading: isPublishLoading } =
    useMutation({
      mutationKey: "workshop-publish",
      mutationFn: async () => {
        return httpRequest.post(
          `/school/my-courses/workshops-and-programs/${id}/publish`
        );
      },
    });

  const handlePublish = async (data: any) => {
    try {
      if (errors?.description) {
        message.error("Please fill description fields");
        return;
      }
      if (errors?.video) {
        message.error("Please upload your video");
        return;
      }
      message.open({
        key: "publish-workshop",
        content: "Publishing...",
        duration: 0,
        type: "loading",
      });
      const form = {
        description: (function () {
          if (data.description === "<p><br></p>") {
            return "";
          }
          return data.description;
        })(),
        material: (function () {
          if (data.material === "<p><br></p>") {
            return "";
          }
          return data.material;
        })(),
      };
      await mutateSave(form);
      await mutatePublish();
      message
        .open({
          key: "publish-workshop",
          content: "Success!",
          type: "success",
        })
        .then(() => {
          navigate(-1);
        });
    } catch (error) {
      message.open({
        key: "publish-workshop",
        content: JSON.stringify(error?.response?.data?.data),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (data?.data?.data) {
      reset(
        {
          description: data?.data?.data?.description,
          material: data?.data?.data?.material,
        },
        { keepDirty: true }
      );
      if (data?.data?.data?.documents) {
        setPdfTemp(data.data.data.documents);
      }
      if (data?.data?.data?.media) {
        setValue("video", data?.data?.data?.media.status);
      }
      trigger();
    }
  }, [data?.data?.data]);

  return {
    data: {
      data: data?.data?.data,
      isLoading,
    },
    form: {
      handleSubmit,
      control,
      errors,
      isDirty,
    },
    fileMaterial: {
      handleFileUpload,
      beforeFileUpload,
      pdfTemp,
      isLoadingUpload: isLoadingFileUpload,
      handleFileDelete,
      isLoadingDelete: isLoadingFileDelete,
      handleVideoDelete,
      isLoadingVideoDelete,
      handleVideoEncode,
      isEncodeLoading,
    },
    actionSave: {
      handleSave,
      isSaveLoading,
      handlePublish,
      isPublishLoading,
    },
  };
};

import { Button, Divider, Skeleton } from 'antd'
import React from 'react'
import { BookIcon, LampIcon, StarIcon, TeamIcon } from '../../../assets/icon'
import { useNavigate } from 'react-router-dom'
import { useSchoolDashboard } from '../hook/useSchoolDashboard.ts'
import { useSchool } from '../../onboarding/hooks/useSchool.js'
import { ROUTES } from '../pages/SchoolDashboard.tsx'

interface OverviewContentProps {

}

const OverviewContent: React.FC<OverviewContentProps> = () => {
  const navigate = useNavigate()

  const {
    schoolDashboardStat,
    schoolDashboardStatLoading,
  } = useSchoolDashboard()

  const {
    certificateData,
  } = useSchool()

  return (
    <div className='my-12 space-y-6'>
      <div className='shadow-custom-sm bg-white p-3 rounded-lg border'>
        <div className='flex items-center justify-between space-y-4'>
          <h3>Certificates of Completion</h3>
          <Button type="primary" className='bg-primary' onClick={() => navigate("/trainer-admin/school-dashboard/overview/certif-editor")}>Settings</Button>
        </div>
        {certificateData?.data?.data?.owner_certificate && certificateData?.data?.data?.owner_certificate_id ?
          <p className='text-green-500'>Active</p> :
          <p className='text-red-500'>Not Configured</p>
        }
        <div className='flex items-center gap-x-2 leading-9'>
          Applied to:
          <p>
            {schoolDashboardStat?.total_courses_issuing_certificate || "0"} Green Skills
          </p>
          <Divider type='vertical' />
          <p>
            {schoolDashboardStat?.total_programs_issuing_certificate || "0"} Programs
          </p>
        </div>
      </div>


      <div className='grid grid-cols-10 gap-x-7'>
        <div className='col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16'
          onClick={(e) => navigate('/students')}
        >
          <TeamIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? <Skeleton.Button size='small' className='!w-full' active /> :
              <h2 className='text-2xl'>{schoolDashboardStat?.total_learners}</h2>
            }
            <p>Students</p>
          </div>
        </div>
        <div className='col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16'
          onClick={(e) => navigate('/trainer-admin/instructors')}>
          <TeamIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? <Skeleton.Button size='small' className='!w-full' active /> :
              <h2 className='text-2xl'>{schoolDashboardStat?.total_trainers}</h2>
            }
            <p>Instructors</p>
          </div>
        </div>
        <div className='col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16'
          onClick={(e) => navigate(ROUTES.GREEN_SKILLS)}
        >
          <LampIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? <Skeleton.Button size='small' className='!w-full' active /> :
              <h2 className='text-2xl'>{schoolDashboardStat?.total_courses}</h2>
            }
            <p>Green Skills</p>
          </div>
        </div>
        <div className='col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16'
          onClick={(e) => navigate(ROUTES.WORKSHOPS)}>
          <StarIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? <Skeleton.Button size='small' className='!w-full' active /> :
              <h2 className='text-2xl'>{schoolDashboardStat?.total_workshops}</h2>
            }
            <p>Workshops</p>
          </div>
        </div>
        <div className='col-span-2 border rounded-lg flex gap-x-2 px-3 py-1 cursor-pointer hover:border-b-8 hover:border-primary-bg transition-all h-16'
          onClick={(e) => navigate(ROUTES.PROGRAMS)}
        >
          <BookIcon className="w-6 h-6" />
          <div>
            {schoolDashboardStatLoading ? <Skeleton.Button size='small' className='!w-full' active /> :
              <h2 className='text-2xl'>{schoolDashboardStat?.total_programs}</h2>
            }
            <p>Programs</p>
          </div>
        </div>

      </div>

    </div>
  )
}

export default OverviewContent

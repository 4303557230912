import { Input } from 'antd'
import React from 'react'
import { SearchIcon2 } from './Icons'

const InputSearch = ({ value, handleSearchChange, placeholder }) => {
  return (
    <Input
      addonBefore={<SearchIcon2 width={20} height={20} />}
      placeholder={placeholder}
      allowClear
      className='col-span-2'
      style={{ padding: '0px 3px' }}
      defaultValue={value}
      onChange={handleSearchChange}
    />
  )
}

export default InputSearch

import { Route, Routes } from "react-router-dom"
import NotFound from "../auth/page/NotFound"
import DetailSkillsAndCourses from "./pages/DetailSkillsAndCourses"
import Sales from "./pages/Sales"
import SchoolProfile from "./pages/SchoolProfile"
import Instructor from "./pages/Instructor"
import Payouts from "./pages/Payouts"
import Certificate from "./pages/Certificate"
import ListCoursesAndCourses from "./pages/ListSkillsAndCourses"
import ProgramsAndWorkshops from "./pages/ProgramsAndWorkshops.tsx"
import SchoolDashboard from "./pages/SchoolDashboard.tsx"
import CertificateEditor from "./pages/CertificateEditor.tsx"
import ProgramDetail from "./pages/ProgramDetail.tsx"
import PreviewCertificate from "./pages/PreviewCertificate.tsx"

const Index = () => {
  return (
    <Routes>
      {/* SKILLS AND COURSES */}
      <Route path="skills-courses" element={<ListCoursesAndCourses />} />
      <Route path="skills-courses/:id" element={<DetailSkillsAndCourses />} />
      <Route path="skills-courses/certificate" element={<Certificate />} />
      <Route path="/school-profile" element={<SchoolProfile />} />
      <Route path="/instructors" element={<Instructor />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/payouts" element={<Payouts />} />
      <Route
        path="/programs-and-workshops"
        element={<ProgramsAndWorkshops />}
      />

      {/* PART OF SCHOOL DASHBOARD */}
      <Route
        path="/school-dashboard/overview"
        element={<SchoolDashboard />}
      />
      <Route
        path="/school-dashboard/overview/certif-editor"
        element={<CertificateEditor />}
      />
      <Route
        path="/school-dashboard/green-skills"
        element={<SchoolDashboard />}
      />
      <Route
        path="/school-dashboard/workshop"
        element={<SchoolDashboard />}
      />
      <Route
        path="/school-dashboard/programs"
        element={<SchoolDashboard />}
      />
      <Route
        path="/school-dashboard/programs/:id"
        element={<ProgramDetail />}
      />
      <Route
        path="/school-dashboard/programs/:id/certificate"
        element={<PreviewCertificate />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index

import React from "react";
import { openInNewTab } from "../../utils/util_funcs.ts";
import { Layout } from "antd";

export function CustomFooter({ isLogged }) {
  // const footerBgColor = isLogged ? "bg-[#F5F5F5] py-6" : "bg-backdrop";
  const footerTextColor = isLogged ? "text-backdrop" : "text-[#F5F5F5]";

  return (
    <Layout.Footer
      className={` w-full px-5 py-3 flex items-center justify-between bg-[#E2EBFA]`}>
      <div className={`capitalize ${footerTextColor}`}>
        COPYRIGHT © 2023 SKILLEDIN PTE. LTD. ALL RIGHTS RESERVED.
      </div>
      <div className='ml-6 flex items-center gap-5'>
        <>
          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap hover:!text-black`}
            onClick={() =>
              openInNewTab("https://skilledin.io/privacy-policy/")
            }>
            Privacy Policy
          </p>
          <small className={`${footerTextColor}`}>|</small>

          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap hover:!text-black`}
            onClick={() => openInNewTab("https://skilledin.io/terms-of-use/")}>
            Terms of use
          </p>
          <small className={`${footerTextColor}`}>|</small>

          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap hover:!text-black `}
            onClick={() =>
              openInNewTab("https://skilledin.io/cookie-policy-eu/")
            }>
            Cookie Policy (EU)
          </p>
        </>
      </div>
    </Layout.Footer>
  );
}

import React from "react"
import { Layout, Menu } from "antd"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import {
  DashboardOutlined,
  TeamOutlined,
  BookOutlined,
  SettingOutlined,
  // MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons"
import { useAdmin } from "../hooks/useAdmin"
import { useHeader } from "../hooks/useCustomHeader"

const { Sider } = Layout

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}
// eslint-disable-next-line react/prop-types
export default function CustomSiderbar({
  openSidebar,
  // handleOpenSidebar
}) {
  const isAdmin = useAdmin()
  const { handleLogoutRedirect } = useHeader()

  const itemsTrainer = [
    getItem(
      "Dashboard",
      "/dashboard",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/dashboard").pathname,
          end: true,
        })
          ? " text-primary "
          : ""
          }`}
        to="/dashboard">
        <DashboardOutlined
          className={`${useMatch({
            path: useResolvedPath("/dashboard").pathname,
            end: true,
          })
            ? " text-primary "
            : ""
            }`}
        />
      </Link>
    ),
    getItem(
      "My Courses",
      "/courses",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/courses").pathname,
          end: true,
        })
          ? " text-primary"
          : ""
          }`}
        to="/courses">
        <BookOutlined
          className={`${useMatch({
            path: useResolvedPath("/courses").pathname,
            end: true,
          })
            ? " text-primary"
            : ""
            }`}
        />
      </Link>
    ),
    getItem(
      "Students",
      "/students",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/students").pathname,
          end: true,
        })
          ? " text-primary"
          : ""
          }`}
        to="/students">
        <TeamOutlined
          className={`${useMatch({
            path: useResolvedPath("/students").pathname,
            end: true,
          })
            ? " text-primary"
            : ""
            }`}
        />
      </Link>
    ),
  ]

  const itemsAdmin = [
    getItem(
      "Dashboard",
      "/dashboard",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/dashboard").pathname,
          end: true,
        })
          ? " text-primary "
          : ""
          }`}
        to="/dashboard">
        <DashboardOutlined
          className={`${useMatch({
            path: useResolvedPath("/dashboard").pathname,
            end: true,
          })
            ? " text-primary "
            : ""
            }`}
        />
      </Link>
    ),
    getItem(
      "My Courses",
      "/courses",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/courses").pathname,
          end: true,
        })
          ? " text-primary"
          : ""
          }`}
        to="/courses">
        <BookOutlined
          className={`${useMatch({
            path: useResolvedPath("/courses").pathname,
            end: true,
          })
            ? " text-primary"
            : ""
            }`}
        />
      </Link>
    ),
    getItem(
      "Students",
      "/students",
      <Link
        className={`text-md font-semibold text-backdrop ${useMatch({
          path: useResolvedPath("/students").pathname,
          end: true,
        })
          ? " text-primary"
          : ""
          }`}
        to="/students">
        <TeamOutlined
          className={`${useMatch({
            path: useResolvedPath("/students").pathname,
            end: true,
          })
            ? " text-primary"
            : ""
            }`}
        />
      </Link>
    ),
    getItem(
      "Admin",
      "/admin",
      <SettingOutlined
        className={`${useMatch({
          path: useResolvedPath("/admin").pathname,
          end: true,
        })
          ? " text-primary"
          : ""
          }`}
      />,
      [
        getItem(
          <Link
            className={`text-md  text-backdrop ${""}`}
            to={"/trainer-admin/school-profile"}>
            School Profile
          </Link>,
          "/trainer-admin/school-profile"
        ),
        getItem(
          <Link
            title="Instructors"
            className={`text-md  text-backdrop `}
            to={"/trainer-admin/instructors"}>
            Instructors
          </Link>,
          "/trainer-admin/instructors"
        ),
        // getItem(
        //   <Link
        //     title="Skills and Courses"
        //     className={`text-md  text-backdrop `}
        //     to={"/trainer-admin/skills-courses"}>
        //     Skills & Courses
        //   </Link>,
        //   "/trainer-admin/skills-courses"
        // ),
        // getItem(
        //   <Link
        //     title="Programs and Workshops"
        //     className={`text-md  text-backdrop `}
        //     to={"/trainer-admin/programs-and-workshops"}>
        //     Programs and Workshops
        //   </Link>,
        //   "/trainer-admin/programs-and-workshops"
        // ),
        getItem(
          <Link
            title="School Dashboard"
            className={`text-md  text-backdrop `}
            to={"/trainer-admin/school-dashboard/overview"}>
            School Dashboard
          </Link>,
          "/trainer-admin/school-dashboard/overview"
        ),
        getItem(
          <Link
            title="Sales"
            className={`text-md  text-backdrop `}
            to={"/trainer-admin/sales"}>
            Sales
          </Link>,
          "/trainer-admin/sales"
        ),
        getItem(
          <Link
            title="Payouts"
            className={`text-md  text-backdrop `}
            to={"/trainer-admin/payouts"}>
            Payouts
          </Link>,
          "/trainer-admin/payouts"
        ),
      ]
    ),
  ]
  return (
    <div className="h-full mt-12">
      <Sider
        width={openSidebar ? 210 : 70} // 70
        style={{
          backgroundColor: "#F5F5F5",
        }}
        className="shadow h-screen overflow-y-auto ">
        <Menu
          mode="inline"
          className="bg-[#F5F5F5] text-backdrop mt-5 text-[16px]"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{
            // height: '100%',
            borderRight: 0,
          }}
          items={isAdmin ? itemsAdmin : itemsTrainer}
        />
        <LogoutOutlined
          className=" cursor-pointer fixed z-10 bottom-9 left-7"
          onClick={handleLogoutRedirect}
        />

        {/* <button onClick={handleOpenSidebar}>SHOW HIDE</button> */}
      </Sider>
    </div>
  )
}

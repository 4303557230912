import React from 'react'
import { PageContent } from '../../../common/layout/PageContent.tsx'
import { PageHeader } from '../../../common/layout/PageHeader.tsx'
import { HomeIcon } from '../../../assets/icon/index.js'
import CertificatePreviewContent from '../components/CertificatePreviewContent.tsx'
import { initialProgramStoreState } from '../store/programStore.ts'


interface PreviewCertificateProps {

}

const PreviewCertificate: React.FC<PreviewCertificateProps> = () => {

  const { programDetailData } = initialProgramStoreState()

  const breadcrumbItems = [
    {
      title:
        <div className={"mt-1"}>
          <HomeIcon />
        </div>,
      path: "/",
    },
    {
      title: "Admin",
      className: "text-gray-400",
    },
    {
      title: "Dashboard",
      to: "/trainer-admin/school-dashboard",
    },
    {
      title: "Program",
      to: "/trainer-admin/school-dashboard/programs",
    },
    {
      title: programDetailData?.title || 'Program title',
      to: `/trainer-admin/school-dashboard/programs/${programDetailData?.id}`,
    },
    {
      title: "Certificate Editor",
    },
  ]

  return (
    <>
      <PageContent
        content={<CertificatePreviewContent />}
        header={
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title="CoC Preview"
            showBack
          />
        }
      />
    </>
  )
}

export default PreviewCertificate

import React, { createContext, useState } from "react";

// Create Context Object
export const AutoSaveContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const AutoSaveContextProvider = (props) => {
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isAutoSaveEnabled, setIsAutoSaveEnabled] = useState(true);
  const [delayInSeconds, setDelayInSeconds] = useState(0); // Initialize with 0 seconds
  const [globalFieldsDisabled, setGlobalFieldsDisabled] = useState(false);

  // Handler functions
  const startAutoSaving = () => setIsAutoSaving(true);
  const stopAutoSaving = () => setIsAutoSaving(false);
  const enableAutoSaveFeature = () => setIsAutoSaveEnabled(true);
  const disableAutoSaveFeature = () => setIsAutoSaveEnabled(false);
  const disableGlobalFields = () => setGlobalFieldsDisabled(true);
  const enableGlobalFields = () => setGlobalFieldsDisabled(false);

  return (
    <AutoSaveContext.Provider
      value={{
        isAutoSaving,
        isAutoSaveEnabled,
        delayInSeconds,
        setDelayInSeconds,
        startAutoSaving,
        stopAutoSaving,
        enableAutoSaveFeature,
        disableAutoSaveFeature,
        globalFieldsDisabled,
        disableGlobalFields,
        enableGlobalFields,
        setIsAutoSaving,
        setIsAutoSaveEnabled,
        setGlobalFieldsDisabled,
      }}>
      {props.children}
    </AutoSaveContext.Provider>
  );
};

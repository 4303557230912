import React, { useRef, useImperativeHandle } from "react";
import { Upload } from "antd";

export const UploadWithFocus = React.forwardRef((props: any, ref) => {
  const { onFocus, ...rest } = props;
  const uploadRef = useRef<HTMLDivElement>();

  useImperativeHandle(ref, () => {
    return {
      ...uploadRef.current,
      focus: onFocus || (() => {}),
    };
  });

  return <Upload ref={uploadRef} {...rest} />;
});

import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";

interface SavingTagProps {
  isSaving: boolean;
}

export const SavingTag: React.FC<SavingTagProps> = ({ isSaving }) => {
  return (
    <Tag
      color={isSaving ? "blue" : "green"}
      className='rounded-full text-base !px-3'
      icon={
        isSaving ? (
          <LoadingOutlined className='text-sm' />
        ) : (
          <CheckCircleFilled className='text-sm' />
        )
      }>
      {isSaving ? "Saving..." : "Saved"}
    </Tag>
  );
};

import React, { useState } from "react";

import { Layout, Spin } from "antd";

// import CustomHeader from './CustomHeader'
import CustomSidebar from "./CustomSidebar";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CustomFooter } from "./CustomFooter";
import { useProgressCheking } from "../hooks/useProgressCheking";
import { LoadingOutlined } from "@ant-design/icons";
import CustomHeader from "./CustomHeader";

const { Content } = Layout;

// eslint-disable-next-line react/prop-types
export default function PageLayout({
  children,
  isSidebar = true,
  isFooter = true,
  isHeader = true,
  isOnboard = false,
  isBgColor = true,
}) {
  const [openSidebar, setOpenSidebar] = useState(true);
  const handleOpenSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const { isLoading } = useProgressCheking();

  return isLoading ? (
    <div className="h-screen w-screen bg-slate-200 flex justify-center items-center absolute z-50">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
    </div>
  ) : (
    <Layout className="h-screen">
      {isHeader && <CustomHeader />}

      <Layout>
        <AuthenticatedTemplate>
          {isHeader && <CustomHeader isOnBoarding={isOnboard} />}
          {isSidebar && (
            <div className="fixed">
              <CustomSidebar
                openSidebar={openSidebar}
                handleOpenSidebar={handleOpenSidebar}
              />
            </div>
          )}

          {/* hide = 3.2rem */}
          <Layout
            className={`h-full ${
              !isSidebar && isBgColor ? "bg-[#E2EBFA]" : " bg-bg"
            } ${
              !isSidebar ? "" : openSidebar ? "ml-[13.2rem]" : "ml-[4.2rem]"
            } `}
          >
            <Content
              className="overflow-auto flex flex-col justify-between"
              style={{
                margin: 0,
                // minHeight: 280,
                marginTop: isHeader ? "64px" : 0,
              }}
            >
              {children}
            </Content>
            {isFooter && <CustomFooter isLogged={true} />}
          </Layout>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Layout className="bg-bg h-screen">
            <Content
              style={{
                margin: 0,
                marginTop: "50px",
                // minHeight: 280,
              }}
            >
              {children}
              <CustomFooter isLogged={false} />
            </Content>
          </Layout>
        </UnauthenticatedTemplate>
      </Layout>
    </Layout>
  );
}

import { UploadOutlined } from "@ant-design/icons";
import { Button, Progress, Spin, message } from "antd";
import Dragger, { DraggerProps } from "antd/es/upload/Dragger";
import React from "react";
import { httpRequest } from "../../config/config";
import { BlobServiceClient } from "@azure/storage-blob";

interface VideoUploadProps extends DraggerProps {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  encodingInProgress?: boolean;
  azureContainerName: string;
  sasEndpoint?: string;
  fileName?: string;
  beforeUpload?: () => void;
  afterUpload?: () => void;
  onSuccess?: (fileName: string) => void;
}

const CONTANTS = {
  MAX_FILE_SIZE: 1024 * 1024 * 1024,
};

const getBlobSas = async (sasEndpoint: string) => {
  const response = await httpRequest.post(sasEndpoint);
  return response?.data?.data;
};

export const VideoUploadV2: React.FC<VideoUploadProps> = ({
  className,
  style,
  disabled,
  encodingInProgress,
  azureContainerName,
  fileName,
  sasEndpoint = "/school/my-courses/generate-blob-sas",
  beforeUpload,
  afterUpload,
  onSuccess,
  ...rest
}) => {
  const [uploadProgress, setUploadProgress] = React.useState(0);
  // process.env.REACT_APP_AZURE_VIDEO_STORAGE_CONTAINER_NAME
  const customRequest = async ({ onError, file, onProgress }) => {
    beforeUpload?.();
    if (!file) {
      console.error("No file provided");
      return;
    }

    try {
      const sasToken = await getBlobSas(sasEndpoint);
      const blobServiceClient = new BlobServiceClient(
        sasToken?.storage_full_url
      );
      const containerClient =
        blobServiceClient.getContainerClient(azureContainerName);
      const blockBlobClient = containerClient.getBlockBlobClient(
        fileName ?? file?.name
      );
      const response = await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: (progress) => {
          const percentUploaded =
            progress?.loadedBytes && file?.size
              ? (progress?.loadedBytes / file?.size) * 100
              : 0;
          setUploadProgress(percentUploaded); // Update upload progress
        },
        blobHTTPHeaders: {
          blobContentType: file.type,
          blobContentMD5: undefined,
          blobCacheControl: undefined,
          blobContentEncoding: undefined,
          blobContentLanguage: undefined,
          blobContentDisposition: undefined,
        },
      });

      if (
        response?.["_response"]?.status >= 200 &&
        response?.["_response"]?.status < 300
      ) {
        afterUpload?.();
        setUploadProgress(0);
        return onSuccess?.(fileName ?? file?.name);
      } else {
        setUploadProgress(0);
        afterUpload?.();
        throw new Error("Error uploading file");
      }
    } catch (error) {
      afterUpload?.();
      setUploadProgress(0);
      onError(error);
      console.log(error);
    }
  };

  if (uploadProgress > 0) {
    return (
      <div className="flex items-center justify-center p-6 flex-col !h-72 ">
        <Progress
          percent={uploadProgress}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          strokeWidth={6}
          format={(percent) => `${parseFloat(percent?.toFixed(2) ?? "0")}%`}
        />
        <p className="font-semibold mt-4 text-center text-[16px]">
          Uploading Video
        </p>
      </div>
    );
  }

  if (encodingInProgress)
    return (
      <div className="flex items-center justify-center p-6 flex-col !h-72 ">
        <Spin spinning={true} />
        <p className="font-semibold mt-4 text-center text-[16px]">
          Encoding Video
        </p>
        <p className="mt-1">
          You may leave this page, the progress will be saved.
        </p>
      </div>
    );

  return (
    <Dragger
      className={` ${className}`}
      style={style}
      disabled={disabled}
      beforeUpload={async (file, fileList) => {
        try {
          if (!file.type.startsWith("video/")) {
            message.error("File must be a video");
            return false;
          }
          // check if video has audio
          // const hasAudio = await checkIfVideoHasAudio(file);
          // if (!hasAudio) {
          //   message.error("Video must have an audio track");
          //   return false;
          // }

          // check if file is too large
          if (file.size > CONTANTS.MAX_FILE_SIZE) {
            message.error(
              "File is too large. Please select a file smaller than 1GB."
            );
            return false;
          }
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      showUploadList={false}
      //   @ts-ignore
      customRequest={customRequest}
      accept="video/*"
      progress={{
        strokeColor: {
          "0%": "#108ee9",
          "100%": "#87d068",
        },
        size: 3,
        format: (percent) => `${parseFloat(percent?.toFixed(2) ?? "0")}%`,
      }}
      {...rest}
    >
      <div className="flex items-center p-6 flex-col h-full">
        <div className="rounded-full p-4">
          <UploadOutlined className="text-[24px]" />
        </div>
        <p className="font-semibold mt-4 text-center text-[16px]">
          {disabled
            ? "Will be available in a few seconds..."
            : "Drag and drop video file to upload"}
        </p>
        <Button disabled={disabled} className="mt-auto">
          Select Video File
        </Button>
      </div>
    </Dragger>
  );
};

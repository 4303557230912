import React from "react";
import { BasicContainer } from "./BasicContainer.tsx";
import { RichText } from "../../../../common/components/RichText.tsx";
import { Controller } from "react-hook-form";
import { ControllerProps } from "react-hook-form";
import { ReactQuillProps } from "react-quill";
import { SavingTag } from "../../../../common/components/SavingTag.tsx";

interface Props extends Omit<ControllerProps<any>, "render"> {
  title?: string;
  richTextClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  richTextProps?: ReactQuillProps;
  showSaving?: boolean;
  isSaving?: boolean;
  showError?: boolean;
  errorMessage?: string;
  isError?: boolean;
  children?: React.ReactNode;
}

export const BasicContainerRichEditor: React.FC<Props> = ({
  title,
  richTextClassName,
  disabled,
  showSaving,
  isSaving,
  className,
  showError,
  errorMessage,
  isError,
  children,
  ...rest
}) => {
  const { richTextProps, ...restProps } = rest;
  return (
    <BasicContainer className={className}>
      {showSaving ? (
        <div className='flex w-full items-center justify-between'>
          <h3>{title}</h3>
          <SavingTag isSaving={isSaving ?? false} />
        </div>
      ) : (
        <h3>{title}</h3>
      )}
      <Controller
        {...restProps}
        render={({ field, fieldState, formState }) => {
          return (
            <RichText
              {...field}
              className={`mt-4 h-[278px] pb-[1rem] mb-1 font-default  font-normal bg-white  ${richTextClassName}`}
              placeholder={richTextProps?.placeholder ?? "Input content"}
              readOnly={disabled ?? false}
              {...richTextProps}
            />
          );
        }}
      />
      {showError && isError && (
        <div className='mt-2 text-danger text-sm'>{errorMessage}</div>
      )}
      {children}
    </BasicContainer>
  );
};

import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { FormValues, QuestionData } from "../types/common.ts";
import { useUpdateCourseLevelAssessment } from "./courseEditor.ts";
import { useCourseEditorStore } from "../stores/useCourseEditorStore.ts";
import React from "react";
import { message } from "antd";
import { useQueryClient } from "react-query";

type QuestionFormType = FieldArrayWithId<
  {
    questions: QuestionData[] | undefined;
  },
  "questions",
  "fid"
>;

export const useQuestionContainer = (
  question: QuestionFormType,
  replaceAssessmentList: (a: any) => void
) => {
  const updateAssessment = useUpdateCourseLevelAssessment();
  const { skill, level } = useCourseEditorStore((state) => state);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const queryClient = useQueryClient();

  const handleQuestionBlur = (value: string) => {
    const hasChanged = value !== question?.question;
    if (skill?.id && level?.id && question?.id && hasChanged) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: question?.id,
          formData: {
            question: value,
            order: question?.order,
            timer: question?.timer,
            answers: question?.answers,
          },
        },
        {
          onSuccess: async (data) => {
            const a: any = await queryClient.fetchQuery([
              "course-level-assessment-list",
              skill?.id,
              level?.id,
            ]);
            replaceAssessmentList([...a?.data?.data]);
          },
          onError: (error) => {
            message.error(
              "There was a problem updating the question, please try again."
            );
          },
        }
      );
    }
  };
  const handleAnswerBlur = (value: string, index: number) => {
    const hasChanged = value !== question?.answers[index]?.answer;
    const newAnswers = [...question?.answers];
    newAnswers[index].answer = value;
    if (skill?.id && level?.id && question?.id && hasChanged) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: question?.id,
          formData: {
            order: question?.order,
            timer: question?.timer,
            answers: newAnswers,
          },
        },
        {
          onSuccess: (data) => {},
          onError: (error) => {
            message.error(
              "There was a problem updating the question, please try again."
            );
          },
        }
      );
    }
  };

  const radioChangeHandler = (value: boolean, index: number) => {
    const hasChanged = value !== question?.answers[index]?.is_correct_answer;
    const newAnswers = [...question?.answers];
    newAnswers[index].is_correct_answer = value;
    if (skill?.id && level?.id && question?.id && hasChanged) {
      updateAssessment.mutate(
        {
          course_id: skill?.id,
          level_id: level?.id,
          assessment_id: question?.id,
          formData: {
            order: question?.order,
            timer: question?.timer,
            answers: newAnswers,
          },
        },
        {
          onSuccess: (data) => {},
          onError: (error) => {
            message.error(
              "There was a problem updating the question, please try again."
            );
          },
        }
      );
    }
  };
  return {
    handleQuestionBlur,
    handleAnswerBlur,
    radioChangeHandler,
    isCollapsed,
    setIsCollapsed,
    control,
    errors,
    skill,
    level,
    updateAssessment,
  };
};

import React from "react";
import {BasicContainer} from "../CourseEditor/BasicContainer.tsx";
import {Avatar, Button, Progress, Table} from "antd";
import {CourseStatusTag, LevelName} from "./components/index.ts";
import {LockOutlined} from "@ant-design/icons";
import {Level, SkillDetail} from "../../types/common.ts";
import {useNavigate} from "react-router";
import {useCourseEditorStore} from "../../stores/useCourseEditorStore.ts";

interface Props {
	isLoading?: boolean;
	skillDetail: SkillDetail | undefined;
}

/**
 * @todo Add Widths to columns
 *
 */

export const ProficiencyLevelsTable: React.FC<Props> = ({
	isLoading,
	skillDetail,
}) => {
	const navigate = useNavigate();
	const initialize = useCourseEditorStore((state) => state.initialize);

	// const {methods} =
	//   useCourseEditor();

	const columns = [
		{
			dataIndex: "isLocked",
			key      : "locked",
			render   : (
				_: any,
				record: Level
			) =>
				record?.accessible_for_me ? null : (
					<LockOutlined className='text-darkGrey text-[24px] mt-1 '/>
				),
		},
		{
			title    : "Level Name",
			dataIndex: "name",
			key      : "proficiencyLevel",
			render   : (
				levelName: string,
				record: Level
			) => (
				<LevelName
					status={record?.status}
					levelNum={record?.proficiency}
					levelName={record?.name}
				/>
			),
		},
		{
			title    : "Instructor",
			dataIndex: "instructor",
			key      : "instructor",
			render   : (
				_: any,
				record: Level
			) => (
				<Avatar
					src={record?.trainer?.avatar}
					size={"large"}
					className='object-contain !w-[50px]'
				/>
			),
		},
		{
			title    : "Course Status",
			dataIndex: "status",
			key      : "status",
			render   : (status: string) => <CourseStatusTag status={status}/>,
		},
		{
			title    : "Progress",
			dataIndex: "progress",
			key      : "description",
			render   : (
				progress: number,
				record: Level
			) => (
				<div className='flex gap-x-2 items-baseline'>
					<Progress
						showInfo={false}
						strokeWidth={6}
						strokeColor={{
							to  : "#86C769",
							from: "#009ACE",
						}}
						percent={
							(
								(
									record?.progress?.completed ?? 0) /
								(
									record?.progress?.total ?? 1)) *
							100
						}
					/>
					<div className='whitespace-nowrap'>
						{record?.progress?.completed ?? 0} /{record?.progress?.total ?? 1}
					</div>
				</div>
			),
			width    : "25%",
		},

		{
			title    : "Edit Course",
			dataIndex: "action",
			key      : "action",
			render   : (
				_: any,
				record: Level,
				index: number
			) => (
				<Button
					className='w-full'
					onClick={
						skillDetail?.id
							? () => {
								initialize(
									{
										id         : skillDetail?.id,
										name       : skillDetail?.name,
										description: skillDetail?.description,
									},
									{
										levelNum: record?.proficiency,
										name    : record?.name,
										id      : record?.id,
									}
								);
								navigate(`/course/editor/${skillDetail?.id}/${record?.id}`);
								// const {methods} =
								//   useCourseEditor();
								//   methods.trigger()
							}
							: undefined
					}
					disabled={!record?.accessible_for_me}>
					Course Editor
				</Button>
			),
		},
	];

	return (
		<BasicContainer>
			<h2>Proficiency Levels</h2>
			<Table
				columns={columns}
				loading={isLoading}
				rowClassName={"!bg-transparent"}
				rootClassName='!bg-transparent'
				bordered={false}
				dataSource={skillDetail?.levels}
				pagination={false}
				rowKey={(record: any) => record.id}
			/>
		</BasicContainer>
	);
};

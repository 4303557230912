import { Button } from "antd";
import React from "react";
import { TitleSize, getTitleAccordingToSize } from "../../utils.tsx";
import { ButtonProps } from "antd";

interface BasicTitleContainerProps {
  className?: string;
  children: React.ReactNode | React.ReactElement;
}

export const BasicTitleContainer: React.FC<BasicTitleContainerProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={`px-4 py-3 rounded-[11px] shadow-md bg-dirtyBlue ${
        className ?? ""
      }`}>
      {children}
    </div>
  );
};
interface TitleContainerProps {
  className?: string;
  children?: React.ReactNode | React.ReactElement;
  title: string;
  withButton?: boolean;
  onClick?: () => void;
  buttonTitle?: string;
  titleSize?: TitleSize;
  buttonProps?: ButtonProps;
}

export const BasicTitle: React.FC<TitleContainerProps> = ({
  title,
  withButton,
  className,
  onClick,
  buttonTitle,
  titleSize = "h2",
  children,
  buttonProps,
}) => {
  return (
    <BasicTitleContainer
      className={`flex items-center ${
        withButton ? "justify-between" : ""
      } ${className}`}>
      {getTitleAccordingToSize(titleSize, title)}
      {withButton && (
        <Button
          className='text-white bg-primary'
          onClick={onClick}
          {...buttonProps}>
          {buttonTitle}
        </Button>
      )}
      {children}
    </BasicTitleContainer>
  );
};

import { Divider, Tag } from "antd";
import React from "react";
import place from "../../../assets/icon/pin_map gray.svg";
import avatar from "../../../assets/images/avatar_1.png";

export const SchoolInstructors = ({ data, peoples }) => {
  return (
    <div className="bg-[#E2EBFA] p-3">
      <div className=" flex items-center flex-col gap-2">
        <img
          src={data?.logo || avatar}
          alt="avatar-user"
          className="w-14 h-14 border border-primary object-contain rounded-full"
        />
        <p className=" capitalize font-semibold">{data?.name}</p>
      </div>
      <Divider className="border-1 border-[#22A5D1] my-3 p-0" />
      {peoples?.map((v, i) => (
        <div className="flex gap-5 items-start my-5" key={i}>
          <img
            src={v?.avatar || avatar}
            alt="avatar-user"
            className="w-10 h-10 border border-primary object-contain rounded-full"
          />
          <div className=" w-28 ">
            <p className="truncate capitalize">
              {`${v?.first_name} ${v?.last_name}`}
            </p>
            <div className="flex gap-2 items-center text-[#919191] text-[12px]">
              <img src={place} alt="pin-map" className=" w-3 h-3" />
              <p>{v?.country || "-"}</p>
            </div>
          </div>
          <div className="flex-grow text-end">
            <Tag
              //   color="green"
              color={v.role === "Admin" ? "green" : "orange"}
              className=" capitalize py-0.5 px-2 m-0 font-kodchasan"
            >
              {v.role}
            </Tag>
          </div>
        </div>
      ))}
    </div>
  );
};

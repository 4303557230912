import { useMutation } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

type EncodeLessonVideoData = {
  lesson_id: string;
  module_id: string;
  video_file_name: string;
};

const encodeLessonVideo = (data: EncodeLessonVideoData) => {
  return httpRequest.post("/school/my-courses/encode-lesson-video", data);
};

const deleteLessonVideo = (data: EncodeLessonVideoData) => {
  return httpRequest.post("/school/my-courses/remove-lesson-video", data);
};

export const useEncodeLessonVideo = () => {
  return useMutation((data: EncodeLessonVideoData) => encodeLessonVideo(data), {
    onSuccess: () => {},
    mutationKey: "encodeLessonVideo",
  });
};

export const useDeleteLessonVideo = () => {
  return useMutation((data: EncodeLessonVideoData) => deleteLessonVideo(data), {
    mutationKey: "deleteLessonVideo",
    onSuccess: () => {
      message.success(
        "Video deleted successfully. You'll see the changes soon, or you can refresh the page."
      );
    },
  });
};

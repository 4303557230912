import { Route, Routes } from "react-router-dom";
import ListStudendts from "./pages/ListStudents";
import NotFound from "../../common/components/NotFound";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<ListStudendts />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Index;

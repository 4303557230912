import { useQuery } from "react-query";
import httpRequest from "../../../config/config";

export const useSales = () => {
  const { data, isLoading } = useQuery("sales", () =>
    httpRequest.get("/school/sales/monthly")
  );

  const configBySkills = {
    data: data?.data?.data?.by_skills || [],
    xField: "date",
    yField: "value",
    seriesField: "label",
    slider: {
      start: 0.1,
      end: 0.9,
    },
  };
  const configByInstuctors = {
    data: data?.data?.data?.by_instructors || [],
    xField: "date",
    yField: "value",
    seriesField: "label",
    slider: {
      start: 0.1,
      end: 0.9,
    },
  };
  return {
    data: { skill: configBySkills, instructor: configByInstuctors },
    loading: isLoading,
  };
};

import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useCourseEditorStore } from "../stores/useCourseEditorStore.ts";
import {
  useFetchCourseLevelAssessmentList,
  useFetchCourseLevelModuleList,
  useFetchCourseLevelOverview,
} from "./courseEditor.ts";
import httpRequest from "../../../config/config";
import { handlingInfo, handlingSuccess } from "../../../utils/handling";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CourseEditorSchema } from "../utils/courseEditorSchema.ts";

export const useCourseEditor = () => {
  const { skill_id } = useParams();
  const navigate = useNavigate();
  const { skill, level, setCheckErrors } = useCourseEditorStore(
    (state) => state
  );

  const assessmentListQuery = useFetchCourseLevelAssessmentList(
    skill?.id,
    level?.id
  );
  const overviewQuery = useFetchCourseLevelOverview(skill?.id, level?.id);
  const moduleListQuery = useFetchCourseLevelModuleList(skill?.id, level?.id);

  const methods = useForm({
    resolver: yupResolver(CourseEditorSchema),
    defaultValues: {
      overview: {
        certification_description:
          overviewQuery?.data?.data?.data?.certification_description,
        module_1st_outcome:
          overviewQuery?.data?.data?.data?.module_learning_outcomes?.[0]
            ?.outcomes,
        module_2nd_outcome:
          overviewQuery?.data?.data?.data?.module_learning_outcomes?.[1]
            ?.outcomes,
        module_3rd_outcome:
          overviewQuery?.data?.data?.data?.module_learning_outcomes?.[2]
            ?.outcomes,
        module_4th_outcome:
          overviewQuery?.data?.data?.data?.module_learning_outcomes?.[3]
            ?.outcomes,
      },
      module_1: {
        glossary:
          moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports?.glossary,
        introduction:
          moduleListQuery?.data?.data?.data?.[0]?.overview?.introduction,
        reflection:
          moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports
            ?.reflection,
        resources:
          moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports
            ?.resources,
        title: moduleListQuery?.data?.data?.data?.[0]?.overview?.title,
        lessons: moduleListQuery?.data?.data?.data?.[0]?.lessons ?? [],
      },
      module_2: {
        glossary:
          moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports?.glossary,
        introduction:
          moduleListQuery?.data?.data?.data?.[1]?.overview?.introduction,
        recap: moduleListQuery?.data?.data?.data?.[1]?.overview?.recap,
        reflection:
          moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports
            ?.reflection,
        resources:
          moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports
            ?.resources,
        title: moduleListQuery?.data?.data?.data?.[1]?.overview?.title,
        lessons: moduleListQuery?.data?.data?.data?.[1]?.lessons ?? [],
      },
      module_3: {
        glossary:
          moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports?.glossary,
        introduction:
          moduleListQuery?.data?.data?.data?.[2]?.overview?.introduction,
        recap: moduleListQuery?.data?.data?.data?.[2]?.overview?.recap,
        reflection:
          moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports
            ?.reflection,
        resources:
          moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports
            ?.resources,
        title: moduleListQuery?.data?.data?.data?.[2]?.overview?.title,
        lessons: moduleListQuery?.data?.data?.data?.[2]?.lessons ?? [],
      },
      module_4: {
        glossary:
          moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports?.glossary,
        introduction:
          moduleListQuery?.data?.data?.data?.[3]?.overview?.introduction,
        recap: moduleListQuery?.data?.data?.data?.[3]?.overview?.recap,
        reflection:
          moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports
            ?.reflection,
        resources:
          moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports
            ?.resources,
        title: moduleListQuery?.data?.data?.data?.[3]?.overview?.title,
        lessons: moduleListQuery?.data?.data?.data?.[3]?.lessons ?? [],
      },
      assessment: {
        questions: assessmentListQuery?.data?.data?.data ?? [],
      },
    },
    reValidateMode: "onChange",
    mode: "onChange",
  });

  React.useEffect(() => {
    if (
      overviewQuery?.data?.data?.data ||
      assessmentListQuery?.data?.data?.data ||
      moduleListQuery?.data?.data?.data
    ) {
      methods.reset({
        overview: {
          certification_description:
            overviewQuery?.data?.data?.data?.certification_description,
          module_1st_outcome:
            overviewQuery?.data?.data?.data?.module_learning_outcomes?.[0]
              ?.outcomes,
          module_2nd_outcome:
            overviewQuery?.data?.data?.data?.module_learning_outcomes?.[1]
              ?.outcomes,
          module_3rd_outcome:
            overviewQuery?.data?.data?.data?.module_learning_outcomes?.[2]
              ?.outcomes,
          module_4th_outcome:
            overviewQuery?.data?.data?.data?.module_learning_outcomes?.[3]
              ?.outcomes,
        },
        module_1: {
          glossary:
            moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports
              ?.glossary,
          introduction:
            moduleListQuery?.data?.data?.data?.[0]?.overview?.introduction,
          reflection:
            moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports
              ?.reflection,
          resources:
            moduleListQuery?.data?.data?.data?.[0]?.conceptual_supports
              ?.resources,
          title: moduleListQuery?.data?.data?.data?.[0]?.overview?.title,
          lessons: moduleListQuery?.data?.data?.data?.[0]?.lessons ?? [],
        },
        module_2: {
          glossary:
            moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports
              ?.glossary,
          introduction:
            moduleListQuery?.data?.data?.data?.[1]?.overview?.introduction,
          recap: moduleListQuery?.data?.data?.data?.[1]?.overview?.recap,
          reflection:
            moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports
              ?.reflection,
          resources:
            moduleListQuery?.data?.data?.data?.[1]?.conceptual_supports
              ?.resources,
          title: moduleListQuery?.data?.data?.data?.[1]?.overview?.title,
          lessons: moduleListQuery?.data?.data?.data?.[1]?.lessons ?? [],
        },
        module_3: {
          glossary:
            moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports
              ?.glossary,
          introduction:
            moduleListQuery?.data?.data?.data?.[2]?.overview?.introduction,
          recap: moduleListQuery?.data?.data?.data?.[2]?.overview?.recap,
          reflection:
            moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports
              ?.reflection,
          resources:
            moduleListQuery?.data?.data?.data?.[2]?.conceptual_supports
              ?.resources,
          title: moduleListQuery?.data?.data?.data?.[2]?.overview?.title,
          lessons: moduleListQuery?.data?.data?.data?.[2]?.lessons ?? [],
        },
        module_4: {
          glossary:
            moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports
              ?.glossary,
          introduction:
            moduleListQuery?.data?.data?.data?.[3]?.overview?.introduction,
          recap: moduleListQuery?.data?.data?.data?.[3]?.overview?.recap,
          reflection:
            moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports
              ?.reflection,
          resources:
            moduleListQuery?.data?.data?.data?.[3]?.conceptual_supports
              ?.resources,
          title: moduleListQuery?.data?.data?.data?.[3]?.overview?.title,
          lessons: moduleListQuery?.data?.data?.data?.[3]?.lessons ?? [],
        },
        assessment: {
          questions: assessmentListQuery?.data?.data?.data ?? [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overviewQuery?.data?.data?.data,
    assessmentListQuery?.data?.data?.data,
    moduleListQuery?.data?.data?.data,
  ]);
  React.useEffect(() => {
    if (skill?.id === null || level?.id === null) {
      navigate(`/courses/detail/${skill_id}`);
    }
  }, [skill, level, navigate, skill_id]);

  const publishCourse = methods.handleSubmit(async () => {
    try {
      const response = await httpRequest.patch(
        `/school/my-courses/${skill?.id}/level/${level?.id}/publish`
      );
      handlingInfo("Publish Level Successfully", "success");
      return response.data;
    } catch (error) {
      console.log("error", error);
      message.error("Publish Level Failed");
    }
  });

  const saveChanges = React.useCallback(async () => {
    try {
      const isError = await methods?.trigger();
      const values = methods?.getValues();
      const response = await httpRequest.patch(
        `/school/my-courses/${skill?.id}/level/${level?.id}`,
        values
      );
      const continueEditing = isError
        ? "Continue editing to fix the identified issues within the editor"
        : "";
      handlingSuccess("Your Changes Have been saved. " + continueEditing);
      return response;
    } catch (error) {
      message.error("Failed to Save Changes. Please try again.");
    }
  }, [methods, skill, level]);

  return {
    skill_id,
    navigate,
    skill,
    level,
    setCheckErrors,
    assessmentListQuery,
    overviewQuery,
    moduleListQuery,
    saveChanges,
    publishCourse,
    methods,
  };
};

import React from "react";
import { Breadcrumbs } from "../../../common/components/Breadcumbs.jsx";
import TableCourses from "../components/TableCourses.jsx";
import { useMyCourses } from "../hooks/useFetchMyCourses.js";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useWorkshopList } from "../hooks/useWorkshop.ts";
import TableWorkshops from "../components/TableWorkshops.tsx";
import { debounce } from "../../../common/helper/debounce.js";
import {HomeIcon} from "../../../assets/icon";

const ListCoursesV2 = () => {
  const items = [
    {
      title:
          <div className={"mt-1"}>
            <HomeIcon />
          </div>,
      path: "/",
    },
    {
      path: "/courses",
      title: "Skills I teach",
    },
  ];

  const { listMyCourses, isLoading } = useMyCourses();
  const workshop = useWorkshopList();
  return (
    <div>
      <div className="px-6 py-2 bg-white">
        <div className="mb-3">
          <Breadcrumbs items={items} />
        </div>
        <h1 className="text-2xl font-bold text-backdrop">
          My Courses & Workshops
        </h1>
      </div>

      <div className="mx-5 p-5 my-5 bg-white rounded-lg shadow-md ">
        <div
          className="flex gap-3"
          style={{ borderBottom: "1px solid #D0DDE5" }}
        >
          <SearchOutlined />{" "}
          <Input
            bordered={false}
            placeholder="Search workshop"
            defaultValue={workshop.params.title}
            onChange={debounce((val) => {
              workshop.setParams((prev) => ({
                ...prev,
                title: val?.target.value,
                page: 1,
                limit: 10,
              }));
            }, 500)}
          />
        </div>
      </div>

      <div className="mx-5 px-3 py-5 my-5 bg-white rounded-lg shadow-md">
        <div className="mx-5 px-5 py-5 my-5 bg-[#FAFAFA] rounded-lg shadow-md">
          <h2>Skills</h2>
          <p className="my-3">
            Curriculum needs to be agreed on with Skilledin
          </p>

          <div className="space-y-3">
            <div>
              <TableCourses
                isLoading={isLoading}
                listMyCourses={listMyCourses}
              />
            </div>
          </div>
        </div>
        <div className="mx-5 px-5 py-5 my-5 bg-[#FAFAFA] rounded-lg shadow-md">
          <h2 className="">Workshops</h2>
          <TableWorkshops
            isLoading={workshop.isLoading}
            data={workshop.dataList}
            total={workshop.total}
            setParams={workshop.setParams}
          />
        </div>
      </div>
    </div>
  );
};

export default ListCoursesV2;
